
import {
    baseConfig, default_location_config
} from "../constants";
import { RootConfigType } from "../constantsTyped";
import { DEFAULT_FRUIT } from "../fruitNames";


export const MasterTenantConfig: RootConfigType = {
    ...baseConfig,
    default_fruit_type: DEFAULT_FRUIT,
    summary_collapsed: false,
    can_do_manual_check: true,
    default_location_config: { ...default_location_config, device_result_action: false },
    default_manual: true,
    strict_check_size: false,
    indicate_soft_avocado_at_internal: false,
    suggest_color_stage: true,

    default_location: "ripening",

    research_mode: false,
    invalidate_20x_firmness: false,

    mock_signals: [
        {
            color: "danger",
            title: "Max fruit age is almost reached",
            description: "Please check on this pallet in ripening",
            url: "/layer/57236dd4-1d24-4d81-b487-814491995d07",
            flag: "flag-danger"

        },
        {
            color: "warning",
            title: "Ripening process too fast",
            description: "This pallet deviates from expected ripening",
            url: "/layer/cb35f178-282e-4e0b-b470-1eb2c788eeaf",
            flag: "flag-warning"

        },
        {
            color: "secondary",
            title: "Ripening process too slow",
            description: "This Pallet deviates from expected ripening",
            url: "/layer/126b6f5e-ff43-4c6d-a13a-954fd959af6d",
            flag: "flag-light"

        }
    ],
};
