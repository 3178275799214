/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LAYER_TYPE } from "../../../../actions/Layers/constants";
import { applyDisplayFilterFields } from "../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig, { getFormOptionsLabel } from "../../../../actions/Tenants/config/configHook";
import { find_category } from "../../../../actions/Tenants/config/utils";
import { getFilledArrayOrDefault, isFilledArray } from "../../../../utils";
import { toPercentage } from "../../Layers/fields/formatters";
import useRipeningRoom, { formatDate, getAllocatedLayersHook, isItemAllocatedOnDateHook } from "./hooks/useRipeningRoom";

// * Components to show as meta rows in table in a side bar
export const MetaRowComponents = {
    number_of_purchase_orders: MetaRowNumberOfPurchaseOrders,
    number_of_ggn: (args) => <MetaRowListUnqiue {...args} field="global_gap_number" displayType="count" />,
    number_of_sizes: (args) => <MetaRowListUnqiue {...args} field="size" displayType="list" />, // TODO: remove when we migrated `size` to `fruit_size`
    number_of_box_sizes: (args) => <MetaRowListUnqiue {...args} field="box_size" displayType="list" />, // TODO: remove when we migrated `box_size` to `fruit_size`
    number_of_fruit_sizes: (args) => <MetaRowListUnqiue {...args} field="fruit_size" displayType="list" />,
    number_of_suppliers: (args) => <MetaRowListUnqiue {...args} field="supplier" displayType="list" />,
    number_of_countries: (args) => <MetaRowListUnqiue {...args} field="country" displayType="list" />,
    number_of_fruit_varieties: (args) => <MetaRowListUnqiue {...args} field="fruit_variety" displayType="list" />,
    age_average: MetaRowAgeAverage,
    occupancy_latest: MetaRowOccupancyLatest,
    dry_matter: MetaRowAverageDryMatter,
};

export interface MetricCardProps {
    title: string,
    children: any,
    color?: string | null
    description?: any

}

interface CustomMetricCardProps {
    metric: {
        flags: object[],
        label: string,
        default_flag: string
    },
    // eslint-disable-next-line react/no-unused-prop-types
    pallets?: any[], // TODO: why is it complaining that it is not being used
    // eslint-disable-next-line react/no-unused-prop-types
    firstWeekDay?: dayjs.Dayjs
}

interface GeneralMetricCardProps extends CustomMetricCardProps {
    field: string,
    displayType: "count" | "list" | "range"
}

function getFlagColor(value, flags, default_flag) {
    const flag = find_category(value, flags);
    if (flag) {
        return flag.flag;
    }
    return default_flag;

}

export function RipeWiseMetricMetaTable() {
    const config = useConfig();
    const { pallets, firstWeekDay, fruit_type } = useRipeningRoom();
    const isItemAllocatedOnDate = isItemAllocatedOnDateHook();
    const activePallets = pallets.filter((p) => isItemAllocatedOnDate(p.id, firstWeekDay));


    const metrics = applyDisplayFilterFields(
        config?.root_config?.ripening_room_metric_cards,
        { fruit_type }
    );

    if (!isFilledArray(metrics)) {
        return null;
    }

    return <table className="w-100">

        <tbody>
            {metrics.map((i, index) => {
                const MetricComponent = MetaRowComponents[i.key];
                if (!MetricComponent) {
                    // eslint-disable-next-line no-console
                    console.log(`MetricCardComponent not found for key: ${i.key}`);
                    return null;
                }
                return <MetricComponent key={index} metric={i} pallets={activePallets} firstWeekDay={firstWeekDay} />;
            })}
        </tbody>
    </table>;
}


function MetricMetaRow({ title, children, color, description }: MetricCardProps) {
    const [modal, setModal] = useState(false);

    const getIcon = (color) => {
        if (color === "success") {
            return <span className="text-success">
                <FontAwesomeIcon icon={faInfoCircle} />
            </span>;
        }
        if (color === "warning") {
            return <span className="text-warning">
                <FontAwesomeIcon icon={faTriangleExclamation} />
            </span>;
        }
        if (color === "danger") {
            return <span className="text-danger">
                <FontAwesomeIcon icon={faTriangleExclamation} />
            </span>;
        }
        return <span css={css`opacity: 0.4;`}>
            <FontAwesomeIcon icon={faInfoCircle} />
        </span>;
    };
    return <tr>
        <td className="pb-2 pe-3 align-top fw-bold text-nowrap">{title}</td>
        <td className="pb-2 text-end align-top" onClick={() => setModal(true)} >
            {children}
        </td>
        <td className="pb-2 align-top text-end" onClick={() => setModal(true)}>
            {getIcon(color)}
            {description && <Modal isOpen={modal} toggle={() => setModal(false)}>
                <ModalHeader toggle={() => setModal(false)}>Description</ModalHeader>
                <ModalBody>{description}</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            }
        </td>
    </tr>;
}


function MetaRowListUnqiue({ metric, pallets, firstWeekDay, field, displayType }: GeneralMetricCardProps) {
    const description = <span>This metric shows the count of unique {metric.label} on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const unique = new Set(pallets?.filter((i) => i[field]).map((i) => i[field]));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);
    const display = (displayType === "count" && unique.size !== 1) || unique.size > 6 ? uniqueCount : Array.from(unique).map((i) => getFormOptionsLabel(field, i)).join(", ");
    return (<MetricMetaRow title={metric.label} color={flag} description={description}>{display}</MetricMetaRow>);
}


function MetaRowAgeAverage({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the age on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const harvestDates = (pallets || [])
        .filter((i) => i.harvest_date)
        .map((i) => new Date(i.harvest_date).getTime());

    if (harvestDates.length === 0) {
        return <MetricMetaRow title={metric.label} description={description}>-</MetricMetaRow>;
    }

    const min_harvest_date = new Date(Math.min(...harvestDates));
    const max_harvest_date = new Date(Math.max(...harvestDates));

    const max_age = dayjs().diff(min_harvest_date, "day");
    const min_age = dayjs().diff(max_harvest_date, "day");

    return (<MetricMetaRow title={metric.label} description={description}>{min_age}d - {max_age}d</MetricMetaRow>);
}

function MetaRowAverageDryMatter({ metric }: CustomMetricCardProps) {
    const { firstWeekDay } = useRipeningRoom();
    const getAllocatedLayers = getAllocatedLayersHook();
    const layers = getAllocatedLayers(firstWeekDay);
    const description = <span>Displays the range of average Dry Matter value from lab checks.</span>;

    const dryMatterValues = getFilledArrayOrDefault(layers).map((i) => i.lab_check_average_dry_matter).filter((i) => i).map((i) => Number(i));

    if (dryMatterValues.length === 0) {
        return <MetricMetaRow title={metric.label} description={description}>-</MetricMetaRow>;
    }

    const min_dry_matter = Math.min(...dryMatterValues);
    const max_dry_matter = Math.max(...dryMatterValues);
    const flag = getFlagColor(min_dry_matter, metric.flags, metric.default_flag);

    return (<MetricMetaRow title={metric.label} color={flag} description={description}>{min_dry_matter} - {max_dry_matter}</MetricMetaRow>);
}

function MetaRowOccupancyLatest({ metric, firstWeekDay }: CustomMetricCardProps) {
    const current = useSelector((state: any) => state.facilityLocations.current);
    const { today } = useRipeningRoom();
    const description = <span>This metric shows the occupancy on <b>{formatDate(firstWeekDay)}</b>.</span>;

    const getAllocatedLayers = getAllocatedLayersHook();
    const loaded = getAllocatedLayers(today, LAYER_TYPE.PALLET).length;

    return (<MetricMetaRow title={metric.label} description={description}>{toPercentage(loaded, current.capacity)} </MetricMetaRow>);
}

function MetaRowNumberOfPurchaseOrders({ metric, pallets, firstWeekDay }: CustomMetricCardProps) {
    const description = <span>This metric shows the count of unique PO on <b>{formatDate(firstWeekDay)}</b>.</span>;
    const parents = (pallets || []).flatMap((i) => i.parents.map(({ id, label }) => ({ id, label })));
    const unique = new Set((parents || []).map((i) => i.id));
    const uniqueCount = String(unique.size);
    if (!uniqueCount) {
        return null;
    }
    const flag = getFlagColor(uniqueCount, metric.flags, metric.default_flag);

    // Generate layer links with commas as separators
    const layerLinks = Array.from(unique).map((id, index) => {
        const label = parents.find((p) => p.id === id)?.label || id;
        return (
            <React.Fragment key={id}>
                <Link to={`/layers/${id}`}>{label}</Link>
                {index < unique.size - 1 && ", "}
            </React.Fragment>
        );
    });

    return (<MetricMetaRow title={metric.label} color={flag} description={description}>{layerLinks}</MetricMetaRow>);
}

