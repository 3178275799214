/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, FormGroup, Input, Table } from "reactstrap";
import { listLayers } from "../../../actions/Layers/actions";
import { addBrowseHistory, resetBrowseHistory } from "../../../actions/Navigation/actions";
import useConfig, { useTenantFormOptions } from "../../../actions/Tenants/config/configHook";
import LayerSortableHeader, { HandleUnknownLayerFieldFilter } from "../../Helper/LayerSortableHeader";
import { Loading } from "../../Helper/Loading";
import { PaginationRow } from "../../Helper/PaginationRow";
import { TableCell, TableHeaderCell } from "../../Helper/Table";
import { useSwitchTabContext } from "./fields/useSwitchTab";
import LayerListCta, { BulkLayerListCTA } from "./LayerListCta";

export const DataTestId = {
    TAB: "TAB",
    TAB_CONTENT: "TAB_CONTENT",
};

export default function LayerList() {
    return <LayerTable />;
}


export function LayerTable() {
    const isLoading = useSelector<any, any>((state) => state.layers.isLoading);
    const layers = useSelector<any, any>((state) => state.layers.items);
    const config = useConfig();
    const dispatch = useDispatch();
    const filter = useSelector<any, any>((state) => state.layers.filter);
    const navigate = useNavigate();
    const form_options = useTenantFormOptions();
    const stage = config.get_stage(filter.location);
    const location = useLocation();


    const { fields, activeTab, tab_category, selectedLayers, setSelectedLayers, selectedLayersArray } = useSwitchTabContext();

    const toggleSelectedLayer = (layer_id) => setSelectedLayers((prev) => ({ ...prev, [layer_id]: !prev[layer_id] }));
    const selectALlorNothing = () => {
        if (Object.keys(selectedLayers).length > 0) {
            setSelectedLayers({});
            return;
        }
        const newSelected = layers.results.reduce((acc, i) => {
            acc[i.id] = true;
            return acc;
        }, {});
        setSelectedLayers(newSelected);
    };

    useEffect(() => {
        if (location) {
            dispatch(resetBrowseHistory());
            dispatch(addBrowseHistory({ pathname: location.pathname, level: 0 }));
        }
    }, [location]);


    // Overwrite hardcoded fields with managed fields from the DB
    if (!layers || !stage) {
        return null;
    }

    const updateLimit = (limit) => {
        dispatch(listLayers({ offset: 0, limit }));
    };

    const onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = Math.max((currentPage - 1) * pageLimit, 0);
        if (filter.offset !== offset) {
            dispatch(listLayers({ offset }));
        }
    };
    // sometimes needed when switching tenant
    if (!fields) {
        return null;
    }

    // SUPPLIER PORTAL FIELDS VALUES
    const values = (p) => fields.map((i, index) => <TableCell key={index}>
        {i.set_value({
            config,
            layer: p,
            check: null, // * To be used in check tables and layer overview
            fruit: null, // * Fruit list is not available on overview screen
            children: null, // * children is only available on layer index page
            form_options // * used to translate form values to labels
        }).display_component()}
    </TableCell>);

    const header = () => fields.map((i, index) => <LayerSortableHeader key={index} field={i} />);

    return <div className="pt-4 d-flex flex-column" css={css`min-height: 70vh;`} >
        <div >

            <Table size="sm" responsive={true} borderless={true}>
                <thead>
                    <tr>
                        {activeTab?.cta_form_title && <TableHeaderCell>
                            <FormGroup check>
                                <Input onChange={() => selectALlorNothing()} checked={Boolean(Object.keys(selectedLayers).length > 0 && layers.results.length > 0 && Object.keys(selectedLayers).length === layers.results.length)} type="checkbox" />
                            </FormGroup>
                        </TableHeaderCell>}
                        {header()}
                        {activeTab?.cta_form_title && <TableHeaderCell>&nbsp;</TableHeaderCell>}
                    </tr>
                </thead>
                {!isLoading && <tbody>
                    {layers.results.map((i, index) => <tr key={index} title={i.id} >
                        {activeTab?.cta_form_title && <TableCell>
                            <FormGroup check>
                                <Input onChange={() => toggleSelectedLayer(i.id)} checked={Boolean(selectedLayers[i.id])} type="checkbox" />
                            </FormGroup>
                        </TableCell>}
                        {values(i)}
                        {activeTab?.cta_form_title && <td className="text-end">
                            {/* <Button size="sm" onClick={() => navigate(`/layer/${i.id}`)}>View</Button> */}
                            <Button color="primary" size="sm" onClick={() => navigate(`/layer/tab/${tab_category}/${activeTab?.value}/cta/${i.id}`)}>{activeTab?.cta_form_title}</Button>
                        </td>}
                    </tr>)}
                </tbody>}
            </Table >
            {isLoading && <Loading />}
        </div>
        <PaginationRow results={layers.results} totalCount={layers.count} filter={filter} onPageChanged={onPageChanged} updateLimit={updateLimit} />
        <LayerListCta />
        <BulkLayerListCTA selectedLayers={selectedLayersArray} deselectAll={() => setSelectedLayers({})} />
        <HandleUnknownLayerFieldFilter />
    </div>;
}
