/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";
import { Col, Container, Row } from "reactstrap";
import logo from "../../../../img/avos-eye.jpg";

export default function Layout({ children }: { children: ReactNode }) {
    return (
        <Container>
            <Row
                className="align-items-center justify-content-center text-center"
                css={css`height: 100vh;`}
            >
                <Col
                    xs="12"
                    md="6"
                    lg="4"
                    className="d-flex flex-column gap-5"
                    css={css`max-width: 26rem; width: 100%;`}
                >
                    <div className="text-center pb-3">
                        <img
                            src={logo}
                            alt="EYE Logo"
                            css={css`max-width: 11rem; width: 50%;`}
                        />
                    </div>

                    {children}
                </Col>
            </Row>
        </Container>
    );
}
