import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { getUser as getGeneralUser } from "../../../actions/Auth/actions";
import { ConfigProvider } from "../../../actions/Tenants/config/configHook";
import { scopes } from "../../../authConfig";
import { IS_DEMO } from "../../../constants/urls";
import { Loading } from "../../Helper/Loading";
import DefaultLayout from "../Layout/DefaultLayout";
import UserNotAuthorized from "./UserNotAuthorized";

interface RequireAuthPropsType {
    element: React.ReactNode;
    header?: React.ReactNode;
    fullscreen?: boolean;
    disableRefresh?: boolean;
    getUser?: (data?: any) => void;
}

export function RequireAuth({ element, fullscreen = false, disableRefresh = false, header, getUser = getGeneralUser }: RequireAuthPropsType) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [authCheckComplete, setAuthCheckComplete] = useState(false); // Track whether auth check is done
    const { authenticated, isLoading } = useSelector((state: any) => state.auth);
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    const accessTokenRequest = {
        scopes,
        account,
        redirectUri: "/blank.html",
    };

    const redirectToLogin = () => navigate(`/login?redirect_to=${location.pathname}${location.search}`);

    useEffect(() => {
        // AUTH scheme is overwritten in demo
        if (IS_DEMO) {
            dispatch(getUser() as any);
            setAuthCheckComplete(true); // Mark as complete in demo mode
            return;
        }

        if (account) {
            // Attempt to refresh token
            instance.acquireTokenSilent(accessTokenRequest as any).then((response) => {
                if (response) {
                    dispatch(getUser() as any);
                }
                setAuthCheckComplete(true); // Auth check complete
            }).catch((error) => {
                console.error(error); // eslint-disable-line no-console
                redirectToLogin();
                setAuthCheckComplete(true); // Complete even on error
            });
        } else {
            redirectToLogin();
            setAuthCheckComplete(true); // Complete if no account
        }
    }, [
        account?.homeAccountId, // * As soon as MS account id is available, we can check for auth against our own backend to fetch user & tenant info
        instance,
        IS_DEMO
    ]); // Prevent infinite loop by referring to homeAccountId


    // Render child component or fallback based on authentication state
    const getChildComponent = () => {
        console.log("getChildComponent"); // eslint-disable-line no-console
        console.log("authenticated", authenticated); // eslint-disable-line no-console
        console.log("authCheckComplete", authCheckComplete); // eslint-disable-line no-console
        console.log("isLoading", isLoading); // eslint-disable-line no-console
        console.log("Account Info:", { username: account?.username, uniqueId: account?.homeAccountId }); // eslint-disable-line no-console
        if (!authenticated && (!authCheckComplete || isLoading)) {
            // Show loading while auth check is in progress and we are not authenticated yet (so not for every refresh)
            return <Loading />;
        }
        if (authCheckComplete && ((!account && !IS_DEMO) || !authenticated)) {
            // Show UserNotAuthorized only after auth check is complete and is not loading
            return <UserNotAuthorized />;
        }

        return element;
    };

    return (
        <ConfigProvider refreshInterval={disableRefresh ? false : 6 * 60 * 1000}>
            <DefaultLayout
                fullscreen={fullscreen}
                header={fullscreen ? undefined : header}
            >
                {getChildComponent()}
            </DefaultLayout>
        </ConfigProvider>
    );
}
