
/** @jsxImportSource @emotion/react */
import { FromLink } from "../../../../utils/useNavigateFrom";
import { DataSource } from "./Field";
import LayerField from "./LayerField";

class LabelLinkField extends LayerField {
    field_type = "label_link_field";

    backend_table = "layer";

    get_backend_fieldname(): string[] {
        return [`${this.backend_table}.label`, `${this.backend_table}.id`];
    }

    layer_id?: string;

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        // always reset the value
        this.value = null;
        this.value = this.value_filter(datasource.layer[this.fieldname_getter], datasource);
        this.layer_id = datasource.layer?.id || undefined;
        return this;
    }

    display_component(): JSX.Element {
        return <FromLink to={`/layer/${this.layer_id}`}>{this.value}</FromLink>;
    }

}

export default LabelLinkField;
