/** @jsxImportSource @emotion/react */
import { View } from "@react-pdf/renderer";
import { Badge } from "reactstrap";
import { FILTER_METHOD_TYPES } from "../../../../actions/Layers/actions";
import Field, { DataSource } from "./Field";

class LayerMetaColorBadgeField extends Field {
    field_type = "layer_meta_color_code_field";

    backend_table = "layer_meta";

    filter_config = {
        formatter: (value: string | number | null) => value as string,
        filter_method_type: FILTER_METHOD_TYPES.COLOR_CODE,
    };

    is_filterable(): boolean {
        return true;
    }

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        let value = "success";
        if (datasource.layer && datasource.layer[this.fieldname_getter]) {
            value = datasource.layer[this.fieldname_getter];
        }
        this.value = this.value_filter(value, datasource);
        return this;

    }


    display_component(): JSX.Element {
        // !warning: please do not remove the space between the badge tags, it is needed to render the badges correctly
        return <span className="layer-meta-color-badge-field">
            <Badge style={{ padding: "10px" }} color={this.value}> </Badge>
        </span>;
    }

    display_vector(): JSX.Element {
        const colors = {
            light: "#f8f9fa",
            success: "#198754",
            danger: "#dc3545",
            warning: "#ffc107",
            info: "#0dcaf0",
            primary: "#0d6efd",
            secondary: "#6c757d",
        };
        return <View style={{ width: "11px", height: "11px", borderRadius: "50%", backgroundColor: colors[this.value] }} >
        </View>;
    }

}

export default LayerMetaColorBadgeField;
