import { View } from "@react-pdf/renderer";
import { tableStyles } from "./PDFTable";

/**
 * Chunk an array into smaller arrays of a specified size, keeping the header for each chunk
 * @param array - The array to be chunked
 * @param size - The size of each chunk
 * @returns The chunked array with headers included in each chunk
 */

// chunk an array into smaller arrays of a specified size, keeping the header for each chunk
const chunkArrayWithHeader = (array: any, size: number) => {
    const chunkedArr: any = [];
    for (let i = 0; i < array.length; i += size) {
        const chunk = array.slice(i, i + size);
        // Include the header cell in each chunk
        if (i > 0) {
            chunk.unshift(array[0]);
        }
        chunkedArr.push(chunk);
    }
    return chunkedArr;
};


interface PDFCheckTableProps {
    columns: any[][];
    maxRows?: number;
}

const PDFCheckTable: React.FC<PDFCheckTableProps> = ({ columns, maxRows }) => {
    const rowsPerPage = maxRows ?? 16; // a fix number of rows per page
    // Chunk columns into pages based on rowsPerPage
    const chunkedColumns = columns.map((column) => chunkArrayWithHeader(column, rowsPerPage));

    // Create pages
    const pages: any = [];
    for (let i = 0; i < chunkedColumns[0].length; i++) {
        const pageColumns = chunkedColumns.map((columnChunks) => columnChunks[i]);
        pages.push(pageColumns);
    }

    return (
        <>
            {pages.map((pageColumns, pageIndex) => (
                <View key={pageIndex} style={{ marginBottom: 40 }}>
                    <View style={tableStyles.table}>
                        {pageColumns.map((column, colIndex) => (
                            <View key={colIndex} style={{ flexGrow: 1 }}>
                                {/* Render the header cell */}
                                <View key={`${colIndex}-header`}>
                                    {column[0]}
                                </View>
                                {/* Render the rest of the cells */}
                                {column.slice(1).map((cell, cellIndex) => (
                                    <View key={`${colIndex}-${cellIndex}`}>
                                        {cell}
                                    </View>
                                ))}
                            </View>
                        ))}
                    </View>
                </View>
            ))}
        </>
    );
};

export default PDFCheckTable;
