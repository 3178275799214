/* eslint-disable no-case-declarations */
import { BROWSE_HISTORY } from "./actionTypes";
import { Location } from "./constants";


export interface NavigationState {
    browse_history: Location[];
}

const navigation = {
    browse_history: [] as Location[],
};

export default (state: NavigationState = navigation, action): NavigationState => {
    switch (action.type) {
    case BROWSE_HISTORY.SUCCESS: {
        const lastEntry = state.browse_history.length > 0 ? state.browse_history.at(-1) : null;
        // Prevent adding duplicate locations when both the pathname and label are the same as the last one
        if (lastEntry && lastEntry.pathname === action.payload.pathname && lastEntry.id === action.payload.id) {
            return state;
        }
        return {
            ...state,
            browse_history: [...state.browse_history, action.payload]
        };
    }
    case BROWSE_HISTORY.RESET:
        return {
            ...state,
            browse_history: []
        };

    default:
        return state;
    }
};


