
import { Text, View } from "@react-pdf/renderer";
import { useCallback, useMemo } from "react";
import { applyTriggerFilterFlags } from "../../../../../actions/Tenants/config/applyDisplayFilter";
import { find_category } from "../../../../../actions/Tenants/config/utils";
import { doFilterDefects } from "../../../../Forms/useManagedOptionsHook";
import { toPercentage } from "../../fields/formatters";
import { styles } from "./PDFCheckFruitTables";
import { truncateText } from "./utils";

const textFlagColors = {
    success: "#13a55f",
    danger: "#dc3545",
    warning: "#ffc107",
};

export const ColorCodedTextVector = ({ children, flag, fontSize = "9px" }) => {
    const color = textFlagColors[flag] ? { color: textFlagColors[flag] } : {};
    return <Text style={{ fontSize, ...color }}>{children}</Text>;
};


const PDFCheckChildrenDefects = ({ defect_group, all_defects, check, layer }: any) => {

    if (!all_defects || !check) return null;

    const group_defects = doFilterDefects({ defect_position: defect_group.defect_position, defect_severity: defect_group.defect_severity }, all_defects);
    const group_defect_ids = group_defects.map((i) => i.defect_id);
    const metric_meta_key = `defects_${[...(defect_group.defect_position || []), ...(defect_group.defect_severity || [])].join("_")}`;
    // * set this flag using the color coding wizard
    const total_flag_name = `flag_${metric_meta_key}`;

    const denominator = defect_group.denominator === "sample_size" ? check.sample_size : check.avocados.length;
    const decimals = defect_group.decimals || 1;

    const flags = useMemo(() => applyTriggerFilterFlags(defect_group.flags || [], layer, check), [layer, check]);

    // * Use this function to get the flag color for indivudal defects
    const getFlag = useCallback((value) => {
        const flag = find_category(value / denominator, flags);
        if (flag) {
            return flag.flag;
        }
        return "";
    }, [flags, denominator]);


    // Defects registered on fruit by fruit
    const fruit_defects = check.avocados.flatMap((avocado) => avocado.defects);

    // We assume here that what ever is registered on a check level via visual check or ART is not also registered on a fruit level
    const registered_defects = [...check.defect_list, ...fruit_defects];
    const total_defects_per_type = registered_defects.reduce((acc, i) => ({ ...acc, [i.defect_id]: (acc[i.defect_id] || 0) + i.frequency }), {});

    // Count the number of fruit having at least one defect from this group
    const defectedFruit = check.avocados.filter((avocado) => avocado.defects.filter((i) => i.frequency > 0 && group_defect_ids.includes(i.defect_id)).length > 0).length;
    const sum_of_all_defects = group_defect_ids.reduce((acc, defect_id) => acc + (total_defects_per_type[defect_id] || 0), 0);
    const totalNumerator = defect_group.denominator === "sample_size" ? sum_of_all_defects : defectedFruit;
    const display_defects = group_defects.filter((i) => {
        const numerator = total_defects_per_type[i.defect_id] || 0; // Use 0 if undefined
        const denominatorValue = denominator;

        // Calculate percentage
        if (denominatorValue && denominatorValue > 0) {
            const percentage = (numerator / denominatorValue) * 100;
            // Round the percentage to the specified decimals
            const roundedPercentage = Math.round(percentage * 10 ** decimals) / 10 ** decimals;

            return roundedPercentage > 0; // Filter out 0% values
        }

        return false; // Exclude if denominator is not valid
    });

    return (

        <View style={[styles.container, styles.horizontalMargins]}>
            <View style={styles.cell}>
                <View style={styles.metaItems}>
                    <View style={{ maxWidth: "80%" }}>
                        <Text style={{ fontSize: "9px" }}>{defect_group.title}: </Text>
                    </View>
                    <View style={styles.metaTextValueIntakeReport}>
                        <ColorCodedTextVector flag={check[total_flag_name]}> {toPercentage(totalNumerator, denominator, decimals)}</ColorCodedTextVector>
                    </View>
                </View>
                <View style={styles.divider}></View>
                {display_defects.map((i, index) => (
                    <View key={index} style={styles.metaItems}>
                        <View style={{ maxWidth: "80%" }}>
                            <Text style={{ fontSize: "9px" }}>{truncateText(i.label, 22, 20)}:</Text>
                        </View>
                        <View>
                            <ColorCodedTextVector flag={getFlag(total_defects_per_type[i.defect_id])}> {toPercentage(total_defects_per_type[i.defect_id], denominator, decimals)}</ColorCodedTextVector>
                        </View>
                    </View>
                ))}
            </View>
        </View>
    );
};


export default PDFCheckChildrenDefects;


