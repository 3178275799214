import { avocado_min_weight } from "../../../Layers/constants";
import {
    baseConfig,
    headerLinks
} from "../constants";
import { RootConfigType } from "../constantsTyped";
import { AVOCADO_FRUIT } from "../fruitNames";

export const MissionBredaConfig: RootConfigType = {
    ...baseConfig,
    default_fruit_type: AVOCADO_FRUIT,
    underweight: avocado_min_weight,
    indicate_quality_at_defects: false,
    lang: {
        pressure: "Pressure"
    },
    // On take numbers from the string
    barcode_filter: (scanned) => scanned.replace(/\D/g, ""),
    headerMainLinks: [headerLinks.home, headerLinks.packinglist, headerLinks.mailbox],
    headerSecondaryLinks: [headerLinks.packinglist, headerLinks.settings, headerLinks.logout],
};
