
import {
    baseConfig,
    headerLinks
} from "../constants";
import { RootConfigType } from "../constantsTyped";
import { DEFAULT_FRUIT, } from "../fruitNames";


export const GlobalPacificConfig: RootConfigType = {
    ...baseConfig,
    default_fruit_type: DEFAULT_FRUIT,
    indicate_quality_at_defects: false,
    strict_check_size: false,
    indicate_soft_avocado_at_internal: false,
    suggest_color_stage: true,
    headerMainLinks: [headerLinks.home, headerLinks.packinglist],
    headerSecondaryLinks: [headerLinks.packinglist, headerLinks.settings, headerLinks.logout],
};
