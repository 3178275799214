/** @jsxImportSource @emotion/react */
import {
    useLocation
} from "react-router-dom";
import { LAYER_TAB_CATEGORIES } from "../../../../actions/Layers/constants";
import { headerLinks } from "../../../../actions/Tenants/config/constants";
import { FromLink } from "../../../../utils/useNavigateFrom";

export type GeneralLinkType = {
    path: string;
    label: string;
}

export const generalLinks = {
    [headerLinks.home]: (label) => <GeneralLink key={headerLinks.home} path={`/layer/tab/${LAYER_TAB_CATEGORIES.OPERATION}/first`} label={label || "Overview"} />, // Legacy EYE before the planning module
    [headerLinks.tabCategoryOperation]: (label) => <GeneralLink key={headerLinks.tabCategoryOperation} path={`/layer/tab/${LAYER_TAB_CATEGORIES.OPERATION}/first`} label={label || "Operation"} />,
    [headerLinks.tabCategoryStock]: (label) => <GeneralLink key={headerLinks.tabCategoryStock} path={`/layer/tab/${LAYER_TAB_CATEGORIES.STOCK}/first`} label={label || "Stock"} />,
    [headerLinks.tabCategoryQCPlanning]: (label) => <GeneralLink key={headerLinks.tabCategoryQCPlanning} path={`/layer/tab/${LAYER_TAB_CATEGORIES.QC_PLANNING}/first`} label={label || "QC"} />,
    [headerLinks.tabCategoryQCReport]: (label) => <GeneralLink key={headerLinks.tabCategoryQCReport} path={`/layer/tab/${LAYER_TAB_CATEGORIES.QC_REPORT}/first`} label={label || "Reports"} />,
    [headerLinks.tabCategoryTransit]: (label) => <GeneralLink key={headerLinks.tabCategoryTransit} path={`/layer/tab/${LAYER_TAB_CATEGORIES.TRANSIT}/first`} label={label || "Transit"} />,
    [headerLinks.mailbox]: (label) => <GeneralLink key={headerLinks.mailbox} path="/mailbox" label={label || "Mailbox"} />, // TODO: migrate this to QC_REPORT
    [headerLinks.insights]: (label) => <GeneralLink key={headerLinks.insights} path="/power-bi" label={label || "Insights"} />,
    [headerLinks.ripening]: (label) => <GeneralLink key={headerLinks.ripening} path="/ripening-room" label={label || "Ripening"} />,
    [headerLinks.waste]: (label) => <GeneralLink key={headerLinks.waste} path="/waste" label={label || "Waste"} />,
    [headerLinks.labelling]: (label) => <GeneralLink key={headerLinks.labelling} path="/labelling" label={label || "Labelling"} />,
    [headerLinks.packinglist]: (label) => <GeneralLink key={headerLinks.packinglist} path="/packing-list" label={label || "Packing List"} />, // TODO: migrate to TRANSIT tab
    [headerLinks.deviceManagement]: (label) => <GeneralLink key={headerLinks.deviceManagement} path="/device-managment" label={label || "Device Management"} />,
    [headerLinks.tenants]: (label) => <GeneralLink key={headerLinks.tenants} path="/tenants" label={label || "Tenants"} />,
    [headerLinks.settings]: (label) => <GeneralLink key={headerLinks.settings} path="/settings" label={label || "Settings"} />,
    [headerLinks.admin]: (label) => <GeneralLink key={headerLinks.admin} path="/admin" label={label || "Admin"} />,
};

export const GeneralLink = ({ path, label }: GeneralLinkType) => {
    const location = useLocation();
    // remove /first from path to make sure the active class is set correctly for tab Categories
    const cleanedPath = path.endsWith("/first") ? path.slice(0, -"/first".length) : path;
    const isActive = location.pathname.startsWith(cleanedPath);

    return (
        <li className={`nav-item ${isActive ? "active" : ""}`} key={path}>
            <FromLink className={`nav-link ${isActive ? "active fw-bold" : ""}`} to={path}>{label}</FromLink>
        </li>
    );
};
