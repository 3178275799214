/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useDrag, useDrop } from "react-dnd";
import { ReactNode } from "react";

type DraggableFieldProps = {
    index: number;
    children?: ReactNode;
    itemType: string;
    onUpdatePosition(from: number, to: number): void;
}

export const DraggableField = ({ index, children, itemType, onUpdatePosition }: DraggableFieldProps) => {
    const [, ref] = useDrop({
        accept: itemType,
        drop(item: any) {
            if (item.index !== index) {
                onUpdatePosition(item.index, index); // Perform the swap only when dropped
            }
        },
    });

    const [, drag] = useDrag({
        type: itemType,
        item: { index },
    });

    return (
        <div ref={(node) => drag(ref(node))} className="border mb-3 p-3" css={css`background: #fcfcfc;`}>
            {children}
        </div>
    );
};
