import { FormCheckboxManagedField, FormFruitImagesField, FormImageRadioButtonsField, FormNumberField, FormRadioField } from "./constantsTyped";
import { DEFECT_POSITION, DEFECT_SEVERITY } from "../../Checks/constants";

export const pressure: FormNumberField = {
    label: "Pressure",
    name: "pressure",
    type: "number",
    mandatory: false,
    min: 0,
    max: 100,
    unity: "LBS",
    lg: 3
};

export const weight: FormNumberField = {
    label: "Weight",
    name: "weight",
    type: "number",
    mandatory: false,
    min: 0,
    max: 9999,
    unity: "GR",
    lg: 3
};

export const taste: FormRadioField = {
    label: "Taste",
    name: "manual_taste",
    type: "radio",
    options: [
        { text: "Balanced", value: "balanced" },
        { text: "Sweet", value: "sweet" },
    ],
};

export const internal_color: FormRadioField = {
    label: "Internal Color",
    name: "manual_internal_color_stage",
    type: "radio",
    options: [
        { text: "1", value: 1, color: "#f8d37a", className: "me-3 text-white" },
        { text: "2", value: 2, color: "#f0d35b", className: "me-3 text-white" },
        { text: "3", value: 3, color: "#e7bf43", className: "me-3 text-white" },
        { text: "4", value: 4, color: "#f6b041", className: "me-3 text-white" },
        { text: "5", value: 5, color: "#f19940", className: "me-3 text-white" },
    ],
    mandatory: true
};

export const radio_button_images: FormImageRadioButtonsField = {
    label: "Your label",
    name: "your_meta_key",
    type: "image-radiobutton",
    mandatory: true,
    options: []
};


export const defects: FormCheckboxManagedField = {
    label: "Defects",
    name: "defects",
    type: "checkbox-managed",
    defect_position: Object.values(DEFECT_POSITION),
    defect_severity: Object.values(DEFECT_SEVERITY)

};
const internal_defects: FormCheckboxManagedField = {
    ...defects,
    label: "Internal Defects",
    defect_position: [DEFECT_POSITION.INTERNAL],
    defect_severity: Object.values(DEFECT_SEVERITY)

};

export const external_defects: FormCheckboxManagedField = {
    ...defects,
    label: "External Defects",
    defect_position: [DEFECT_POSITION.EXTERNAL],
    defect_severity: Object.values(DEFECT_SEVERITY)

};

// // at some point we can remove
// export const mango_internal_quality_check_field: FormCheckboxManagedField = { ...internal_defects, deprecated: true };

// export const avocado_internal_quality_check_field: FormCheckboxManagedField = { ...internal_defects, deprecated: true };

// export const avocado_external_quality_check_field: FormCheckboxManagedField = { ...external_defects, deprecated: true };

// export const mango_external_quality_check_field: FormCheckboxManagedField = { ...external_defects, deprecated: true };

export const maturity: FormRadioField = {
    label: "Maturity",
    name: "manual_maturity",
    type: "radio",
    options: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "2.5", value: "2.5" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
    ],
    mandatory: true
};

export const brix: FormNumberField = {
    label: "Brix",
    name: "manual_brix",
    type: "number",
    min: 0,
    max: 100,
    unity: "%",
    lg: 3
};

// export const avocado_taste: FormRadioManagedField = {
//     label: "Taste score",
//     name: "manual_taste",
//     type: "radio-managed",
//     options_name: "manual_taste_avocado",
//     mandatory: true,
//     deprecated: true
// };

// export const avocado_mouth_ripeness_field: FormRadioManagedField = {
//     label: "Mouth ripeness",
//     name: "mouth_ripeness",
//     type: "radio-managed",
//     options_name: "mouth_ripeness_avocado",
//     mandatory: true,
//     deprecated: true
// };

// export const avocado_flavour_nuttiness_field: FormRadioManagedField = {
//     label: "Flavour nuttiness",
//     name: "flavour_nuttiness",
//     type: "radio-managed",
//     options_name: "flavour_nuttiness_avocado",
//     mandatory: true,
//     deprecated: true
// };

// export const avocado_flavour_creamy_field: FormRadioManagedField = {
//     label: "Flavour creamy",
//     name: "flavour_creamy",
//     type: "radio-managed",
//     options_name: "flavour_creamy_avocado",
//     mandatory: true,
//     deprecated: true
// };

export const temperature: FormNumberField = {
    label: "Temperature",
    name: "manual_temperature",
    type: "number",
    mandatory: false,
    min: -100,
    max: 100,
    unity: "°C",
    lg: 3
};

export const fruit_images: FormFruitImagesField = {
    label: "Fruit Images",
    name: "fruit_images",
    type: "fruit-images",
};

export const fruit_diameter: FormNumberField = {
    label: "Diameter",
    name: "fruit_diameter",
    type: "number",
    mandatory: false,
    min: 0,
    unity: "mm",
    lg: 3
};

const fruitFormField = {
    defects,
    internal_defects,
    external_defects,
    // avocado_internal_quality_check_field,
    // avocado_external_quality_check_field,
    // avocado_mouth_ripeness_field,
    // avocado_taste,
    // avocado_flavour_nuttiness_field,
    // avocado_flavour_creamy_field,
    // mango_internal_quality_check_field,
    // mango_external_quality_check_field,
    radio_button_images,
    maturity,
    pressure,
    taste,
    internal_color,
    brix,
    weight,
    temperature,
    fruit_images,
    fruit_diameter
};

Object.keys(fruitFormField).forEach((key) => {
    fruitFormField[key].form_field_id = key;
});
export { fruitFormField };

