/** @jsxImportSource @emotion/react */
import { faUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BarcodeFormat } from "@zxing/library";
import { Link, useParams } from "react-router-dom";
import { DEFECT_POSITION, DEFECT_SEVERITY } from "../../../../actions/Checks/constants";
import { checkFormFields } from "../../../../actions/Tenants/config/checkFormFields";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { FormFileSelectFieldReadFormat } from "../../../../actions/Tenants/config/constantsTyped";
import { fruitFormField } from "../../../../actions/Tenants/config/fruitFormFields";
import { layerFormFields } from "../../../../actions/Tenants/config/layerFormFields";
import { MimeType } from "../../../../constants/misc";
import FilterableConfigList from "./FilterableConfigList";
import ImageOptionsConfigForm from "./ImageOptionsConfigForm";
import OptionsConfigForm from "./OptionsConfigForm";
import { yesOrNoRadio } from "./util";

const formTypes = [
    "select-managed",
    "text",
    "date-radiobutton",
    "number",
    "checkbox-managed",
    "fruit-images",
    "text-qrcode-scanner",
    "fta-device-field",
    "radio",
    "radio-flag",
    "single-select",
    "radio-managed",
    "layer-select",
    "header",
    "select-creatable",
    "image-radiobutton",
    "layer-select-children",
    "date-picker",
    "file-select",
];


const defaultField = {
    field: {
        label: "Your Label",
        name: "meta_key",
        type: "single-select",
        mandatory: false
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    // exclude_layer_types: [],
    }
};


export default function FormConfigForm({ name, title, fields, description, setArray, useDisplayFilter = ["fruit_types", "exclude_fruit_types", "layer_types", "manual_or_with_mini"] }) {
    const { tenant_id } = useParams();
    const getFieldTitle = (object) => {
        let name = <span>[{object.name}{object.mandatory ? "*" : ""}] - {object.type}</span>;
        const isManaged = ["select-managed", "checkbox-managed", "radio-managed"].includes(object.type);
        if (isManaged) {
            name = <Link to={`/tenants/${tenant_id}/form-field-options/${object.options_name || object.name}`} target="_blank" rel="noopener noreferrer" >[{object.options_name || object.name}{object.mandatory ? "*" : ""}] - {object.type} <FontAwesomeIcon icon={faUpRightFromSquare} /> </Link>;
        }

        return <span>{object.label} {name}</span>;
    };
    const config = useConfig();

    const getFieldForm = (object, setObject) => {
        const formFieldAttributes = [
            {
                type: "header",
                label: "Select predefined field",
            },
            {
                label: "Predefined fruit field",
                type: "single-select",
                name: "form_field_id",
                options: Object.keys(fruitFormField).filter((i) => !fruitFormField[i].deprecated).map((i) => ({ label: i, value: i })),
                onchange_callback: ({ field, value }) => {

                    const predfinedField = fruitFormField[value];

                    if (!predfinedField) {
                        setObject(defaultField);
                        return;
                    }

                    const newObject = Object.keys(predfinedField).reduce((acc, key) => {
                        const attr = predfinedField[key];
                        // * copy all serialiazable values
                        if (!attr || typeof attr === "function") {
                            return acc;
                        }
                        return ({
                            ...acc,
                            [key]: predfinedField[key]
                        });

                    }, { ...object, [field]: value });
                    setObject(newObject);

                }
            },
            {
                label: "Predifined layer field",
                type: "single-select",
                name: "form_field_id",
                options: layerFormFields.map((i) => ({ label: i.label, value: i.form_field_id })),
                onchange_callback: ({ field, value }) => {

                    const predfinedField = layerFormFields.find((i) => i.form_field_id === value);

                    if (!predfinedField) {
                        setObject(defaultField);
                        return;
                    }

                    const newObject = Object.keys(predfinedField).reduce((acc, key) => {
                        const attr = predfinedField[key];
                        // * copy all serialiazable values
                        if (!attr || typeof attr === "function") {
                            return acc;
                        }
                        return ({
                            ...acc,
                            [key]: predfinedField[key]
                        });

                    }, { ...object, [field]: value });
                    setObject(newObject);

                }
            },
            {
                label: "Predefined check field",
                type: "single-select",
                name: "form_field_id",
                options: checkFormFields.map((i) => ({ label: i.label, value: i.form_field_id })),
                onchange_callback: ({ field, value }) => {

                    const predfinedField = checkFormFields.find((i) => i.form_field_id === value);

                    if (!predfinedField) {
                        setObject(defaultField);
                        return;
                    }

                    const newObject = Object.keys(predfinedField).reduce((acc, key) => {
                        const attr = predfinedField[key];
                        // * copy all serialiazable values
                        if (!attr || typeof attr === "function") {
                            return acc;
                        }
                        return ({
                            ...acc,
                            [key]: predfinedField[key]
                        });

                    }, { ...object, [field]: value });
                    setObject(newObject);

                }
            },
            {
                type: "header",
                label: "AND/OR Customize field",
            },
            {
                label: "label",
                type: "text",
                description: "What is the label of this input fields?",
                name: "label",
                advanced: false,
                display_for: false,
                for_types: false,
                onchange_callback: ({ field, value }) => {
                    setObject({
                        ...object,
                        [field]: value,
                        name: value.toLowerCase().replace(/[^a-z0-9]/g, "_")
                    });
                }
            },
            {
                label: "name",
                description: "What is the name of this input fields? This will be used as the key in the form data and in the database.",
                type: "text",
                name: "name",
                onchange_callback: ({ field, value, setValue }) => setValue(field, value.toLowerCase().replace(/[^a-z0-9]/g, "_")),
                advanced: false,
                display_for: false,
                for_types: false,
            },
            {
                label: "type",
                type: "single-select",
                description: "What is the type of this input fields?",
                name: "type",
                advanced: false,
                display_for: false,
                for_types: false,
                options: formTypes.map((i) => ({ label: i, value: i }))
            },
            {
                ...yesOrNoRadio,
                label: "Overwrite barcode template defined in tenant level",
                description: "Overwrite barcode template defined at tenant level in hardcoded config.",
                name: "overwrite_barcode_template",
                advanced: false,
                display_for: false,
                for_types: ["text-qrcode-scanner"],
            },
            {
                label: "Barcode Template",
                type: "text",
                description: "What is the barcode template (regex expression) to eliminate undesired results from the scanner?",
                name: "barcode_template",
                advanced: false,
                display_for: false,
                for_types: ["text-qrcode-scanner"],
            },
            {
                label: "Supported Formats",
                type: "single-select",
                description: "What barcode formats are supported?",
                name: "barcode_formats",
                advanced: false,
                display_for: false,
                for_types: ["text-qrcode-scanner"],
                multi: true,
                return_array: true,
                options: Object.entries(BarcodeFormat).filter(([, value]) => typeof value === "number")
                    .map(([label, value]) => ({ label, value })),
            },
            {
                label: "Data Key",
                type: "text",
                description: "What is the key to get the data from fta response object?",
                name: "data_key",
                advanced: false,
                display_for: false,
                for_types: ["fta-device-field"],

            },
            {
                label: "Data value type",
                type: "single-select",
                description: "What is the type of the data value?",
                name: "data_value_type",
                advanced: false,
                display_for: false,
                for_types: ["fta-device-field"],
                options: [
                    {
                        value: "string",
                        label: "String"
                    },
                    {
                        value: "number",
                        label: "Number"
                    },
                    {
                        value: "boolean",
                        label: "Boolean"
                    }
                ]
            },
            {
                label: "mandatory",
                description: "Is this field mandatory?",
                name: "mandatory",
                advanced: false,
                display_for: false,
                for_types: false,
                ...yesOrNoRadio
            },
            {
                label: "Show error msg if total is not 100%",
                description: "Show error message if the sum of the values in the group is not 100",
                name: "show_error_msg_if_total_is_not_100",
                advanced: false,
                for_types: false,
                display_for: ["visual_check_option_form"],
                ...yesOrNoRadio
            },
            {
                label: "backward",
                type: "number",
                description: "Until how many days back to show dates from?",
                name: "backward",
                advanced: true,
                display_for: false,
                for_types: ["date-radiobutton"],
            },
            {
                label: "forward",
                type: "number",
                description: "How many days forward to show dates from?",
                name: "forward",
                advanced: true,
                display_for: false,
                for_types: ["date-radiobutton"],
            },
            {
                label: "min",
                type: "number",
                description: "Minimum value for this input",
                name: "min",
                advanced: true,
                display_for: false,
                for_types: ["number"],
            },
            {
                label: "max",
                type: "number",
                description: "Maximum value for this input",
                name: "max",
                advanced: true,
                display_for: false,
                for_types: ["number"],
            },
            {
                label: "unity",
                type: "text",
                description: "Unity for this input, e.g. KG, LBS or %.",
                name: "unity",
                advanced: true,
                display_for: false,
                for_types: ["number"],
            },
            {
                label: "lg",
                type: "number",
                description: "How many columns should this input take on desktop screen? Fil something between 1 - 12. Only works if using grid form.",
                name: "lg",
                advanced: true,
                display_for: false,
                for_types: false,
                max: 12,
                min: 1
            },
            {
                label: "default_value",
                type: "text",
                description: "What is the default value for this input? Will update the database when form is rendered.",
                name: "default_value",
                advanced: true,
                display_for: false,
                for_types: false,
            },
            {
                label: "default_value_from_layer_key",
                type: "text",
                description: "Will try to read the value from this key of the layer and set it on this form state. E.g. set box_size to copy layer.box_size to check.box_size or intake_yield to copy layer.intake_yield to check.intake_yield. Only works on forms that have implemented this.",
                name: "default_value_from_layer_key",
                advanced: true,
                display_for: false,
                for_types: false,

            },
            {
                label: "onchange_callback",
                type: "single-select",
                name: "onchange_callback",
                description: "Do you want to call a function when this input changes? If yes, select the function from the list.",
                advanced: true,
                display_for: false,
                for_types: false,
                options: Object.keys(config.onchange_callbacks).map((i) => ({ label: i, value: i }))
            },
            {
                type: "element",
                advanced: false,
                display_for: false,
                for_types: ["single-select", "radio"],
                el: <OptionsConfigForm
                    fields={object.options || []}
                    description="Options for dropdown or radio button. Please use one of the `*-managed` types if you have more then 10 options."
                    name="options"
                    useDisplayFilter={false}
                    title="Options"
                    setArray={(options) => setObject({ ...object, options }) } />
            },
            {
                type: "element",
                advanced: false,
                display_for: false,
                for_types: ["image-radiobutton"],
                el: <ImageOptionsConfigForm
                    fields={object.options || []}
                    description="Image options for dropdown or radio button."
                    name="image_option"
                    useDisplayFilter={false}
                    title="Image option"
                    setArray={(options) => setObject({ ...object, options }) } />
            },
            {
                label: "layer_type",
                type: "text",
                description: "What layer type to search for",
                name: "layer_type",
                advanced: true,
                display_for: false,
                for_types: ["layer-select", "layer-select-children"],
            },
            {
                label: "Disable the button",
                name: "disabled",
                description: "Disable the button if needed",
                advanced: true,
                display_for: false,
                for_types: false,
                ...yesOrNoRadio
            },
            {
                label: "Creatable",
                name: "creatable",
                description: "Is this input creatable? usually used for creating layer if not exist",
                advanced: true,
                display_for: false,
                for_types: false,
                ...yesOrNoRadio
            },
            {
                label: "multi",
                name: "multi",
                advanced: true,
                description: "is this input multi select?",
                display_for: false,
                for_types: false,
                ...yesOrNoRadio
            },
            {
                label: "options_name",
                type: "text",
                name: "options_name",
                description: "what is the name to get the options from? For example if you want to store taste (as field name) but the taste options are stored under `taste_avocado`. Fil in `taste_avocado` as `options_name` and `taste` under `name`. Leave blank to just use name to fetch options.",
                advanced: true,
                display_for: false,
                for_types: ["select-managed", "checkbox-managed", "radio-managed"],
            },
            {
                label: "Pattern",
                type: "text",
                name: "pattern",
                description: "Regular expression pattern to validate the input. To let field start with 08 you will need this pattern /^[08]/, therefore fill `^[08]` in this form.",
                advanced: true,
                display_for: false,
                for_types: false,
            },
            {
                label: "invalid_hint",
                type: "text",
                description: "Show a message to the user if the input doesn't match the pattern.",
                name: "invalid_hint",
                advanced: true,
                display_for: false,
                for_types: false,
            },
            // {
            //     label: "image",
            //     type: "text",
            //     name: "image",
            //     advanced: true,
            //     display_for: false,
            // for_types: ["fruit-images"],
            // },
            {
                label: "meta_options",
                type: "single-select",
                name: "meta_options",
                descirption: "Do you want to show meta data in search results?",
                multi: true,
                options: ["box_size"].map((i) => ({ label: i, value: i })),
                advanced: true,
                display_for: false,
                for_types: ["layer-select", "layer-select-children"],
            },
            {
                label: "Defect position",
                name: "defect_position",
                type: "single-select",
                return_array: true,
                multi: true,
                advanced: false,
                display_for: false,
                for_types: ["checkbox-managed"],
                options: Object.values(DEFECT_POSITION).map((i) => ({ value: i, label: i })),
            },
            {
                label: "Defect Severity",
                name: "defect_severity",
                type: "single-select",
                return_array: true,
                multi: true,
                advanced: false,
                for_types: ["checkbox-managed"],
                display_for: false,
                options: Object.values(DEFECT_SEVERITY).map((i) => ({ value: i, label: i })),
            },
            {
                label: "Show severity on one line",
                name: "defect_severity_on_one_line",
                advanced: false,
                for_types: ["checkbox-managed"],
                display_for: false,
                ...yesOrNoRadio,
            },
            {
                label: "Accept",
                type: "single-select",
                name: "accept",
                description: "What file formats are accepted?",
                advanced: false,
                for_types: ["file-select"],
                multi: true,
                return_array: true,
                options: Object.entries(MimeType).map(([label, value]) => ({ label, value })),
            },
            {
                label: "Read Format",
                type: "single-select",
                name: "readFormat",
                description: "What format to read the file in?",
                advanced: false,
                for_types: ["file-select"],
                display_for: false,
                options: Object.entries(FormFileSelectFieldReadFormat).map(([label, value]) => ({ label, value })),
            }
        ];
        return formFieldAttributes.filter((i) => {
            if (i.for_types && typeof i.for_types !== "boolean") {
                return i.for_types.includes(object.type);
            }
            if (i.display_for && typeof i.display_for !== "boolean") {
                return i.display_for.includes(name);
            }
            return true;
        });
    };
    return <FilterableConfigList
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle}
        useDisplayFilter={useDisplayFilter}
    />;

}


