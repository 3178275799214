import { CREATE_EVENT, DELETE_DEVICE, GET_DEVICE, LIST_DEVICE, LIST_EVENT, LIST_STATUS, POLL_CHECK_EVENT, SELECT_DEVICE, UPDATE_DEVICE, SOFTWARE_STATUS } from "./actionsTypes";
import { AvosAction } from "../types";

const endpoint = "device/";

export function listDevices(params) {
    return {
        types: [LIST_DEVICE.LOADING, LIST_DEVICE.SUCCESS, LIST_DEVICE.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint}management/`,
                params
            },
        },
    };
}

export function refreshDevices(params) {
    return {
        types: [`${LIST_DEVICE.LOADING}-no-loading-state`, LIST_DEVICE.SUCCESS, LIST_DEVICE.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint}management/`,
                params
            },
        },
    };
}


export function getDevice(device_uid) {
    return {
        types: [GET_DEVICE.LOADING, GET_DEVICE.SUCCESS, GET_DEVICE.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${`${endpoint}management/${device_uid}`}/`,
            },
        },
    };
}

// export function createDevice(data) {
//     return {
//         types: [CREATE_DEVICE.LOADING, CREATE_DEVICE.SUCCESS, CREATE_DEVICE.ERROR],
//         payload: {
//             request: {
//                 method: "POST",
//                 url: `${endpoint}management/`,
//                 data,
//             },
//         },
//     };
// }

export function updateDevice(device) {
    // This is for updating the location object
    return {
        types: [UPDATE_DEVICE.LOADING, UPDATE_DEVICE.SUCCESS, UPDATE_DEVICE.ERROR],
        payload: {
            request: {
                method: "PUT",
                url: `${`${endpoint}management/${device.UID}`}/`,
                data: device,
            },
        },
    };
}

export function selectDevice(data) {
    // This function is for deselecting users and update the location
    return {
        types: [SELECT_DEVICE.LOADING, SELECT_DEVICE.SUCCESS, SELECT_DEVICE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${`${endpoint}management/${data.UID}`}/select/`,
                data,
            },
        },
    };
}

export function assignDeviceToTenant(device) {
    return {
        types: [UPDATE_DEVICE.LOADING, UPDATE_DEVICE.SUCCESS, UPDATE_DEVICE.ERROR],
        payload: {
            request: {
                method: "PUT",
                url: `${`${endpoint}management/${device.UID}`}/change_tenant_id`,
                data: device,
            },
        },
    };
}

export function deleteDevice(device_uid) {
    return {
        types: [DELETE_DEVICE.LOADING, DELETE_DEVICE.SUCCESS, DELETE_DEVICE.ERROR],
        payload: {
            request: {
                method: "DELETE",
                device_uid,
                url: `${`${endpoint}management/${device_uid}`}/`,
            },
        },
    };
}

// export function changePowerOption(data) {
//     return {
//         types: [POWER_OPTION.LOADING, POWER_OPTION.SUCCESS, POWER_OPTION.ERROR],
//         payload: {
//             request: {
//                 method: "POST",
//                 url: `${EFQ_MANAGMENT_ROOT_URL}api/admin/devices/power`,
//                 data,
//             },
//         },
//     };
// }

export function createDeviceEvent(data) {
    return {
        types: [CREATE_EVENT.LOADING, CREATE_EVENT.SUCCESS, CREATE_EVENT.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}events/user/`,
                data
            },
        },
    } as AvosAction;
}

// export function getDeviceEvent(event_id) {
//     return {
//         types: [GET_EVENT.LOADING, GET_EVENT.SUCCESS, GET_EVENT.ERROR],
//         payload: {
//             request: {
//                 method: "GET",
//                 url: `${endpoint}events/user/${event_id}`,
//                 data
//             },
//         },
//     };
// }

// export function adminPollDevice(device_uid) {
//     return {
//         types: [POLL_CHECK_EVENT.LOADING, POLL_CHECK_EVENT.SUCCESS, POLL_CHECK_EVENT.ERROR],
//         payload: {
//             request: {
//                 method: "GET",
//                 url: `${endpoint}events/admin/poll/${device_uid}/`,
//             },
//         },
//     };
// }


export function adminGetDeviceEvents(device_uid, params = {}) {
    return {
        types: [LIST_EVENT.LOADING, LIST_EVENT.SUCCESS, LIST_EVENT.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint}events/admin/${device_uid}`,
                params
            },
        },
    };
}

export function pollCheckDevice(device_uid, test_id) {
    return {
        types: [POLL_CHECK_EVENT.LOADING, POLL_CHECK_EVENT.SUCCESS, POLL_CHECK_EVENT.ERROR],
        payload: {
            request: {
                no_toast: true,
                method: "GET",
                url: `${endpoint}events/user/poll/${device_uid}/${test_id}/new-fruit`,
            },
        },
    } as unknown as AvosAction;
}

export function resetDeviceStatusList() {
    return {
        type: LIST_STATUS.RESET,
        payload: { },
    };
}

export function getDeviceStatusList(filter) {
    return {
        types: [LIST_STATUS.LOADING, LIST_STATUS.SUCCESS, LIST_STATUS.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint}status/`,
                params: filter
            },
        },
    };
}

export function getSoftwareStatus(filter) {
    return {
        types: [SOFTWARE_STATUS.LOADING, SOFTWARE_STATUS.SUCCESS, SOFTWARE_STATUS.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint}software_status/`,
                params: filter
            },
        },
    };
}


// GET Device Event
// Get Device Queue
// POLl for new event
