
/** @jsxImportSource @emotion/react */
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Breadcrumb, BreadcrumbItem
} from "reactstrap";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export type BreadcrumbItemProps = {
    href?: string;
    label?: string;
    icon?: IconProp;
    isHome?: boolean;
}

export interface BreadcrumbsProps {
    items: BreadcrumbItemProps[];
}

export const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
    return (
        <Breadcrumb className="my-3">
            {items?.map((item, idx) => (
                <BreadcrumbItem active={items.length - 1 === idx} href={item.href} tag={item.href ? "a" : "span"} key={item.href ?? `breadcrumb-item-${idx}`}>
                    {(!!item.icon || item.isHome) && <FontAwesomeIcon icon={item.icon ?? faHome} />}

                    {item.label}
                </BreadcrumbItem>
            ))}
        </Breadcrumb>
    );
};
