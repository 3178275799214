/** @jsxImportSource @emotion/react */
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";

import { patchLocation } from "../../../../actions/FacilityLocations/actions";
import { applyDisplayFilterFields } from "../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig from "../../../../actions/Tenants/config/configHook";
import MetaForm, { metaFormIsValid } from "../../../Forms/MetaForm";
import useRipeningRoom from "./hooks/useRipeningRoom";


export default function RipeningRoomMetaForm() {
    const config = useConfig();
    const { fruit_type } = useRipeningRoom();
    const dispatch = useDispatch();
    const currentLocation = useSelector((state:any) => state.facilityLocations.current);
    const [modalOpen, setModal] = useState(false);
    const meta_form = applyDisplayFilterFields(config.root_config?.ripening_room_meta_form, { fruit_type });
    const setValue = (field, value) => dispatch(patchLocation(currentLocation.location_id, field, value, false) as any);

    const onDebounce = (field, value) => {
        const field_config = meta_form.find((i) => i.name === field);
        if (metaFormIsValid([field_config], { [field]: value })) {
            return dispatch(patchLocation(currentLocation.location_id, field, value, true, true) as any);
        }
        return Promise.resolve();
    };

    const onSubmit = () => {
        if (meta_form && !metaFormIsValid(meta_form, currentLocation)) return;
        // dispatch(triggerLayerBusinessRules(layer.id) as any);
        setModal(false);
    };

    return <>
        <Modal isOpen={modalOpen} size="md">
            <ModalHeader >Edit Ripening Room</ModalHeader>
            <ModalBody>
                <MetaForm
                    meta={meta_form}
                    object={currentLocation}
                    config={config}
                    setValue={setValue}
                    onDebounce={onDebounce}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => setModal(false)}>Cancel</Button>
                <Button color="primary" onClick={() => onSubmit() }>Save</Button>
            </ModalFooter>
        </Modal>
        <div className="pb-2 pb-md-0"><Button className="text-nowrap" color="light" onClick={() => setModal(true)}><FontAwesomeIcon icon={faCog} ></FontAwesomeIcon> Settings</Button></div>
    </>;

}
