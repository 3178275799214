import { getStore } from "../../store";
import { AvosAction } from "../types";
import {
    AUTH, GET, LOGOUT, POWERBI_GET, POWERBI_LOGOUT, UPDATE
} from "./actionsTypes";

export function logoutUser() {
    return {
        type: LOGOUT.SUCCESS,
    } as AvosAction;
}

export function authIsLoading(isLoading) {
    return {
        type: AUTH.LOADING,
        data: isLoading,
    };
}
export function askReauthentication() {
    getStore().dispatch(logoutUser());
    window.location.replace(`/login?redirect_to=${window.location.pathname}${window.location.search}`);
}

export function getUser(data) {
    return {
        types: [GET.LOADING, GET.SUCCESS, GET.ERROR],
        payload: {
            request: {
                no_toast: true,
                method: "GET",
                url: "users/me/",
                data,
            },
        },
    };
}

export function getSupplierPortalUser(data) {
    return {
        types: [GET.LOADING, GET.SUCCESS, GET.ERROR],
        payload: {
            request: {
                no_toast: true,
                method: "GET",
                url: "supplier-portal/me/",
                data,
            },
        },
    };
}

export function switchTenant(data) {
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                method: "PUT",
                url: "users/switch_tenant/",
                data,
            },
        },
    };
}


export function getPowerBIEmbedInfo() {
    return {
        types: [POWERBI_GET.LOADING, POWERBI_GET.SUCCESS, POWERBI_GET.ERROR],
        payload: {
            request: {
                method: "GET",
                url: "powerbi/getembedinfo",
            },
        },
    };
}

export function logoutPowerBIEmbedInfo() {
    return {
        type: POWERBI_LOGOUT.SUCCESS,
    };
}
