/** @jsxImportSource @emotion/react */
import dayjs from "dayjs";
import Field, { DataSource } from "./Field";

class LayerFruitAgeField extends Field {
    field_type = "layer_fruit_age_field";

    backend_table = "layer_meta";

    fruit_age: any | null = null;

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        this.value = null;
        if (datasource.layer[this.fieldname_getter]) {
            this.value = datasource.layer[this.fieldname_getter];
        } else if (datasource.layer.parent_meta && datasource.layer.parent_meta[this.fieldname_getter]) {
            this.value = datasource.layer.parent_meta[this.fieldname_getter];
        }

        // TODO: first look at harvest_date, then at packing list
        this.value = this.value_filter(this.value, datasource);
        this.fruit_age = dayjs(this.value);

        if (this.value && this.fruit_age.isValid()) {
            // TODO: If last date in facility is known, use that day instead of today
            this.value = dayjs().diff(this.fruit_age, "day");
        } else {
            this.value = "-";
        }

        return this;
    }

    display_component(): JSX.Element {
        if (this.value === "-") {
            return <span>-</span>;
        }
        // TODO: set opacity if last_date_in_facility is known
        if (this.value > 36) {
            return <span className="text-danger">{this.value} days</span>;
        } if (this.value >= 21) {
            return <span className="text-warning">{this.value} days</span>;
        }
        return <span className="text-success">{this.value} days</span>;
    }

}

export default LayerFruitAgeField;
