/**
 * Checks if a value is an array and returns it. Otherwise, returns a fallback value.
 * @param value - The value to check.
 * @param fallback - The fallback value to return if the value is not an array or is an empty array. Default is an empty array.
 * @returns The value if it is an array and not empty, otherwise the fallback value.
 */
export function getFilledArrayOrDefault(value: any, fallback: any[] = []): any[] {
    if (Array.isArray(value) && value.length > 0) {
        return value;
    }

    return fallback;
}

export const isFilledArray = <T>(value: T): boolean => !!getFilledArrayOrDefault(value).length;
