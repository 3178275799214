/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";


export function TableHeaderCell({ children, className = "text-muted align-middle text-start text-nowrap fw-bold py-1 px-2 ", styles = null }: { children?: React.ReactNode, className?: string, styles?: any }) {
    return <td className={className} css={[css`color: black !important;background: #e5e8e5!important; border-right: 1px solid white;`, styles]}>
        {children}
    </td>;
}


export function TableCell({ children, className = "text-start align-middle text-nowrap px-2", styles = null }: { children?: React.ReactNode, className?: string, styles?: any }) {
    return <td className={className} css={styles}>{children}</td>;
}

export function TableWrapper({ children }) {
    return <div css={css`border-radius: 8px; overflow: hidden;`} className="bg-body border">{children}</div>;
}
