/* eslint-disable no-restricted-syntax */
/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Col, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { optionsFlagged } from "../../actions/Layers/constants";
import { BarcodeFilter, IFormField } from "../../actions/Tenants/config/constantsTyped";
import { createBarcodeFilter } from "../../actions/Tenants/config/utils";
import { getFilledArrayOrDefault } from "../../utils";
import { toast } from "../../utils/toast";
import DatePicker from "../Forms/DatePicker";
import FruitImages from "../scenes/AddItem/components/Summary/FruitImages";
import { CheckboxButtons, CheckboxButtonsManaged } from "./CheckboxButtons";
import DateRadioButtons from "./DateRadioButtons";
import { FileSelect } from "./FileSelect";
import { FtaDeviceField } from "./FtaDeviceField";
import { ImageRadioButtons } from "./ImageRadiButtons";
import { ImageViewerModal } from "./ImageViewerModal";
import LayerAutoComplete from "./LayerAutoComplete";
import LayerChildrenAutoComplete from "./LayerChildrenAutoComplete";
import MetaSelectCreatable from "./MetaSelectCreatable";
import NumericInputField from "./NumericInputField";
import { ObjectList } from "./ObjectList";
import { TextQRcodeScanner } from "./QRCodeScan";
import { RadioButtons } from "./RadioButton";
import { RadioButtonManaged, SelectManaged } from "./SelectManaged";
import SingleSelect from "./SingleSelect";
import TextList from "./TextList";
import { useDebounceForObject } from "./utils";

const GridWrapper = ({ children }) => <Row className="justify-content-start">{children}</Row>;
GridWrapper.propTypes = {
    children: PropTypes.any,
};
const Wrapper = ({ children }) => <div>{children}</div>;
Wrapper.propTypes = {
    children: PropTypes.any,
};

const GridItemWrapper = ({ children, lg }) => <Col lg={lg}>{children}</Col>;
GridItemWrapper.propTypes = {
    children: PropTypes.any,
    lg: PropTypes.any
};
const ItemWrapper = ({ children }) => <div className="pb-2">{children}</div>;
ItemWrapper.propTypes = {
    children: PropTypes.any,
};

export const selectAllOnFocus = (event) => event.target.select();

export interface MetaFormProps {
    setValue: (field: string, value: unknown) => void,
    onDebounce?: (field: string, value: unknown) => void,
    object: object,
    meta: Array<any>,
    grid?: boolean,
    config: { barcode_filter: BarcodeFilter, onchange_callbacks: object },
    extra_context?: { // * pass extra context properties without setting them on the main object (e.g. to only show avocado options on check field, while fruit type is not stored on check but on layer)
        fruit_type?: string,
        fruit_variety?: string,
    }
    disabled?: boolean
    debounce_time?: number
}

interface UpdateFieldProps {
    field: string,
    value: any,
    object?: object
}

function validatePattern(pattern: string, value: string) {
    if (pattern) {
        return (new RegExp(pattern)).test(value);
    }
    return true;
}

export function handlePatternValidation(item, object) {
    if (item?.pattern && object[item.name]) {
        return validatePattern(item.pattern, object[item.name]);
    }
    return true;
}

export function metaFormIsValid(meta_form: IFormField[] = [], object: object, show_toast = true) {
    const missing_field = meta_form.find((item) => {
        if (item?.mandatory) {
            if (Array.isArray(object[item.name])) {
                if (object[item.name].length === 0) return true;
            } else if (!object[item.name] && object[item.name] !== false) return true;
        }
        return false;
    });

    const invalid_value = meta_form
        .map((item) => ({ ...item, matchesPattern: handlePatternValidation(item, object) }))
        .find((item) => item.invalid_hint && !item.matchesPattern);

    if (invalid_value && invalid_value.invalid_hint) {
        if (show_toast) {
            toast.error(`${invalid_value.invalid_hint}`);
        }
        return false;
    }
    if (missing_field) {
        if (show_toast) {
            toast.error(`please fill in the ${missing_field.label}`);
        }
        return false;
    }
    return true;

}

export default function MetaForm({
    meta, setValue, onDebounce = () => null, object, grid = false, config, extra_context = {}, disabled = false, debounce_time = 1000
}: MetaFormProps) {
    const TheWrapper = grid ? GridWrapper : Wrapper;
    const TheItemWrapper = grid ? GridItemWrapper : ItemWrapper;
    const min = false;
    const max = false;

    const debouncedSetValue = useDebounceForObject(setValue, onDebounce, debounce_time);

    const fields = meta
        .filter((i) => i)
        .map((i) => {
            // * By default just update field
            let update = ({ field, value }: UpdateFieldProps) => debouncedSetValue(field, value);
            if (typeof i.onchange_callback === "function") {
                update = ({ field, value, object }: UpdateFieldProps) => i.onchange_callback({ setValue: debouncedSetValue, field, value, object });

                // * Configured, but predefined, onchange_callback
            } else if (typeof i.onchange_callback === "string") {
                const callback = config.onchange_callbacks[i.onchange_callback];
                if (typeof callback === "function") {
                    update = ({ field, value, object }: UpdateFieldProps) => callback({ setValue: debouncedSetValue, field, value, object });
                } else {
                    console.error(`callback ${i.onchange_callback} not found`); // eslint-disable-line no-console
                }

            }
            return {
                ...i,
                update

            };
        }).map((i) => ({
            ...i,
            setValue: (field, value) => i.update({ field, value, object })

        }));

    const fieldsHaveDefaultValue = fields.filter((x) => x.default_value !== undefined && x.default_value !== "");

    useEffect(() => {
        // * Make a complete object
        const objectWithAllFieldsUpdated = fieldsHaveDefaultValue.reduce((acc, field) => {
            if (acc[field.name] === undefined || acc[field.name] === "") {
                return { ...acc, [field.name]: field.default_value };
            }
            return acc;
        }, object);

        // * Now call field.update with the complete object so that onchange callbacks properly work
        fieldsHaveDefaultValue.map((field) => {
            if ((object[field.name] === undefined || object[field.name] === "")) {
                field.update({ field: field.name, value: field.default_value, object: objectWithAllFieldsUpdated });
            }
            return null;
        });

    }, [fieldsHaveDefaultValue.map((i) => `${i.name}-${i.default_value}`).join(",")]);

    const getBarcodeFilter = (i): BarcodeFilter => {
        if (i.overwrite_barcode_template) {
            const filter = createBarcodeFilter(i.barcode_template ? [i.barcode_template] : []);
            const formatsToSupport = i.barcode_formats;
            return { filter, formatsToSupport };
        }
        return config?.barcode_filter;
    };

    return (
        <TheWrapper>
            {fields.map((i, index) => {
                // * Extra context used to pass data to the managed options hook for filtering options, but not data we want to store in the form object
                const item_extra_context = { ...extra_context, defect_position: i.defect_position, defect_severity: i.defect_severity };
                return (<TheItemWrapper key={index} lg={i.lg} >
                    {i.type === "element" && <div>{i.el}</div>}
                    {i.type === "header" && <FormGroup className="d-flex border-bottom align-items-center justify-content-between">
                        <h6 className="opacity-80 fw-bold">{i.label}</h6>
                        <ImageViewerModal label={i?.label} options={i?.options} />
                    </FormGroup>}
                    {i.type === "layer-select"
                        && <FormGroup>
                            <Label for={i.name} >{i.label}</Label>
                            <LayerAutoComplete
                                type={i.layer_type ? i.layer_type : null}
                                setLayer={(value) => i.setValue(i.name, value)}
                                layer={object?.[i.name]}
                                multi={i.multi}
                                creatable={i.creatable}
                            />
                            {i.description && <FormText className="d-block">{i.description}</FormText>}
                        </FormGroup>
                    }
                    {i.type === "layer-select-children"
                        && <FormGroup>
                            <Label>{i.label}</Label>
                            <LayerChildrenAutoComplete
                                type={i.layer_type ? i.layer_type : null}
                                post_value={object?.[i.name]}
                                setValue={i.setValue}
                                field={i.name}
                                meta_options={i.meta_options}
                            />
                            {i.description && <FormText className="d-block">{i.description}</FormText>}
                        </FormGroup>
                    }
                    {i.type === "select-creatable"
                        && <FormGroup>
                            <Label for={i.name} >{i.label}</Label>
                            <MetaSelectCreatable
                                type={i.layer_type ? i.layer_type : null}
                                post_value={object?.[i.name]}
                                setValue={i.setValue}
                                field={i.name}
                                creatable={i.creatable}
                            />
                            {i.description && <FormText className="d-block">{i.description}</FormText>}
                        </FormGroup>
                    }
                    {i.type === "single-select"
                        && <SingleSelect
                            setValue={i.setValue}
                            options={getFilledArrayOrDefault(i?.options, [])}
                            post_value={object?.[i.name]}
                            field={i.name}
                            label={i.label}
                            description={i.description}
                            multi={i.multi}
                            formatOptionLabel={i.formatOptionLabel}
                            return_objects={i.return_objects}
                            return_array={i.return_array}
                            disabled={i.disabled}
                        />
                    }
                    {
                        i.type === "select-managed"
                        && <SelectManaged
                            setValue={i.setValue}
                            object={object}
                            post_value={object?.[i.name] === undefined ? i.default_value : object?.[i.name]}
                            name={i.name}
                            options_name={i.options_name}
                            label={i.label}
                            description={i.description}
                            multi={i.multi}
                            hide_when_no_options={i.hide_when_no_options}
                            children_meta={i.children_meta}
                            extra_context={item_extra_context}
                            return_objects={i.return_objects}
                            return_array={i.return_array}
                            disabled={i.disabled}
                        />
                    }
                    {
                        i.type === "date-radiobutton"
                        && <DateRadioButtons
                            setValue={(value) => i.setValue(i.name, value)}
                            value={object?.[i.name]}
                            label={i.label}
                            description={i.description}
                            forward={typeof i.forward === "string" ? parseInt(i.forward, 10) : i.forward}
                            backward={typeof i.backward === "string" ? parseInt(i.backward, 10) : i.backward}
                        />
                    }
                    {
                        i.type === "image-radiobutton"
                        && <FormGroup>
                            <Label for={i.name} >{i.label}</Label>
                            <ImageRadioButtons
                                setValue={(value) => i.setValue(i.name, value)}
                                value={object?.[i.name]}
                                options={i.options}
                            />
                            {i.description && <FormText className="d-block">{i.description}</FormText>}
                        </FormGroup>
                    }
                    {(i.type === "number")
                        && <FormGroup >
                            <Label for={i.name} >{i.label}</Label>
                            <NumericInputField
                                field={i.name}
                                appendendStyle={i.appendend_style}
                                db_value={object?.[i.name]}
                                label={i.label}
                                disabled={i.disabled || disabled}
                                min={({ min, ...i }).min}
                                max={({ max, ...i }).max}
                                setValue={(_, value) => i.setValue(i.name, value)}
                                unity={i.unity}
                                invalid={typeof i.validate === "function" && object?.[i.name] && !i.validate(object?.[i.name])} />
                            {i.description && <FormText className="d-block">{i.description}</FormText>}
                        </FormGroup>
                    }
                    {(i.type === "range")
                        && <FormGroup >
                            {i.label && <Label for={i.name} >{i.label}</Label>}
                            <Input
                                type="range"
                                name={i.name}
                                id={i.name}
                                disabled={i.disabled}
                                value={object?.[i.name] || i.min || 0}
                                min={i.min || 0}
                                max={i.max || 100}
                                step={i.step || 0.001}
                                onChange={(e) => i.setValue(i.name, e.target.value)}
                                invalid={typeof i.validate === "function" && !!object?.[i.name] && !i.validate(object?.[i.name])}></Input>
                            {i.description && <FormText className="d-block">{i.description}</FormText>}
                        </FormGroup>
                    }
                    {(i.type === "text" || i.type === "textarea" || i.type === "email")
                        && <FormGroup >
                            {i.label && <Label for={i.name} >{i.label}</Label>}
                            <Input
                                type={i.type || "text"}
                                name={i.name}
                                id={i.name}
                                disabled={i.disabled || disabled}
                                value={object?.[i.name] || ""}
                                maxLength={i.validDigitsNumber || ""}
                                onChange={(e) => i.setValue(i.name, e.target.value)}
                                invalid={typeof i.validate === "function" && !!object?.[i.name] && !i.validate(object?.[i.name])}
                                onFocus={selectAllOnFocus} ></Input>
                            {typeof i.validate === "function" && object?.[i.name] && !i.validate(object?.[i.name]) && i.validDigitsNumber && <FormText>
                                Please make sure that the number consists of {i.validDigitsNumber} digits
                            </FormText>}
                            {i.description && <FormText className="d-block">{i.description}</FormText>}
                        </FormGroup>
                    }
                    {(i.type === "text-qrcode-scanner")
                        && <TextQRcodeScanner
                            barcode_filter={getBarcodeFilter(i) as any}
                            item={i}
                            object={object}
                            setValue={i.setValue}
                            onFocus={selectAllOnFocus}
                        />
                    }
                    {
                        i.type === "fta-device-field"
                        && <FtaDeviceField
                            item={i}
                            object={object}
                            setValue={i.setValue}
                            disabled={disabled}
                        />
                    }
                    {(i.type === "radio" || i.type === "radio-flag")
                        && <FormGroup >
                            <Label for={i.name} >{i.label}</Label>
                            <div>
                                <RadioButtons
                                    color="secondary"
                                    options={i.type === "radio-flag" ? optionsFlagged : getFilledArrayOrDefault(i?.options)}
                                    name={i.name}
                                    current={object?.[i.name] === undefined ? i.default_value : object?.[i.name]}
                                    onChange={(_, value) => i.setValue(i.name, value === undefined ? i.default_value : value)}
                                    disabled={disabled}
                                    default_value={i.default_value}
                                />
                            </div>
                            {i.description && <FormText className="d-block">{i.description}</FormText>}
                        </FormGroup>
                    }
                    {
                        i.type === "radio-managed"
                        && <RadioButtonManaged
                            setValue={i.setValue}
                            object={object}
                            post_value={object?.[i.name]}
                            name={i.name}
                            label={i.label}
                            description={i.description}
                            options_name={i.options_name}
                            default_value={i.default_value}
                            extra_context={item_extra_context}
                        />
                    }
                    {
                        i.type === "checkbox-managed"
                        && <CheckboxButtonsManaged
                            onChange={i.setValue}
                            object={object}
                            selectedOptions={object?.[i.name] || []}
                            name={i.name}
                            label={i.label}
                            description={i.description}
                            options_name={i.options_name}
                            extra_context={item_extra_context}
                            return_objects={i.return_objects}
                            return_array={i.return_array}
                        />
                    }
                    {
                        i.type === "checkbox"
                        && <FormGroup >
                            <div className="d-flex align-items-center mb-1">
                                <Label for={i.name} >{i.label}</Label>
                                <ImageViewerModal label={i.label} options={i.options} />
                            </div>
                            <div>
                                <CheckboxButtons
                                    selectedOptions={object?.[i.name] || []}
                                    onChange={(value) => i.setValue(i.name, value)}
                                    options={i.options}
                                    color={i.color}
                                    className="me-1 mb-1"
                                    return_objects={i.return_objects}
                                    return_array={i.return_array}
                                />
                            </div>
                            {i.description && <FormText className="d-block">{i.description}</FormText>}
                        </FormGroup>
                    }
                    {
                        i.type === "fruit-images" && <FruitImages avocado_id={(object as any).avocado_id} />
                    }
                    {
                        i.type === "inline-form"
                        && <FormGroup>
                            <Label for={i.name} >{i.label}</Label>
                            <MetaForm setValue={(field: string, value: unknown) => {
                                const formValue = object?.[i.name] || {};
                                formValue[field] = value;
                                setValue(i.name, formValue);
                            }} grid={grid} config={config} meta={i.fields} object={object?.[i.name]} />
                            {i.description && <FormText className="d-block">{i.description}</FormText>}
                        </FormGroup>
                    }
                    {
                        i.type === "object-list"
                        && <ObjectList
                            title={i.label}
                            description={i.description}
                            name={i.name}
                            items={object?.[i.name] || []}
                            form={i.fields}
                            onChange={(arr) => setValue(i.name, arr)}
                            getItemTitle={i.getItemTitle}
                            validate={i.validate}
                            disabled={i.disabled}
                        />
                    }
                    {
                        i.type === "date-picker"
                        && <FormGroup>
                            <Label for={i.name} >{i.label}</Label>
                            <DatePicker
                                value={object?.[i.name]}
                                onChange={(value) => setValue(i.name, value)} />
                        </FormGroup>
                    }
                    {
                        i.type === "file-select"
                        && <FormGroup>
                            <Label>{i.label}</Label>
                            <FileSelect
                                accept={i.accept}
                                onSelect={(name, content) => i.setValue(i.name, { name, content })}
                                onSelectImage={(path) => i.setValue(i.name, path)}
                                upload_config_image={i.upload_config_image}
                                readFormat={i.readFormat}
                                fileObject={(object as any)} />
                            {i.description && <FormText className="d-block">{i.description}</FormText>}
                        </FormGroup>
                    }
                    {
                        i.type === "text-list"
                        && <FormGroup>
                            <Label for={i.name} >{i.label}</Label>
                            <TextList
                                value={object?.[i.name] || []}
                                onChange={(value) => setValue(i.name, value)}
                                field={i.field}
                                disabled={i.disabled} />
                        </FormGroup>
                    }
                </TheItemWrapper>);
            })}
        </TheWrapper>

    );
}

