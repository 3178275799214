/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { useDispatch, useSelector } from "react-redux";
import { deleteGrower, getGrowers, saveGrower, updateGrowerFilter } from "../../../actions/Admin/grower/actions";
import { Grower, GrowerState } from "../../../actions/Admin/grower/reducer";
import { Filter } from "../../../actions/Admin/reducer";
import { getAllTenantFieldOptions } from "../../../actions/Tenants/actions";
import useConfig, { getFormOptionsLabel } from "../../../actions/Tenants/config/configHook";
import { FormField } from "../../../actions/Tenants/config/constantsTyped";
import { RootState } from "../../../reducers";
import { Form, PageBase, PageBaseTableRowField } from "./PageBase";

interface GrowerFormObject extends Omit<Grower, "name" | "fruit_types"> {
    fruit_type: string[];
}

const generateName = (label: string) => label.toLowerCase().replace(/ /g, "-");

const definedGrowerFormCreate = (onSaveGrower: (grower:Grower) => void, onDeleteGrower: (id:string)=>void) => () => {
    const growerForm: FormField[] = [
        {
            label: "Grower Name",
            name: "label",
            type: "text",
            mandatory: true,
            pattern: "^(?!\\s*$).+$",
            invalid_hint: "String consists of spaces is not allowed",
        },
        {
            label: "Country",
            name: "country",
            type: "select-managed",
            mandatory: true,
        },
        {
            label: "Fruit(s)",
            name: "fruit_type",
            type: "select-managed",
            mandatory: true,
            multi: true,
            return_array: true,
        },
        {
            label: "GGN",
            name: "ggn",
            type: "text",
            mandatory: true,
        }
    ];
    const onSave = (formObject: GrowerFormObject, entity: Partial<Grower>) => {
        const grower: any = {
            ...entity,
            ...formObject,
            fruit_types: formObject.fruit_type,
            fruit_type: undefined,
            name: entity.name || generateName(formObject.label),
        };
        onSaveGrower(grower as Grower);
    };
    const onDelete = (_: GrowerFormObject, entity: Partial<Grower>) => {
        onDeleteGrower(entity.id!);
    };
    const getFormObjectFromEntity = (entity: Partial<Grower>): Partial<GrowerFormObject> => {
        return {
            ...entity,
            fruit_type: entity.fruit_types || [],
        };
    };
    const growerModal: Form<GrowerFormObject, Grower> = {
        title: "Grower",
        fields: growerForm,
        modalButtonLabel: "Edit",
        onSave,
        onDelete,
        getFormObjectFromEntity
    };
    return growerModal;
};


const GrowerPage = () => {
    const dispatch = useDispatch();
    const state = useSelector<RootState, GrowerState>((state) => state.admin.grower);
    const config = useConfig();

    const onSaveGrower = async (entity: Grower) => {
        await dispatch(saveGrower(entity));
        dispatch(getAllTenantFieldOptions(config.tenant_id) as any);
    };
    const onDeleteGrower = async (id: string) => {
        await dispatch(deleteGrower(id));
        dispatch(getAllTenantFieldOptions(config.tenant_id) as any);
    };
    const onUpdateFilter = (filter: Filter) => {
        dispatch(updateGrowerFilter(filter));
    };
    const onListEntities = (filter: Filter) => {
        dispatch(getGrowers(filter));
    };
    const tableRow: PageBaseTableRowField<Grower>[] = [
        {
            label: "Grower Name",
            name: "label",
            lg: 3,
        },
        {
            label: "Country",
            name: "country",
            display_component(entity:Grower) {
                return getFormOptionsLabel("country", entity.country);
            },
            lg: 2,
        },
        {
            label: "Fruit(s)",
            name: "fruit_types",
            display_component(entity:Grower) {
                return entity.fruit_types.map((fruit_type) => (getFormOptionsLabel("fruit_type", fruit_type)) || []).join(", ");
            },
            lg: 2,
        },
        {
            label: "GGN",
            name: "ggn",
            lg: 2,
        }
    ];
    const createGrowerForm = definedGrowerFormCreate(onSaveGrower, onDeleteGrower);
    const createForms = [createGrowerForm];
    return <PageBase<Grower>
        title="Grower"
        tableRow={tableRow}
        actionsColumnWidth={3}
        createForms = {createForms as any}
        state={state}
        events={{ onUpdateFilter, onListEntities }}
    />;
};


export default GrowerPage;
