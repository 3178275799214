
/** @jsxImportSource @emotion/react */
import { Fragment, useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LAYER_TAB_CATEGORIES } from "../../../../actions/Layers/constants";
import { createTenantConfig, patchTenantConfig, patchTenantConfigPosition } from "../../../../actions/Tenants/actions";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { CONFIG_TYPES } from "../../../../actions/Tenants/config/constants";
import { callAvosApi } from "../../../../utils/useAvosApiHook";
import MetaForm from "../../../Forms/MetaForm";
import { ConfirmationModal } from "../../Helper/ConfirmationModal";
import { ModalResult } from "../../Helper/Modal";
import { DraggableField } from "./DraggableField";
import { ItemContent } from "./ItemContent";

interface ListTenantConfigProps {
    type: string;
    title: string;
    defaultConfig: object; // Specify a more detailed type if possible
}

const groupConfigsByTabCategory = (configs): any => {
    const grouped = configs.reduce((acc, item) => {
        if (item.type === CONFIG_TYPES.STAGE) {
            const tabCategories = item.config?.tab_categories || [LAYER_TAB_CATEGORIES.OPERATION];

            tabCategories.forEach((category) => {
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push(item);
            });
        }

        return acc;
    }, {});

    // Sort object keys alphabetically
    return Object.fromEntries(Object.entries(grouped).sort(([a], [b]) => a.localeCompare(b)));
};

function ListTenantConfig({ type, title, defaultConfig }: ListTenantConfigProps) {
    const params = useParams();
    const [createModal, setCreateModal] = useState(false);
    const [query, setQuery] = useState({ value: "", note: "", config: { value: "" } });
    const navigate = useNavigate();
    const { tenant_id } = params;
    const dispatch = useDispatch();
    const config = useConfig();
    const [masterTenantConfigs, setMasterTenantConfigs] = useState([]);
    const configs = config.configs.filter((i) => i.type === type).sort((a, b) => a.position - b.position);
    const [selectedItem, selectItem] = useState(false);

    const deactivate = (config_item) => dispatch(patchTenantConfig(tenant_id, config_item.id, "active", false) as any);

    const closeDeactivateModal = useCallback(
        (result: ModalResult) => {
            if (result === ModalResult.Save && selectedItem) {
                deactivate(selectedItem);
            }
            selectItem(false);
        },
        [deactivate, selectedItem]
    );

    const createConfig = () => {
        dispatch(createTenantConfig(tenant_id, {
            type,
            value: query.value,
            note: query.note,
            position: configs.length ? configs[configs.length - 1].position + 1 : 1,
            config: { ...defaultConfig, ...query.config, value: query.value }
        }) as any).then((response) => {
            if (!response.error) {
                navigate(`/tenants/${tenant_id}/config/${response.payload.data.id}`);
            }
        });

    };

    const form = [
        {
            label: "Value",
            description: "Identifier for this config. For Intake check location use `intake`, for Pallet layer type use `pallet` etc etc",
            type: "text",
            name: "value",
            onchange_callback: ({ field, value, setValue }) => setValue(field, value.toLowerCase().replace(/[^a-z0-9]/g, "_"))
        },
        {
            label: "Master tenant template",
            description: "Copy config from master tenant. Leave blank to start from scratch or to import a json export later.",
            type: "single-select",
            name: "template",
            options: masterTenantConfigs,
            onchange_callback: ({ field, value, setValue }) => {
                setValue(field, value);
                const selected = masterTenantConfigs.find((i: any) => i.value === value) as any;
                if (selected) {
                    setQuery((query) => ({
                        ...query,
                        [field]: value,
                        note: `Copy from Master tenant. (${selected.note})`,
                        config: { ...defaultConfig, value: query.value, ...selected.config }
                    }));
                } else {
                    // cleared
                    setQuery((query) => ({
                        ...query,
                        [field]: value,
                        note: "",
                        config: { ...defaultConfig, value: query.value }
                    }));
                }
            },
        },
        {
            label: "Note",
            description: "Optional note",
            type: "textarea",
            name: "note",
        }
    ];

    const isCreateDisabled = () => {
        if (!query.value) {
            return true;
        }
        const duplicate = config.configs.find((i) => i.value === query.value && i.type === type);
        return !!duplicate;
    };

    useEffect(() => {
        if (createModal && type) {
            callAvosApi("/tenants/master_tenant/configs/active").then((res) => {
                setMasterTenantConfigs(res.data.filter((i: any) => i.type === type).map((i: any) => ({ label: i.value, value: i.id, note: i.note, config: i.config })));
            });
        }
    }, [createModal, type]);

    // TODO: Implement checkForConfigErrors
    // configs.map((i) => ({ ...i, hasError: checkForConfigErrors(i), hasWarning: false }));

    const groupedConfigs = groupConfigsByTabCategory(configs);
    const nonStageConfigs = configs.filter((item: any) => item.type !== CONFIG_TYPES.STAGE);

    const onUpdatePosition = (fromIndex: number, toIndex: number, configItems: any) => {
        if (fromIndex === toIndex) return;

        const updatedConfigs = [...configItems];
        const [item] = updatedConfigs.splice(fromIndex, 1);
        updatedConfigs.splice(toIndex, 0, item);
        const items = updatedConfigs.map((item, index) => ({ id: item.id, position: index + 1 }));

        // Update the whole list BAZOOKAAA
        if (updatedConfigs.length > 0) {
            dispatch(patchTenantConfigPosition(tenant_id, items) as any);
        }
    };

    return (
        <div>
            <div className="pt-1">
                <div className="d-flex align-items-center mb-3">
                    <Button color="light" className="ms-auto" size="sm" onClick={() => setCreateModal(true)}>
                        Add {title.toLowerCase()} config
                    </Button>
                </div>

                <DndProvider backend={HTML5Backend}>
                    {/* Render STAGE configs grouped by tab_category */}
                    {Object.keys(groupedConfigs).map((category) => (
                        <Fragment key={category}>
                            <h4 className="pt-3">{category}</h4>

                            {groupedConfigs[category].map((item, itemIndex) => (
                                <Fragment key={item.id}>
                                    <DraggableField index={itemIndex} itemType={category} onUpdatePosition={(from, to) => onUpdatePosition(from, to, groupedConfigs[category])}>
                                        <ItemContent
                                            item={item}
                                            index={itemIndex}
                                            isFirstItem={itemIndex === 0}
                                            isLastItem={itemIndex === groupedConfigs[category].length - 1}
                                            onDeactivate={() => selectItem(item)}
                                            onUpdatePosition={(from, to) => onUpdatePosition(from, to, groupedConfigs[category])}
                                        />
                                    </DraggableField>
                                </Fragment>
                            ))}
                        </Fragment>
                    ))}

                    {/* Render non-STAGE configs */}
                    {nonStageConfigs.map((item, index) => (
                        <div key={index} className="pb-2">
                            <DraggableField
                                index={index}
                                itemType="config"
                                onUpdatePosition={(from, to) => onUpdatePosition(from, to, nonStageConfigs)}
                            >
                                <ItemContent
                                    item={item}
                                    index={index}
                                    isFirstItem={index === 0}
                                    isLastItem={index === nonStageConfigs.length - 1}
                                    onDeactivate={() => selectItem(item)}
                                    onUpdatePosition={(from, to) => onUpdatePosition(from, to, nonStageConfigs)}
                                />
                            </DraggableField>
                        </div>
                    ))}
                </DndProvider>
            </div>

            <Modal size="lg" isOpen={createModal} toggle={() => setCreateModal(false)}>
                <ModalHeader toggle={() => setCreateModal(false)}>Create config</ModalHeader>

                <ModalBody>
                    <MetaForm meta={form} config={config} object={query} setValue={(field, value) => setQuery((query) => ({ ...query, [field]: value }))} />
                </ModalBody>

                <ModalFooter>
                    <Button color="light" onClick={() => setCreateModal(false)}>Cancel</Button>
                    <Button color="primary" disabled={isCreateDisabled()} onClick={createConfig}>Create</Button>
                </ModalFooter>
            </Modal>

            <ConfirmationModal
                size="md"
                isOpen={selectedItem !== false}
                onClose={closeDeactivateModal}
            >
                Are you sure you want to deactivate this item?
            </ConfirmationModal>
        </div>
    );
}

export default ListTenantConfig;
