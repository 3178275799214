/** @jsxImportSource @emotion/react */
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
    Button,
    Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { GROUP_STRATEGY } from "../../../../actions/Layers/constants";
import useRipeningRoom from "./hooks/useRipeningRoom";

export const GroupStrategyModal = () => {
    const { groupView, setGroupView } = useRipeningRoom();
    const [modalOpen, setModalOpen] = useState(false);


    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleSelectView = (type) => {
        if (groupView !== type) {
            setGroupView(type);
        }
        toggleModal();
    };
    const GroupViewButton = ({ type }) => (
        <div className="pb-4">
            <h4 className="text-muted mb-2">{type}</h4>
            <Button
                size="sm"
                color="primary"
                outline={groupView !== type}
                onClick={() => handleSelectView(type)}
            >
                Select view
            </Button>
        </div>
    );

    return (
        <div className="pb-2 pb-md-0 pe-2">
            <Button color="light" className="text-nowrap" onClick={toggleModal}>
                <FontAwesomeIcon icon={faEye} /> {groupView}
            </Button>
            <Modal isOpen={modalOpen} size="xl">
                <ModalHeader>Table view</ModalHeader>
                <ModalBody>
                    {/* {[LAYER_TYPE.PURCHASE_ORDER, LAYER_TYPE.PALLET].map((type, index) => (
                        <GroupViewButton key={index} type={type} />
                    ))} */}
                    {
                        Object.values(GROUP_STRATEGY).map((type, index) => (
                            <GroupViewButton key={index} type={type} />
                        ))
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};
