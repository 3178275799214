

import {
    baseConfig,
} from "../constants";
import { RootConfigType } from "../constantsTyped";


export const DeLaatConfig: RootConfigType = {
    ...baseConfig,
    use_barcode_scanner: false,
    indicate_quality_at_defects: false,
    lang: {
        pressure: "Pressure"
    },
};
