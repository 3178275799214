/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const tableStyles = css`
  width: 100%;
  table-layout: fixed; /* Prevents table resizing based on content */
  
  .table-hover tbody tr:hover {
    background-color: #f5f5f5;
    color: #333;
    cursor: pointer;
  }

  th, td {
    vertical-align: bottom;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px; /* Adjust the maximum width as needed */
  }
`;

export const modalFooterStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const rightAlignButtons = css`
    display: flex;
    gap: 10px;
    margin-left: auto;
`;

export const tableThStyle = css`
  color: black !important;
  background: #e5e8e5 !important;
  border-right: 1px solid white;
  max-width: 200px; /* Adjust as necessary for header */
`;

export const tableRow = css`
  .layer-meta-color-badge-field {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;
