/** @jsxImportSource @emotion/react */
import { applyDisplayFilterLayerFields } from "../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig, { useTenantFormOptions } from "../../../../actions/Tenants/config/configHook";
import useRipeningRoom from "./hooks/useRipeningRoom";

export default function RipeningCellMetaTable({ fields }) {
    const { fruit_type, pallets, currentLocation } = useRipeningRoom();
    const config = useConfig();
    const form_options = useTenantFormOptions();

    const filteredFields = applyDisplayFilterLayerFields(fields, {
        fruit_type
    });

    if (fields.length === 0) {
        return null;
    }


    return <table className="w-100">
        <tbody>
            {filteredFields.map((field, index) => <tr key={index}>
                <td className="fw-bold text-no-wrap">{field.label}</td>
                <td className="text-end">{field.set_value({
                    config,
                    ripeningRoom: currentLocation,
                    children: pallets,
                    form_options
                }).display_component()}</td>
            </tr>)}
        </tbody>
    </table>;


}
