/* eslint-disable react/jsx-no-undef */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { CATEGORIES_WITH_CHECK_BUTTON, LAYER_TAB_CATEGORIES } from "../../../actions/Layers/constants";
import useConfig from "../../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault } from "../../../utils";
import ScanLayerButton from "../Layers/ScanLayerButton";
import Header from "../Layout/Header";
import StagesTabs from "./StagesTabs";
import { SwitchTabProvider, useSwitchTabContext } from "./fields/useSwitchTab";
import FullPageLayout from "../Layout/FullPageLayout";

export default function WrappedLayerStart() {
    return (
        <SwitchTabProvider>
            <LayerStart />
        </SwitchTabProvider>
    );
}

export function LayerStart() {
    const config = useConfig();
    const navigate = useNavigate();
    const { tab_category } = useSwitchTabContext();

    return (
        <FullPageLayout header={<Header showLayerFilters />}>
            {config.mock_signals && (
                <Container>
                    <Row className="py-4">
                        <h3 className="fw-bold"><strong>{"Today's focus"}</strong></h3>

                        <Col md="12">
                            <div className="d-flex pt-3">
                                {config.mock_signals.map((i, index) => <div key={index} css={css`border-radius: 8px;`} className={`p-4 me-4 bg-white card-alert --${i.flag}`} >
                                    <h6 className="mb-0"><b>{i.title}</b></h6>
                                    <div className="pb-2">{i.description}</div>
                                    <Button className="p-2" color={i.color} onClick={() => navigate(i.url)}>Take action</Button>
                                </div>)}
                            </div>
                        </Col>
                    </Row>
                </Container>
            )}

            {tab_category && getFilledArrayOrDefault(config.root_config.tab_categories_with_check_button, CATEGORIES_WITH_CHECK_BUTTON).includes(tab_category as LAYER_TAB_CATEGORIES) && (
                <div className="md-5 pt-5">
                    <ScanLayerButton />
                </div>
            )}

            <StagesTabs />
        </FullPageLayout>
    );
}


