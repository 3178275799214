import { useReducer, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup, Row, Col, Alert } from "reactstrap";
import { toast } from "../../../utils/toast";
import { getRelabellingSessionDetail, updateDefect, getNewBatch } from "../../../actions/Labelling/actions";
import FullPageLayout from "../Layout/FullPageLayout";

function reducer(state, { field, value }) {
    return {
        ...state,
        [field]: value,
    };
}
const filterClasses = {
    MANUAL: "",
    NIR: "row-cols-4",
    COLOUR: "row-cols-4",
};

const initialState = {
    current_item_index: null,
    filter: "MANUAL",
    todo: null,
};

export default function NewLabelling() {
    const user = useSelector((state) => state.auth.user);
    const {
        currentSession, isLoading, noMoreBatch, newBatchAdded,
    } = useSelector((state) => state.labeling);

    const dispatch = useDispatch();

    const [state, dispatchLocal] = useReducer(reducer, initialState);
    const { current_item_index, filter, todo } = state;

    const params = useParams();

    useEffect(() => {
        if (params.session_id) {
            dispatch(getRelabellingSessionDetail(params.session_id));
        }
    }, [params.tenant_id]);

    useEffect(() => {
        if (current_item_index === null) {
            dispatchLocal({ field: "current_item_index", value: 0 });
        }
        if (currentSession && currentSession.defects.length === 0 && noMoreBatch === false) {
            dispatch(getNewBatch(params.session_id, 10));
        }
        countTodo();
    }, [currentSession]);

    useEffect(() => {
        if (newBatchAdded === true) {
            // dispatch(getRelabellingSessionDetail(params.session_id))
            toast.warning("New image batch has been added!", {
                // autoClose: 1500,
            });

            // newBatchAdded = false;
            dispatchLocal({ field: "newBatchAdded", value: false });
        }
    }, [newBatchAdded]);

    const countTodo = () => {
        if (!currentSession || currentSession.defects.length === 0) return;

        let temp = 0;
        currentSession.defects.forEach((item) => {
            if (item.obvious === null) {
                temp += 1;
            }
        });
        dispatchLocal({ field: "todo", value: temp });
    };
    const getCurrentIndexFilteredImage = () => {
        if (!currentSession || currentSession.defects.length === 0) return [];
        if (filter === "MANUAL") return currentSession.defects[current_item_index].images.filter((i) => i.type === "manual");
        if (filter === "NIR") return currentSession.defects[current_item_index].images.filter((i) => i.type.includes(filter));
        if (filter === "COLOUR") return currentSession.defects[current_item_index].images.filter((i) => i.type.includes("C"));
        return null;
    };

    const nextPrevBtn = (step, notification = true) => {
        const new_index = current_item_index + step;

        if (new_index < 0 || currentSession.defects.length <= new_index) {
            if (notification) {
                toast.warning("No more image", {
                    autoClose: 1500,
                });
            }

            return false;
        }

        dispatchLocal({ field: "current_item_index", value: new_index });
        return true;
    };

    const submitRelabelForDefect = (obvious) => {
        currentSession.defects[current_item_index].obvious = obvious;

        const data = {
            session_id: params.session_id,
            defect_id: currentSession.defects[current_item_index].defect_id,
            obvious,
        };
        dispatch(updateDefect(data));

        const result = nextPrevBtn(+1, false);
        if ((current_item_index + 1) >= currentSession.defects.length - 1) {
            dispatch(getNewBatch(params.session_id, 10));
        }
        countTodo();

        if (result === false && (current_item_index + 1) === currentSession.defects.length && noMoreBatch) {
            const remaining = currentSession.defects.filter((i) => i.obvious === null);
            if (remaining.length > 0) {
                toast.warning("Few images are remaining, please use < > Buttons to navigate to those images and Relabel them", {
                    autoClose: 10000,
                });
            } else {
                toast.success("All images has been relabeled successfully! Now you can leave this page. ", {
                    autoClose: 10000,
                });
            }
        }
    };

    // const user = useSelector(state => state.auth.user);

    // useEffect(() => {
    //     dispatch(listTenants());
    // }, [dispatch]);
/* eslint-disable  */
    return (
        <FullPageLayout>
            <Row className="justify-content-center py-3 mt-5">
                {/* {isLoading && <Loading></Loading>} */}
                {currentSession && /* current_item_index != null  && */ currentSession.defects.length > 0
                    && <Col xs="12" >
                        <Row className="justify-content-center">
                            <Col xs="10" className="align-items-center text-center">
                                <Row className="align-items-center " >
                                    {todo === 0
                                        && <Col xs={{ size: 10, offset: 1 }} className="px-0">
                                            <Alert color="success">You have relabeled all of the images for this session, you can <Link to="/labelling">Click Here</Link> to go back to home page.</Alert>
                                        </Col>
                                    }

                                    {user.tenant.tenant_id === "a6aff297-ac1b-4e81-88a6-420591d31f31"
                                        && <Col xs="12" className="py-3">
                                            <ButtonGroup>
                                                <Button className="px-5" color={(filter === "NIR") ? "success" : "secondary"} onClick={() => dispatchLocal({ field: "filter", value: "NIR" })}>NIR</Button>
                                                <Button className="px-5" color={(filter === "MANUAL") ? "success" : "secondary"} onClick={() => dispatchLocal({ field: "filter", value: "MANUAL" })}>Manual</Button>
                                                <Button className="px-5" color={(filter === "COLOUR") ? "success" : "secondary"} onClick={() => dispatchLocal({ field: "filter", value: "COLOUR" })}>Colour</Button>
                                            </ButtonGroup>
                                        </Col>
                                    }
                                    <Col xs="1"><Button color="secondary" type="button" onClick={() => nextPrevBtn(-1)}>{"<"}</Button></Col>
                                    <Col xs="10" className="relabeling_session_manual" style={{ padding: "30px" }}>
                                        <Row className={filterClasses[filter]}>
                                            {filter && getCurrentIndexFilteredImage().map((i, index) => (
                                                <Col className="px-0" key={index}>
                                                    <img alt="Defect" src={i.url} style={{ maxWidth: "100%", maxHeight: "400px" }} />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                    <Col xs="1"><Button color="secondary" type="button" onClick={() => nextPrevBtn(1)}>{">"}</Button></Col>
                                    <Col xs="12">{current_item_index + 1} of {currentSession.defects.length} </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs={{ size: 10 }} className="py-3">
                                <Row className="justify-content-center">

                                    <Col xs={{ size: 10 }} className="py-3">
                                        <h3 className="text-center">Do you see <b>{currentSession.defect_type}</b> in the above picture?</h3>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col xs={{ size: 10 }} className="px-0">
                                        <Row className="justify-content-center">

                                            <Col>
                                                <Button className="py-1" size="lg" color="danger" block disabled={isLoading} onClick={() => submitRelabelForDefect("0")} >Disagree
                                                    {currentSession.defects[current_item_index].obvious === "0" && <span role="img" aria-label="Selected">✅</span>}
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button className="py-1" size="lg" color="warning" block disabled={isLoading} onClick={() => submitRelabelForDefect("2")} >Light
                                                    {currentSession.defects[current_item_index].obvious === "2" && <span role="img" aria-label="Selected">✅</span>}
                                                </Button>

                                            </Col>
                                            <Col>
                                                <Button className="py-1" size="lg" color="success" block disabled={isLoading} onClick={() => submitRelabelForDefect("3")} >Heavy
                                                    {currentSession.defects[current_item_index].obvious === "3" && <span role="img" aria-label="Selected">✅</span>}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                }
            </Row>
        </FullPageLayout>);

        /* eslint-enable  */
}
