/** @jsxImportSource @emotion/react */

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

export interface HeaderWithCloseAndNoNavProps {
    title?: string;
    children?: React.ReactNode;
    breadcrumbs?: React.ReactNode;
}

/**
 * HeaderWithCloseAndNoNav component
 *
 * This component renders a page header with an optional title and breadcrumbs,
 * along with a close button that navigates back to the previous route.
 *
 * Features:
 * - Displays a title (`title` prop).
 * - Supports additional content via `children` (e.g., extra buttons or controls).
 * - Shows breadcrumbs (`breadcrumbs` prop) if provided.
 * - Uses `useNavigate` to handle closing behavior, preferring the "from" query parameter
 *   or the last known browse history entry.
 *
 * TODO: Refactor to use only `useNavigateFrom`.
 */
export const HeaderWithCloseAndNoNav = ({ children, title, breadcrumbs }: HeaderWithCloseAndNoNavProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    // TODO: refactor to use only useNavigateFrom
    const browse_history = useSelector<any, Location[]>((state) => state.navigation.browse_history);


    const getPreviousRoute = () => {
        const currentParams = new URLSearchParams(location.search);

        // If from parameter is set, prefer that route
        const lastRoute = currentParams.get("from") || browse_history?.[browse_history.length - 1]?.pathname;

        if (!lastRoute || (lastRoute === location.pathname)) return "/";

        return lastRoute;
    };

    return (
        <div className="w-100">
            {children}

            <div className="pt-5 pb-5">
                <div className="d-flex justify-content-end align-items-center">
                    <div className="pb-2 pb-sm-0 me-2 d-inline-flex d-md-block">
                        <h3 className="mb-0 ">{title}</h3>
                    </div>

                    <div className="pb-2 pb-sm-0 d-flex align-items-center text-end ms-auto">
                        <Button
                            className="btn-close my-1 ms-auto"
                            size="lg"
                            onClick={() => navigate(getPreviousRoute())}
                        />
                    </div>
                </div>

                {breadcrumbs}
            </div>
        </div>
    );
};
