import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useConfig from "../../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault } from "../../../utils";
import {
    useFruitTypes,
    useUserPreferences,
} from "../../../utils/useUserPreferences";
import { CheckboxButtons } from "../../Forms/CheckboxButtons";

const MAX_FRUIT_IN_HEADER = 3;

export default function FruitFilter() {
    const config = useConfig();
    // const filter = useSelector<any, any>((state) => state.layers.filter);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, updatePreferences] = useUserPreferences();
    const [isOpen, setModalOpen] = useState(false);
    const allOptions = useSelector(
        (state: any) => state?.tenants?.options_by_field
    );
    const fruitTypeOptions = allOptions?.[config.tenant_id]?.fruit_type || [];
    const selectedFruitTypes = useFruitTypes();

    const onFruitTypeChange = (fruit_type: (string | number | boolean)[]) => updatePreferences({ fruit_types: getFilledArrayOrDefault(fruit_type as string[]) });


    return (
        <div>
            <CheckboxButtons
                color="secondary"
                return_array={true}
                options={fruitTypeOptions.map((option, index) => ({
                    ...option,
                    hidden: index > (MAX_FRUIT_IN_HEADER - 1) && fruitTypeOptions.length >= MAX_FRUIT_IN_HEADER,
                    className: "mx-1",
                }))}
                selectedOptions={selectedFruitTypes}
                onChange={onFruitTypeChange}
            />

            {fruitTypeOptions.length >= MAX_FRUIT_IN_HEADER && <Button
                outline={true}
                className="mx-1"
                onClick={() => setModalOpen(true)}>
                {fruitTypeOptions.length - MAX_FRUIT_IN_HEADER} more
            </Button>

            }
            <Modal isOpen={isOpen} toggle={() => setModalOpen(false)}>
                <ModalHeader toggle={() => setModalOpen(false)}>Filter Fruit Types</ModalHeader>
                <ModalBody>
                    <CheckboxButtons
                        color="secondary"
                        return_array={true}
                        options={fruitTypeOptions.map((option) => ({
                            ...option,
                            className: "mx-1 mb-2",
                        }))}
                        selectedOptions={selectedFruitTypes}
                        onChange={onFruitTypeChange}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => setModalOpen(false)}>Continue</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
