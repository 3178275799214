import { avocado_min_weight } from "../../../Layers/constants";
import { AVOCADO_FRUIT } from "../fruitNames";

import {
    baseConfig,
    default_location_config,
    headerLinks
} from "../constants";
import { RootConfigType } from "../constantsTyped";
import { createBarcodeFilter } from "../utils";


export const MehadrinNLConfig: RootConfigType = {
    ...baseConfig,

    summary_collapsed: false,
    default_fruit_type: AVOCADO_FRUIT,
    can_do_manual_check: true,
    default_location_config: { ...default_location_config, device_result_action: false },
    default_manual: true,
    strict_check_size: false,
    indicate_soft_avocado_at_internal: false,
    suggest_color_stage: true,
    underweight: avocado_min_weight,
    default_location: "ripening",

    research_mode: false,
    invalidate_20x_firmness: false,

    headerMainLinks: [headerLinks.home, headerLinks.packinglist],
    headerSecondaryLinks: [headerLinks.packinglist, headerLinks.settings, headerLinks.logout],
    barcode_filter: createBarcodeFilter([
        // Regular expression to match a string consisting of exactly 20 digits
        // Get the 20 digits as a group
        /^(\d{20})$/,
        // Regular expression to match a string starting with 'P' followed by exactly 7 digits
        // Get the 7 digits as a group
        /^P(\d{7})$/
    ]),
};
