import { moveItemDown, moveItemUp } from "../../../../utils/array";

export const updateItemInArray = (setArray, array, index, field, value, field_or_display_filter: string | false | undefined = false) => setArray(array.map((item, i) => {
    if (i !== index) {
        return item;
    }
    const updateWholeObject = typeof field === "object";
    if (field_or_display_filter === "field") {
        if (updateWholeObject) {
            return { ...item, field };
        }
        return { ...item, field: { ...item.field, [field]: value } };
    } if (field_or_display_filter === "display_filter") {
        if (updateWholeObject) {
            return { ...item, display_filter: field };
        }
        return { ...item, display_filter: { ...item.display_filter, [field]: value } };
    }
    if (updateWholeObject) {
        return field;
    }
    return { ...item, [field]: value };
}));
export const deleteItemInArray = (setArray, array, index) => setArray(array.filter((_, i) => i !== index));
export const addItemInArray = (setArray, array, newValue) => setArray(array.concat(newValue));
export const moveItemUpInArray = (setArray, array, index) => {
    const newArray = moveItemUp(array, index);
    setArray(newArray);
};
export const moveItemDownInArray = (setArray, array, index) => {
    const newArray = moveItemDown(array, index);
    setArray(newArray);
};

export const yesOrNoRadio = {
    type: "radio",
    options: [
        {
            value: true,
            label: "Yes"
        },
        {
            value: false,
            label: "No"
        }
    ]
};


export function deepEqual(object1, object2) {
    if (typeof object1 === "undefined" || typeof object2 === "undefined") {
        return false;
    }

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }
    const attEqual = keys1.map((key) => {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (areObjects && !deepEqual(val1, val2)) {
            return false;
        }
        if (!areObjects && val1 !== val2) {
            return false;
        }
        return true;

    });

    return !attEqual.includes(false);

}

export function isObject(object) {
    return object != null && typeof object === "object";
}
