/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Badge,
    Button, Col, ListGroup, ListGroupItem, Row
} from "reactstrap";
import { logoutPowerBIEmbedInfo, switchTenant } from "../../../actions/Auth/actions";
import {
    deleteTenant, listTenants
} from "../../../actions/Tenants/actions";
import { VERSION_TAG } from "../../../constants/urls";
import { Loading } from "../../Helper/Loading";
import TemporaryTEFileUpload from "./TemporaryTEFileUpload";
import TenantCreateForm from "./TenantCreateForm";
import FullPageLayout from "../Layout/FullPageLayout";

function Tenants() {
    const tenants = useSelector((state) => state.tenants);
    // const devices = useSelector(state => state.devices);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const { isLoading, items } = tenants;

    useEffect(() => {
        dispatch(listTenants());
    }, [dispatch]);

    if (user.tenant.tenant_id !== "a6aff297-ac1b-4e81-88a6-420591d31f31") {
        return null;
    }

    if (isLoading && !items) {
        return <div css={css`min-height: 100vh;`}><Loading></Loading></div>;
    }

    return (
        <FullPageLayout>
            <Row className="justify-content-center pb-3">
                <Col >
                    <h2 className="">Hi {user.first_name}!</h2>
                    <span className="text-muted">You are running version <b>{VERSION_TAG}</b>, and logged in as <b>{user.username}</b></span>
                </Col>
            </Row>

            {items && items.length > 0
                && <div>
                    <Row className="justify-content-center py-3">
                        <Col >
                            <h2 className="">Active Tenants</h2>
                            <ListGroup >
                                {items.filter((i) => i.active).map((i) => <TenantItem key={`tenant-${i.tenant_id}`} tenant={i} />)}
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row className="justify-content-center py-3">
                        <Col >
                            <h2 className="">Other Tenants</h2>
                            <ListGroup >
                                {items.filter((i) => !i.active).map((i) => <TenantItem key={`tenant-${i.tenant_id}`} tenant={i} />)}
                            </ListGroup>
                        </Col>
                    </Row>
                </div>
            }
            <Row className="justify-content-center py-3">
                <Col >
                    <TenantCreateForm />
                </Col>
            </Row>
            <Row className="justify-content-center py-3">
                <Col >
                    <h2 className="">Excel upload</h2>
                    <TemporaryTEFileUpload/>
                </Col>
            </Row>
        </FullPageLayout>
    );
}

function TenantItem({ tenant }) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    return (
        <ListGroupItem>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <strong>{tenant.tenant_name}</strong> {tenant.active && <Badge size="sm" color="primary">Active</Badge> }
                    <br></br>{tenant.tenant_id}
                </div>
                <div className="d-flex">
                    <Button color="primary" className="me-1" outline onClick={() => navigate(`/tenants/${tenant.tenant_id}`)} >Edit</Button>
                    <Button color="warning" outline onClick={() => {
                        dispatch(logoutPowerBIEmbedInfo());
                        dispatch(switchTenant(tenant)).then(() => navigate("/"));
                    }} >Test</Button>
                    {false && user.tenant.tenant_id === "a6aff297-ac1b-4e81-88a6-420591d31f31" && <Button color="danger" outline onClick={() => dispatch(deleteTenant(tenant.tenant_id))} >Delete</Button>}
                </div>
            </div>
        </ListGroupItem>
    );
}

TenantItem.propTypes = {
    tenant: PropTypes.object,
};

export default Tenants;
