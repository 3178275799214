/** @jsxImportSource @emotion/react */
import { useMsal } from "@azure/msal-react";
import { css } from "@emotion/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import useConfig from "../../../actions/Tenants/config/configHook";
import { ENV, isStagingEnv, isTestEnv, loginRequest, subdomainByEnv } from "../../../authConfig";
import { IS_DEMO } from "../../../constants/urls";
import { toast } from "../../../utils/toast";
import { Loading } from "../../Helper/Loading";
import { RootState } from "../../../reducers";
import { isSupplierPortal } from "../../utils";
import Layout from "./components/Layout";

export default function Login() {
    const { instance } = useMsal();
    const isLoading = useSelector((state: RootState) => state.auth.isLoading);
    const authenticated = useSelector(
        (state: RootState) => state.auth.authenticated
    );
    const [redirecting, isRedirecting] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const timer = useRef<NodeJS.Timeout | null>(null);

    const config = useConfig();

    // we start the screen in loading state so that we have smoother transition after redirecting from AAD
    useEffect(() => {
        if (!isLoading && !redirecting && !authenticated && !!timer) {
            timer.current = setTimeout(() => setShowLoader(false), 2000);
        } else {
            clearTimeout(timer.current || undefined);
        }

        return () => clearTimeout(timer.current || undefined);
    }, [redirecting, authenticated, isLoading]);

    // store redirectTo for later reference
    useEffect(() => {
        if (location.search) {
            const search = new URLSearchParams(location.search);
            const redirectTo = search.get("redirect_to");

            // Keep to original redirect_to if it is not login page
            if (redirectTo && !redirectTo.startsWith("/login")) {
                localStorage.setItem("avos_login_redirect", redirectTo);
            }
        }
    }, [location.search]);

    // after login send to root or redirectTo
    useEffect(() => {
        if (authenticated) {
            isRedirecting(true);

            const redirectTo = localStorage.getItem("avos_login_redirect");

            if (redirectTo) {
                localStorage.removeItem("avos_login_redirect");
            }

            navigate(redirectTo || config.home_page);
        }
    }, [authenticated]);

    const redirect = async () => {
        try {
            localStorage.removeItem("msal.interaction.status");

            await instance.loginRedirect(loginRequest);
        } catch (err: unknown) {
            if (err instanceof Error) {
                console.error(err.message); // eslint-disable-line no-console
                toast.error(`Cannot request login redirect url: ${err.message}`);
            } else {
                console.error("An unknown error occurred"); // eslint-disable-line no-console
            }
        }
    };

    const hostname = `${window.location}`;
    const appName = isSupplierPortal() ? "the supplier portal" : "EYE";
    const redirectTo = isSupplierPortal() ? "EYE" : "the supplier portal";

    const redirectToUrl = useMemo(() => {
        // eslint-disable-next-line no-nested-ternary
        const env = isStagingEnv ? ENV.Staging : isTestEnv ? ENV.Test : ENV.Default;

        const subdomains = {
            from: isSupplierPortal() ? "supplierPortal" : "avos",
            to: isSupplierPortal() ? "avos" : "supplierPortal"
        };

        return hostname.replace(subdomainByEnv[subdomains.from][env], subdomainByEnv[subdomains.to][env]);
    }, [hostname]);

    if (showLoader || redirecting) {
        return <Loading />;
    }

    return (
        <Layout>
            <h4 className="d-flex flex-column gap-2 text-center mb-0">
                <b>Welcome!</b>
                <span css={css`font-size: 1.25rem;`}>
                    Login to continue to <b>{appName}</b>.
                </span>
            </h4>

            <div className="d-flex flex-column gap-3">
                <Button
                    className="btn btn-primary btn-lg"
                    onClick={IS_DEMO ? () => navigate("/") : () => redirect()}
                >
                    {IS_DEMO ? "Start DEMO" : "Login with Microsoft"}
                </Button>

                <span css={css`font-size: 14px;`}>
                    Looking for {redirectTo}?{" "}
                    <a href={redirectToUrl}>
                        Go to the <b>{redirectTo}</b> login page
                    </a>
                </span>
            </div>
        </Layout>
    );
}
