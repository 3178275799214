/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { AVOCADO_FRUIT, MANGO_FRUIT } from "../../../../actions/Tenants/config/fruitNames";
import { formatCheck2Frontend } from "../../../../actions/Checks/util";
import { CHECK_LOCATION, Check, Layer } from "../../../../actions/Layers/constants";
import { callAvosApi } from "../../../../utils/useAvosApiHook";
import { Loading } from "../../../Helper/Loading";
import { BoxItem } from "../../AddItem/components/Summary/Boxes";
import { LayerLabResults } from "./LayerCustomCheckResults";

export default function LabCheck({ title }: { title: string }) {
    const layer = useSelector<any, Layer>((state) => state.layers.current);
    const checks = useSelector<any, Check[]>((state) => state.layers.checks);
    const isLoading = useSelector<any>((state) => state.layers.isLoading);
    const [localCheck, setCheck] = useState<Check | null>(null);
    const labChecks = checks.filter((i) => i.location === CHECK_LOCATION.LAB_CHECK).sort((a, b) => b.test_id - a.test_id);
    const fruitTypeCheck = layer.fruit_type === AVOCADO_FRUIT || layer.fruit_type === MANGO_FRUIT; // Only display for Avocado or Mango

    const LatestLabCheckId = labChecks[0];
    useEffect(() => {
        if (LatestLabCheckId) {
            callAvosApi(`/tests/${LatestLabCheckId.test_id}/`).then((response) => {
                setCheck(formatCheck2Frontend(response.data));
            });
        }
    }, [LatestLabCheckId]);

    if (isLoading) {
        return <Loading />;
    }

    if (!layer || !checks) {
        return null;
    }

    return fruitTypeCheck ? (
        <>
            <div className="d-flex">
                <h3 className=" mb-0">{title}</h3>

                <div className="ms-auto">
                    {localCheck && (
                        <Button color="secondary"
                            title={title}
                            outline size="xs"
                            href={`/layer/${layer.id}/add-check/${LatestLabCheckId.test_id}/summary`}
                        >
                            View Check
                        </Button>
                    )}
                </div>
            </div>

            {/* TODO REPEATED LOGIC */}
            <div className="pt-3">
                {!localCheck && <div className="text-warning">No {title} available</div>}
                {localCheck?.avocados && <BoxItem avocados={localCheck.avocados} check={localCheck} layer={layer} title={`Check ${localCheck.test_id}`} />}
            </div>
            <LayerLabResults />
        </>
    ) : null;
}
