import { extractMetaProperties } from "../Layers/util";
import { createFlatObjectFromLayerWithMetaObject, createLayerWithMetaObjectFromFlatObject } from "../util";

export const formatCheck2Backend = (data) => {
    const fields = [
        "test_id",
        "user_id",
        "created",
        "updated",
        "is_pre_ripening",
        "like",
        "box_size",
        "desired_stage",
        "is_maxi_check",
        "maxi_exit",
        "maxi_process_name",
        "location",
        "is_manual",
        "feedback",
        "next_action",
        "status_check",
        "user_agreement",
        "user_avg_pressure",
        "user_split",
        "pallet_status",
        "size",
        "size_category",
        "flag",
        "tracebility",
        "weight_kg",
        "pallet_integrety",
        "box_integrity",
        "temperature",
        "shelf_life_type",
        "box_net_weight",
        "movement",
        "rejection_satisfied",
        "flag_external_defects",
        "flag_internal_defects",
        "flag_average_brix",
        "flag_maturity_dist",
        "flag_average_maturity",
        "flag_average_dry_matter",
        "flag_julian_code",
        "flag_pressure_homogeneity",
        "flag_ripening_speed",
        "flag_defects",
        "flag_pressure",
        "flag_ripeness",
        "flag_dm_brix",
        "flag_homogeneity",
        "internal_defected_fruit",
        "external_defected_fruit",
        "total_defected_fruit",
        "regression_ratio",
        "regression_msle",
        "tenant_id",
        "layer_id",
        "location_id",
        "session_id",
        "avocados", // readonly field
        "images", // readonly field
        "has_pressurecalibration", // readonly field
        "defects", // readonly field
        "defect_list", // readonly field
    ];
    return createLayerWithMetaObjectFromFlatObject(data, fields);
};
// * format meta array to flat object to make our lives easier
export const formatCheck2Frontend = (backendCheck) => {
    let check = createFlatObjectFromLayerWithMetaObject(backendCheck);

    // * Make a flat object from the defects array (defects logged on check, but not on fruit. aka visual check), but keep the orginal list to use in the pdf reports
    if (Array.isArray(check.avocados)) {
        const avocados = extractMetaProperties(check.avocados);
        check = { ...check, avocados };
    }

    if (Array.isArray(check.defects)) {
        const defect_list = check.defects;
        const defects = check.defects.reduce((acc, defect) => ({ ...acc, [defect.defect_id]: defect.frequency }), {});
        return { ...check, defects, defect_list };
    }
    return check;
};
