
export const defaultFilter = {
    limit: 50, ordering: "-created",
};


export const DeviceEventType = {
    REBOOT: "reboot",
    TRIGGER: "trigger",
    BULK_TRIGGER: "bulk_trigger",
    TEST_CAMERA: "test_camera",
    TEST_PRESSURE: "test_pressure",
    TEST_GRAPH_PRESSURE: "test_graph_pressure",
    TEST_TAR_WEIGHTSCALE: "test_tar_weightscale",
    TEST_WEIGHT_WEIGHTSCALE: "test_weight_weightscale",
    TEST_SCALE_WEIGHTSCALE: "test_scale_weightscale",
    UPDATE_MCU: "update_mcu",
    UPDATE_AI_MODEL: "update_ai_model",
    VERSION_MCU: "version_mcu"
};

export const eventIsFinished = (status) => [
    DeviceEventStatus.PROCESSED,
    DeviceEventStatus.FAILED,
    DeviceEventStatus.BOUNCED,
    DeviceEventStatus.TIMEOUT
].includes(status);


export const DeviceEventStatus = {
    DEFAULT: "unprocessed",
    PROCESSED: "processed",
    PROCESSING: "processing",
    FAILED: "failed",
    TIMEOUT: "timeout",
    BOUNCED: "bounced"
};


export const DeviceEventStatusColor = {
    [DeviceEventStatus.DEFAULT]: "light",
    [DeviceEventStatus.PROCESSED]: "success",
    [DeviceEventStatus.PROCESSING]: "warning",
    [DeviceEventStatus.FAILED]: "danger",
    [DeviceEventStatus.TIMEOUT]: "danger",
    [DeviceEventStatus.BOUNCED]: "danger"
};
