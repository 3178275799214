import { BarcodeFormat } from "@zxing/library";

import {
    baseConfig,
    headerLinks,
    pages,
    path_fta_input,
} from "../constants";
import { RootConfigType } from "../constantsTyped";
import { createBarcodeFilter } from "../utils";


export const BakkerConfig: RootConfigType = {
    ...baseConfig,

    summary_collapsed: false,
    research_mode: true,
    pages: { ...pages, [path_fta_input]: "FTA Input" },
    lang: { ...pages, pressure: "Pressure" },
    show_device_toggle: false,
    barcode_filter: {
        filter: createBarcodeFilter([
            // This regex is for any barcode format starting with any 11 digits, then {8} as the pallet number, followed by any digit
            /^\d{11}(\d{8})\d$/,
        ]),
        formatsToSupport: [BarcodeFormat.CODE_128]
    },
    headerMainLinks: [headerLinks.home, headerLinks.packinglist],
    headerSecondaryLinks: [headerLinks.packinglist, headerLinks.settings, headerLinks.logout],
};
