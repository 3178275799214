/** @jsxImportSource @emotion/react */
import { useMsal } from "@azure/msal-react";
import { css } from "@emotion/react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { logoutUser, switchTenant } from "../../../../actions/Auth/actions";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { headerLinks } from "../../../../actions/Tenants/config/constants";
import { isFilledArray } from "../../../../utils";
import useAuthorization from "../../../../utils/authorization";
import { generalLinks } from "./GeneralLink";

export const NavSecondaryLinks = () => {
    const location = useLocation();
    const navigate = useNavigate();
    // @TODO: proper ts
    const { user, authenticated, isLoading } = useSelector((state: any) => state.auth);
    const auth = useAuthorization();
    const config = useConfig();
    const dispatch = useDispatch();
    const { instance } = useMsal();

    const logoutHandler = () => {
        // make sure to redirect to login so that the redirect_to=/add-check/134/summary param is not set to the currect page
        navigate("/login");
        dispatch(logoutUser());
        instance.logoutRedirect();
    };

    if (!authenticated) {
        // also show login item when loading, this will prevent "flickering" of the header during page load
        return (
            <>
                {
                    [
                        (location.pathname !== "/login" || isLoading) && <li className="nav-item" key="login">
                            <NavLink className="nav-link" to="/login" >Login</NavLink>
                        </li>,
                    ]
                }
            </>
        );
    }

    const hasMultiTenants = useMemo(() => user.tenants.length > 1, [user.tenants]);

    const links = {
        ...generalLinks,
        [headerLinks.logout]: () => (
            <li className="nav-item" key="logout" css={css`margin-right: 1rem;`}>
                <button className="nav-link link btn btn-link" onClick={logoutHandler}>
                    <span className="text-danger">Logout</span>
                </button>
            </li>
        ),
        [headerLinks.switchTenant]: () => (
            <li className="nav-item" key="switchTenant" css={css`min-width: 15rem;`}>
                <Select
                    options={user.tenants.map((tenant) => ({ value: tenant.tenant_id, label: tenant.tenant_name }))}
                    value={{ label: user.tenant_user.tenant_name, value: user.tenant_id }}
                    onChange={(option) => {
                        if (option) {
                        // @TODO: proper ts
                            dispatch(switchTenant({ tenant_id: option.value }) as any).then(() => navigate("/"));
                        }
                    }}
                />
            </li>
        ),
    };

    // @TODO this logic needs to be improved
    const linksToShow = useMemo(() => {
        const secondaryLinks = config.root_config.secondary_header_links;

        if (isFilledArray(secondaryLinks)) {
            const secondaryHeaderLinksToShow = secondaryLinks.filter((link) => {
                // Show link if link.roles is not defined or empty
                // OR user has at least one of the roles required by the link
                return !isFilledArray(link.roles) || auth.userHasOneOfRoles(link.roles);
            });

            // use urls configuerd in wizard
            const allLinks = [...secondaryHeaderLinksToShow, ...(hasMultiTenants ? [{ path: headerLinks.switchTenant, label: null }] : [])];

            return <>{allLinks.filter((item) => links[item.path]).map((item) => links[item.path](item.label))}</>;
        }

        // !warning do not use .append() here as it will add a switchTenant button for every render
        const allLinks = [...config.headerSecondaryLinks, ...(hasMultiTenants ? [headerLinks.switchTenant] : [])];

        return <>{Object.keys(links).filter((item) => allLinks.includes(item)).map((path) => links[path](null))}</>;
    }, [config.root_config, links, hasMultiTenants]);

    return linksToShow;
};
