/** @jsxImportSource @emotion/react */

import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { patchLayer } from "../../../../actions/Layers/actions";

export default function LayerNotes() {
    const dispatch = useDispatch();
    const layer = useSelector((state) => state.layers.current);

    return <>
        <h3 className="mb-0">NOTES</h3>

        <div className="pt-3" >
            <Input
                value={layer.arrival_feedback}
                type="textarea"
                id="latest_check_feedback"
                rows={4}
                maxLength={255}
                className="p-4"
                onChange={(e) => dispatch(patchLayer(layer.id, "arrival_feedback", e.target.value))}
            />
        </div>

    </>;
}
