/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ROOT_URL } from "../../../constants/urls";
import Layout from "./components/Layout";

export default function Logout() {
    return (
        <Layout>
            <h4 className="d-flex flex-column gap-2 text-center mb-0">
                <b>You are logged out</b>
                <span css={css`font-size: 1.25rem;`}>
                    See you next time!
                </span>
            </h4>

            <a
                className="btn btn-primary w-100 btn-lg"
                href={`${ROOT_URL}oauth2/login`}
            >
                Login with Microsoft
            </a>
        </Layout>
    );
}
