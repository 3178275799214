import { AnyAction } from "redux";
import { PersonalTabSettings } from "../../components/scenes/Layers/fields/useSwitchTab";
import { PATCH } from "./actionsTypes";

export interface User {
    preferences?: AllUserPreferences;
    tenant: {
        tenant_id?: string;
    };
}

export interface UserPreferences {
    fruit_types?: string[];
    tabs?: {[tab: string]: PersonalTabSettings};
}

export interface AllUserPreferences {
    [tenant_id: string]: UserPreferences;
}

const endpoint = "users/";

export function updatePreferences(data: AllUserPreferences) {
    return {
        types: [PATCH.LOADING, PATCH.SUCCESS, PATCH.ERROR],
        payload: {
            request: {
                method: "PATCH",
                url: `${endpoint}preferences/`,
                data,
            },
        },
    } as unknown as AnyAction;
}
