/** @jsxImportSource @emotion/react */
import { LAYER_TYPE } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import MetaForm from "../../../Forms/MetaForm";
import { ReportTemplate } from "../../Layers/Layer/Report/utils";
import DefectGroupConfigForm from "./DefectGroupConfigForm";
import { DisplayFilterForm } from "./DisplayFilterForm";
import FieldConfigForm from "./FieldConfigForm";
import SanitizeUnexpectedFields from "./SanitizeUnexpectedFields";
import { yesOrNoRadio } from "./util";

export const defaultPdfReport = {
    text: "Intake",
    value: "intake",
    layer_type: "purchase_order",
    logo_name: "",
    internal_defects_title: "",
    external_defects_title: "",
    children_table_fields: [],
    children_check_summary_meta_fields: [],
    check_meta_fields: []

};

const ReportTemplateOptions = [
    { label: "Intake ( PO with per page a pallet )", value: ReportTemplate.intake },
    { label: "Storage  (PO with per page a check summary of 1 day per pallet)", value: ReportTemplate.storage },
    { label: "Advance ripening (PO with per page a pallet with table with a row per day)", value: ReportTemplate.advance_ripening },
    { label: "General check summary for check type (latest check with fruit table)", value: ReportTemplate.latest_check }

];


export default function PDFReportConfigForm({ config_data, setConfigData }) {
    const config = useConfig();

    // const flagNames = getFilledArrayOrDefault(config.color_codes).flatMap((i) => i.metrics || []).filter((i) => i.flag_name);
    // const chekStatus = getFilledArrayOrDefault(config.check_locations).map(({ value }) => `${value}_status`);

    const form = [
        {
            label: "Label",
            description: `What is the label of this pdf report. Used in the button (Print ${config_data.text}).`,
            name: "text",
            type: "text"
        },
        {
            label: "Title",
            description: `What is the title of this pdf report. Used in the header of the report and as email subject. Include "Report" in the title if needed. Will display as "${config_data.title} for {label}".`,
            name: "title",
            type: "text"
        },
        {
            label: "Use parent label in subject",
            description: "Use the parent label in the email subject. Most likely needed for Production and Reassessment reports.",
            name: "parent_label_in_subject",
            ...yesOrNoRadio
        },
        {
            label: "Value",
            description: `What is the value/id of this pdf report. If not ART, it will be used to fetch checks and check config of this type. This value will also be used in the url, blob_name and in qc_status field in layer meta.`,
            name: "value",
            type: "text",
            onchange_callback: ({ field, value }) => {
                setConfigData({ ...config_data, [field]: value.toLowerCase().replace(/[^a-z0-9]/g, "_") });
            },
        },
        {
            label: "Template",
            description: "What template to use for this report?",
            name: "template",
            type: "single-select",
            options: ReportTemplateOptions.map(({ label, value }) => ({ label, value }))
        },
        {
            label: "Check types",
            description: "What check types to include in the report. Use to overwrite default behaviour from setting above.",
            name: "check_types",
            type: "single-select",
            multi: true,
            return_array: true,
            options: config.check_locations.map(({ value, text }) => ({ value, label: `${text} (${value})` }))
        },
        {
            label: "Layer type",
            description: "On which layer type do you want to generate this report?",
            name: "layer_type",
            type: "single-select",
            options: Object.keys(LAYER_TYPE).map((i) => ({ value: LAYER_TYPE[i], label: i }))
        },
        {
            label: "Display filter",
            type: "element",
            name: "display_filter",
            el: <DisplayFilterForm
                object={config_data.display_filter}
                setValue={(field, value) => setConfigData({ ...config_data, display_filter: { ...config_data.display_filter, [field]: value } })}
                attributes={["fruit_types", "exclude_fruit_types"]} />
        },
        {
            label: "Logo name",
            name: "logo_name",
            description: "Show tenant logo on the pdf report. use extension like .png, .jpg, .jpeg...",
            type: "text"
        },
        {
            type: "element",
            name: "children_table_fields",
            el: <FieldConfigForm
                fields={config_data.children_table_fields || []}
                description="What fields to display in the children (pallet) table. Only for purchase order layer type."
                name="children_table_fields"
                title="Children table fields"
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                setArray={(children_table_fields) => setConfigData({ ...config_data, children_table_fields })} />
        },
        {
            type: "element",
            name: "pallet_meta_fields",
            el: <FieldConfigForm
                fields={config_data.pallet_meta_fields || []}
                description="What contextual data to display for pallet in ART report. "
                name="pallet_meta_fields"
                title="Pallet meta fields"
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                setArray={(pallet_meta_fields) => setConfigData({ ...config_data, pallet_meta_fields })} />
        },
        {
            type: "element",
            name: "children_check_summary_meta_fields",
            el: <FieldConfigForm
                fields={config_data.children_check_summary_meta_fields || []}
                description="What fields to show on the check summary"
                name="children_check_summary_meta_fields"
                title="Check summary fields"
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                setArray={(children_check_summary_meta_fields) => setConfigData({ ...config_data, children_check_summary_meta_fields })} />
        },

        {
            type: "element",
            name: "check_meta_fields",
            el: <FieldConfigForm
                fields={config_data.check_meta_fields || []}
                description="Check meta fields to display Intake report. Like Unapproved grower, Has contaminated fruit"
                name="check_meta_fields"
                title="Check meta fields"
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                setArray={(check_meta_fields) => setConfigData({ ...config_data, check_meta_fields })} />
        },
        {
            label: "Defect groups",
            name: "defect_groups",
            type: "element",
            el: <DefectGroupConfigForm
                fields={config_data.defect_groups || []}
                description="What group of defects do you want to show at the header of the check summary page?"
                name="defect_groups"
                title="Defect groups"
                setArray={(defect_groups) => setConfigData({ ...config_data, defect_groups })} />
        },
        {
            label: "Show send email button?",
            name: "show_send_email_button",
            description: "[Deprecated] Show send email button on the pdf report. Use teams_that_can_send instead.",
            ...yesOrNoRadio
        },
        {
            label: "Teams that can send the report",
            name: "teams_that_can_send",
            description: "Teams that can send the report. Leave empty to hide button. Button not showing? Set \"specific_pdf_layout\" to \"yes\".",
            type: "select-managed",
            multi: true,
            return_array: true,
            options_name: "teams",
        },
        {
            label: "Teams that can manage QC status of the report",
            name: "teams_that_can_manage_qc_status",
            description: "Teams that can manage QC status of the report (Mark ready for review). Leave empty to hide button. Button not showing? Set \"specific_pdf_layout\" to \"yes\".",
            type: "select-managed",
            multi: true,
            return_array: true,
            options_name: "teams",
        },
        {
            label: "Save draft report",
            name: "text_save_draft_report",
            description: "Text to show on the button to save the draft report. By default is 'Save draft report'.",
            type: "text",
        },
        {
            label: "Edit report",
            name: "text_edit_report",
            description: "Text to show on the button to edit report. By default is 'Edit'.",
            type: "text",
        },
        {
            label: "Report sent",
            name: "text_report_sent",
            description: "Text to show on the button to send report. By default is 'Report sent'.",
            type: "text",
        }, {
            label: "Show Dry matter?",
            name: "show_dry_matter",
            description: "Show dry matter in the pdf report.",
            ...yesOrNoRadio
        }, // TODO: create dropdown for flag fields
        {
            label: "Manual flag field",
            name: "manual_flag_field",
            description: "Field to manually flag the report. Leave empty to not use manual flag.",
            type: "text",
        },
        {
            label: "Business rule flag field",
            name: "business_rule_flag_field",
            description: "Field to automatically flag the report based on business rules. Leave empty to not use business rule flag.",
            type: "text",
        }
    ];

    return <div>
        <SanitizeUnexpectedFields form={form} object={config_data} setObject={setConfigData} />
        <MetaForm meta={form} setValue={(field, value) => setConfigData({ ...config_data, [field]: value })} object={config_data} config={config} />
    </div>;

}
