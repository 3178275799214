/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
    faArrowRight,
    faGrid,
    faList,
    faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { isFilledArray } from "../../../../utils";
import { Loading } from "../../../Helper/Loading";
import SidebarPageLayout from "../../Layout/SidebarPageLayout";
import RipeningRoomBreadcrumbs from "../RipeningRoomBreadcrumbs";
import { GroupStrategyModal } from "./GroupStrategyModal";
import useRipeningRoom, {
    LIST_OR_GRID,
    RipeningRoomProvider,
} from "./hooks/useRipeningRoom";
import { RipeWiseMetricMetaTable } from "./MetricCards";
import {
    AddSinglePalletModal,
    RemoveLayerModal,
    ScanToMoveLayerModal,
} from "./MovePalletsModal";
import RipeningCellConditionsForm from "./RipeningRoomConditionsForm";
import RipeningRoomDays from "./RipeningRoomDays";
import RipeningRoomItems from "./RipeningRoomItems";
import RipeningRoomMetaForm from "./RipeningRoomMetaForm";
import RipeningCellMetaTable from "./RipeningRoomMetaTable";
import { ScanToCheckModal } from "./StartCheckButton";

export default function RipeningRoomLayoutWrapped() {
    return (
        <RipeningRoomProvider>
            <RipeningRoomLayout />
        </RipeningRoomProvider>
    );
}

function RipeningRoomLayout() {
    const config = useConfig();

    const {
        listOrGrid,
        currentLocation,
        setListOrGrid,
        filterLevel,
        setFilterLevel,
        cellLevels,
        selectedLayersArray,
        fruit_type_config,
        refreshAllocatedLayers,
        deselectAll,
        pallets,
    } = useRipeningRoom();

    const [addPalletModalOpen, setAddPalletModal] = useState(false);
    const [movePalletsModalOpen, setMovePalletsModal] = useState(false);

    if (!currentLocation) {
        return <Loading />;
    }

    const roomIsNotEmpty = pallets?.length > 0
    && fruit_type_config
    && isFilledArray(fruit_type_config.ripening_fields);

    return (
        <SidebarPageLayout
            title={currentLocation?.label}
            breadcrumbs={<RipeningRoomBreadcrumbs currentLocation={currentLocation} />}
            sidebar={
                <>
                    <RipeWiseMetricMetaTable />

                    {isFilledArray(config.root_config?.ripening_room_sidebar_meta_fields_1) && (
                        <div className="mt-5 pt-5 border-top">
                            <RipeningCellMetaTable fields={config.root_config.ripening_room_sidebar_meta_fields_1} />
                        </div>
                    )}

                    {isFilledArray(config.root_config?.ripening_room_sidebar_meta_fields_2) && (
                        <div className="mt-5 pt-5 border-top">
                            <RipeningCellMetaTable fields={config.root_config.ripening_room_sidebar_meta_fields_2} />
                        </div>
                    )}
                </>
            }
            bottomBar={
                <Row>
                    <Col xs="12" md="6">
                        <div className="d-flex">
                            <div className="pe-2">
                                <Button
                                    className="text-nowrap"
                                    disabled={selectedLayersArray.length === 0}
                                    color={selectedLayersArray.length === 0 ? "light" : "primary"}
                                    onClick={() => setMovePalletsModal(true)}
                                >
                                    <FontAwesomeIcon icon={faArrowRight} /> Move{" "}
                                    {selectedLayersArray.length}
                                </Button>
                            </div>

                            <div className="pe-2">
                                <RipeningRoomMetaForm />
                            </div>

                            <GroupStrategyModal />

                            {config.root_config?.ripening_room_slot_allocation_from_erp && <div className="pe-2">
                                <Button
                                    color="light"
                                    className="text-nowrap"
                                    onClick={() => setListOrGrid((prev: string) => (prev === LIST_OR_GRID.LIST
                                        ? LIST_OR_GRID.GRID
                                        : LIST_OR_GRID.LIST))
                                    }
                                >
                                    {listOrGrid === LIST_OR_GRID.LIST && (
                                        <span>
                                            <FontAwesomeIcon icon={faGrid} /> Grid
                                        </span>
                                    )}

                                    {listOrGrid === LIST_OR_GRID.GRID && (
                                        <span>
                                            <FontAwesomeIcon icon={faList} /> List
                                        </span>
                                    )}
                                </Button>
                            </div>}

                            {listOrGrid === LIST_OR_GRID.GRID
                            && cellLevels.length > 0
                            && cellLevels.map((level, index) => (
                                <div key={index} className="pe-2">
                                    <Button
                                        className="text-nowrap"
                                        color={filterLevel === level ? "primary" : "light"}
                                        onClick={() => setFilterLevel(level)}
                                    >
                                        {level}
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </Col>

                    <Col xs="12" md="6">
                        <div className="d-flex justify-content-start justify-content-md-end pt-2 pt-md-0">
                            <ScanToMoveLayerModal />

                            <ScanToCheckModal />

                            <div className="ms-2">
                                <Button
                                    className="text-nowrap"
                                    color="primary"
                                    onClick={() => setAddPalletModal(true)}
                                >
                                    <FontAwesomeIcon icon={faPlus} /> Add pallets
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            }
        >
            <RipeningRoomDays />

            <div css={css`min-height: 80vh;`}>
                {roomIsNotEmpty && (
                    <div className="py-5">
                        <RipeningRoomItems />
                    </div>
                )}
            </div>

            {/* Ripening Cell Conditions Form */}
            <RipeningCellConditionsForm />

            <AddSinglePalletModal
                setModal={setAddPalletModal}
                modalOpen={addPalletModalOpen}
            />

            <RemoveLayerModal
                setModal={setMovePalletsModal}
                modalOpen={movePalletsModalOpen}
                selectedLayers={selectedLayersArray}
                refreshAllocatedLayers={refreshAllocatedLayers}
                deselectAll={deselectAll}
            />
        </SidebarPageLayout>
    );
}
