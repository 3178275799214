/** @jsxImportSource @emotion/react */
import { StyleSheet, View } from "@react-pdf/renderer";
import { PDFImages } from "./PDFImages";
import PDFLayerMeta from "./PDFLayerMeta";
import { PDFDocument, PDFPage } from "./PDFLayout";
import { PDFPallets } from "./PDFPallets";
import { PDFPalletsSummary } from "./PDFPalletsSummary";

/** @jsxImportSource @emotion/react */

import { format2Frontend } from "../../../../../actions/Layers/util";
import { getFilledArrayOrDefault } from "../../../../../utils";
import { getFruitTableData } from "../../../AddItem/components/Summary/FruitTable";


export const setPDFDataForIntakeReport = (config, user, all_defects, children, checks) => {
    // * Only use the pallets/children with intake check, set the check opbject on the pallet
    const children_with_intake = children.results
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((l) => {
            const merged_check = checks
                .filter((i) => i.layer_id === l.id)
                .reduce(
                    (acc, { avocados, defects, defect_list, images, ...i }) => ({
                        ...acc,
                        [`inspected_by_${i.location}`]: i.inspected_by,
                        avocados: [...acc.avocados, ...getFilledArrayOrDefault(avocados)],
                        images: [...acc.images, ...getFilledArrayOrDefault(images)],
                        defects: { ...acc.defects, ...defects },
                        defect_list: [...acc.defect_list, ...getFilledArrayOrDefault(defect_list)],
                        ...i
                    }),
                    {
                        avocados: [],
                        images: [],
                        defects: {},
                        defect_list: []
                    }
                );

            return {
                ...l,
                latest_check: merged_check,
            };
        }).filter((l) => l.latest_check?.test_id)
        .map(format2Frontend);

    // * Populate the intake check with fruit table data
    const updatedChildren = children_with_intake
        .map((child) => (
            // we overwrite the latest check here because the `/tests/{test_id}` endpoint will also return all images and underlying avocados while the layer endpoint does not
            {
                ...child,
                latest_check: {
                    ...child.latest_check,
                    label: `${child.label}`, // label for the images
                    table_data: getFruitTableData({ // this function generates the data for indivual fruit TODO: refactor to fields api
                        layer: child,
                        check: child.latest_check,
                        fruits: child.latest_check.avocados,
                        config,
                        user,
                        all_defects
                    }),
                    ...child.meta
                }
            }));

    const updatedChecks = updatedChildren.map((child) => child.latest_check);

    return { children: updatedChildren, checks: updatedChecks };

};


export const setPDFDataForAdvanceRipeningReport = (children, checks) => {
    // * checks are already sorted
    const sorted_children = children.results.sort((a, b) => a.label.localeCompare(b.label))
        .map((i) => ({ ...i, artChecks: checks.filter((c) => c.layer_id === i.id) }))
        .map((i) => ({ ...i, latest_check: i.artChecks?.[i.artChecks.length - 1] }))
    // .filter((i) => i.latest_check && i.latest_location.stage === allocated_stage) // * Filter for pallets currently in the allocated stage
        .filter((i) => i.latest_check?.test_id > 0) // * Filter out pallets that have atleast one ART check
        .sort((a, b) => a.latest_check.test_id - b.latest_check.test_id)
        .map((i) => format2Frontend(i));

    const getWeekDayName = (date) => {
        if (!date) return "-";
        return new Date(date).toLocaleDateString("en-US", { weekday: "long" });
    };

    // For every pallet, create a list of checks
    const checksPerLayer = sorted_children.map((childLayer) => {
        // Format the checks for frontend
        return childLayer.artChecks.map((check, index) => ({
            ...childLayer,
            label: `Day ${index + 1}`,
            latest_check: check,
            check_day: getWeekDayName(check.check_date), // Get the day name,
            // avocados: check?.avocados,
            all_images: check.all_images ? check.all_images.map((image) => ({ // for ART report we use current day for each image
                ...image,
                label: `Day ${index + 1}`
            })) : [],
        }));
    });

    // Update state with all formatted checks
    return { children: sorted_children, checks: checksPerLayer };
};

// * Global ART styles, slightly different then intake/storage styles
const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
        height: "70vh",
        marginTop: "20px"
    },
    cell1: {
        width: "42%", // Adjust based on your layout
        marginRight: "20px"
    },
    cell2: {
        width: "55%", // Adjust based on your layout
        // marginRight: "10px"
    },
});

export const TemplateAdvanceRipeningReport = ({ onRender, pdf_config, general_status, layer_config, layer, data, config }) => {
    // ! Warning: do not use any hooks that rely on the redux or api calls beyond this point. You will break the pdf rendering
    const { children_table_fields, logo_name, children_check_summary_meta_fields, pallet_meta_fields } = pdf_config ?? {} as any;

    // * fields to show on first page of the purchase order
    const parent_layer_fields = layer_config.meta_display.map((i) => i.clone().set_value({
        config,
        layer,
        check: layer.latest_check, // * To be used in check tables and layer overview
        fruit: null, // * Fruit list is not available on overview screen
        children: null, // * children is only available for layer index your are viewing
        form_options: config.form_options // * used to translate form values to labels
    }));

    // * top left box of one art report per pallet
    const layer_fields = data.children.map((child) => {
        return pallet_meta_fields.map((i) => i.clone().set_value({
            config,
            layer: child,
            check: child.latest_check, // * To be used in check tables and layer overview
            fruit: null,
            children: null,
            form_options: config.form_options // * used to translate form values to labels
        }));
    });

    return (
        <>
            <PDFDocument onRender={onRender} title={`${(pdf_config.title || "Advance ripening trial report")} ${layer?.label}`} >
                <PDFPage logo={logo_name} header_title={pdf_config.title || "Advance ripening trial report"}>
                    <View style={styles.row} wrap={false}>
                        <View style={styles.cell1}>
                            <PDFLayerMeta layer={layer} fields={parent_layer_fields} status={general_status} />
                        </View>
                        <View style={styles.cell2}>
                            <PDFPalletsSummary
                                fields={children_check_summary_meta_fields}
                                printChildren={data.children}
                                config={config}
                            />
                        </View>
                    </View>
                </PDFPage>
                {layer_fields && data.checks.filter((array) => array.length > 0).map((child, index) => [
                    <PDFPallets
                        key={index}
                        fields={children_table_fields}
                        printChildren={child}
                        config={config}
                        layer_fields={layer_fields[index]} />,
                    <PDFImages
                        key={index + 0.2}
                        images={child.flatMap((a) => a.all_images)}
                    />
                ])}
            </PDFDocument>
        </>
    );
};
