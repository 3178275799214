/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactElement } from "react";
import {
    Col, Row
} from "reactstrap";
import { LAYER_TABS } from "../../../actions/Layers/constants";
import { Tab } from "../../../actions/Tenants/config/constantsTyped";
import mq from "../../../constants/mediaqueries";
import { MailboxTab } from "../Mailbox/Mailbox";
import { PackingListTab } from "../PackingList/PackingListList";
import { useSwitchTabContext } from "./fields/useSwitchTab";
import LayerList from "./LayerList";
import TabActions from "./TabActions";

export const DataTestId = {
    TAB: "TAB",
    TAB_CONTENT: "TAB_CONTENT",
};

function LayerListTab() {
    return <>
        <TabActions />
        <LayerList />
    </>;
}

export const TabComponents = {
    [LAYER_TABS.PACKING_LIST]: PackingListTab,
    [LAYER_TABS.QC_MAILBOX]: MailboxTab,
};


export default function StagesTabs() {
    const { sameCategoryTabs, switchTab, activeTab } = useSwitchTabContext();

    const tabStyle = (tab) => {
        const styles = css`text-align:center;background-color:#e5e8e5;`;
        const responsiveStyles = mq({
            minWidth: ["49%", "48%", "48%", "130px"],
            borderRadius: ["8px", "8px", "8px", "8px 8px 0px 0px"],
            borderBottom: ["0px", "0px", "0px", "2px solid #eee"],
        });
        if (tab.value === activeTab?.value) {
            const activeStyle = css`font-weight: 900; background-color: #fff;`;
            const activeResponsiveStyle = mq({
                borderBottom: ["0px", "0px", "0px", "2px solid #fff"],
            });
            return [styles, responsiveStyles, activeResponsiveStyle, activeStyle];
        }
        const inActiveStyle = css`cursor:pointer`;
        return [styles, responsiveStyles, inActiveStyle];
    };

    const getContent = () => {
        const CustomComponent = TabComponents[activeTab?.value || "yolo ts"];
        if (!CustomComponent) {
            return <LayerListTab />;
        }

        return <CustomComponent />;
    };

    return (
        <div className="pb-md-5 pb-3">
            <Row className="justify-content-center py-3">
                <Col lg="12" >
                    <div className="py-md-3">
                        <div className="d-flex flex-wrap">
                            {sameCategoryTabs.map((tab, index) => (
                                <div
                                    key={index}
                                    className="px-3 py-3 mb-3 me-0 me-lg-2  mb-lg-0"
                                    css={tabStyle(tab)}
                                    onClick={() => switchTab(tab)}
                                    data-testid={`${DataTestId.TAB}${index}`}
                                >
                                    <h6
                                        className="mb-0 text-no-wrap fw-bold"
                                        css={css`opacity: ${tab.value === activeTab?.value ? 1 : 0.5};`}
                                    >
                                        {tab.title} <Count tab={tab} />
                                    </h6>

                                    {tab.subtitle && <p className="text-muted py-10">{tab.subtitle}</p>}
                                </div>
                            ))}
                        </div>

                        <div className="bg-white px-3 py-3" css={css`border-radius: 0px 8px 8px 8px;`} >
                            {getContent()}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}


function Count({ tab }: { tab: Tab }): ReactElement<any, any> {
    return <span className="opacity-60 ms-2">{tab.count || "-"}</span>;
}
