/** @jsxImportSource @emotion/react */
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";

import { useCallback, useEffect, useState } from "react";
import { getCheck } from "../../actions/Checks/actions";
import { createDeviceEvent, pollCheckDevice } from "../../actions/Devices/actions";
import { DeviceEventType } from "../../actions/Devices/constants";
import useCheckFlowNavigate from "../../components/scenes/AddItem/useCheckFlowNavigate";

export function TriggerDeviceButton() {
    const dispatch = useDispatch();
    const queue = useSelector((state) => state.devices.queue);
    const selected_mini = useSelector((state) => state.devices.selected_mini);
    const check = useSelector((state) => state.checks.current);
    const layer = useSelector((state) => state.layers.current);

    // Assumption...if there is a TRIGGER event queued then the device is busy
    const busy = queue.some((i) => i.command === DeviceEventType.TRIGGER);

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    // We check if we have a valid token before triggering mini, we need a valid token to submit the fruit to the backend
    const triggerDevice = () => {
        if (isButtonDisabled) {
            return; // Do nothing if the button is disabled
        }

        setIsButtonDisabled(true); // Disable the button
        dispatch(createDeviceEvent({
            check_test_id: check.test_id,
            device_id: selected_mini.UID,
            command: DeviceEventType.TRIGGER,
        }));

        setTimeout(() => {
            setIsButtonDisabled(false); // Re-enable the button after 1.2 seconds
        }, 1200);
    };

    return (
        <div>
            <Button
                disabled={busy || !selected_mini.UID || isButtonDisabled}
                className="text-nowrap"
                color="success"
                onClick={() => triggerDevice()}
            >
                Scan {layer.fruit_type}
            </Button>
        </div>
    );
}


const allElementsInList = (new_list, original_list) => !new_list.some((x) => !original_list.some((y) => y === x));

// * Hook that enables effects that are listening for events queue to fetch new fruit
// * Should be used only once per View and should be scoped to a check id
export function listenForTriggerDeviceEvents(action) {
    const dispatch = useDispatch();
    const check = useSelector((state) => state.checks.current);
    const selected_mini = useSelector((state) => state.devices.selected_mini);
    const processed = useSelector((state) => state.devices.processed);
    const { navigateToCheckAction } = useCheckFlowNavigate();


    // * This will update `processed` in the device state
    // * will contain succelfull triggers from last 3 minutes
    // * This way we ensure we also fetch older objects in case of temporary internet loss
    const pollCheckDeviceAndRefreshIfNeeded = useCallback(() => {
        if (!selected_mini) {
            return;
        }
        dispatch(pollCheckDevice(selected_mini.UID, check.test_id));
    }, [selected_mini?.UID, check.test_id]);

    useEffect(() => {
        pollCheckDeviceAndRefreshIfNeeded();
        const interval = setInterval(pollCheckDeviceAndRefreshIfNeeded, 750);
        return () => clearInterval(interval);
    }, [pollCheckDeviceAndRefreshIfNeeded]);

    // * I use another effect here so that we can use the most up to date checks.current.avocados state
    // * This effect will compare the current list of avocados against the processed list.
    // * if there are processed avocados not yet in the check object then we need to fetch new data
    useEffect(() => {

        const succeeded_avocado_ids = processed.filter((i) => i.data.check_test_id === check.test_id && i.command === DeviceEventType.TRIGGER)
            .map((i) => i.data.avocado_id);


        const AvocadoNotPresent = !allElementsInList(succeeded_avocado_ids, check.avocados.map((i) => i.avocado_id));

        // * There are some new fruits, not yet present in the DOM
        // * So lets refresh the check object (fetch the fruit object)
        if (AvocadoNotPresent) {
            dispatch(getCheck(check.test_id, false)).then((response) => {
                if (!response.error) {
                    const { avocados } = response.payload.data;
                    const last_fruit = avocados[avocados.length - 1];
                    // last_fruit can be undefined if the first fruit is invalid
                    if (last_fruit) {
                        navigateToCheckAction(`${action}/${last_fruit.avocado_id}`);
                    }
                }
            });
        }

    }, [processed, check.test_id, action]);


    return check;

}
