/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
    getLayer,
    getLayerChecks,
    getLayerChildren, getLayerLocationHistory,
    resetLayerState
} from "../../../../actions/Layers/actions";
import { LAYER_TYPE, VIEW } from "../../../../actions/Layers/constants";
import { addBrowseHistory } from "../../../../actions/Navigation/actions";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { AvosAction } from "../../../../actions/types";
import { isFilledArray } from "../../../../utils";
import { Loading } from "../../../Helper/Loading";
import SidebarPageLayout from "../../Layout/SidebarPageLayout";
import ArrivalCheck from "./ArrivalCheck";
import BoxWeightCheck from "./BoxWeightCheck";
import FirmnessGraph from "./FirmnessGraph";
import LabCheck from "./LabCheck";
import LayerBreadcrumbs from "./LayerBreadcrumbs";
import LayerChecks from "./LayerChecks";
import LayerLocationHistory from "./LayerLocationHistory";
import { LayerMeta } from "./LayerMeta";
import LayerMetricCardsGroup from "./LayerMetricCardsGroup";
import LayerNotes from "./LayerNotes";
import LayerParentsChildren from "./LayerParentsChildren";
import LayerReportsView from "./LayerReportsView";
import { SpecialActionComponents } from "./SpecialActions";
import StartCheckForm from "./StartCheckForm";
import SubLayers from "./SubLayers";

const contentWrapper = css`
    gap: 3rem;

    > * {
        border-top: 1px solid #f4f9ff;
        padding-top: 3rem;
    }

    > *:empty {
        display: none;
    }
`;

export default function LayerIndex() {
    const params = useParams();
    const layer = useSelector((state: any) => state.layers.current);
    const isLoading = useSelector((state: any) => state.layers.isLoading);
    // const browse_history = useSelector((state: any) => state.navigation.browse_history);
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const config = useConfig();
    const layer_config = config.get_layer_config(layer);
    const checkLocations = config.get_locations(layer);
    // const level = browse_history[browse_history.length - 1]?.level;
    // const targetLevel = level - 10; // 10 cuz position of layer_type are 10, 20, 30, 40, 50....
    // const lastBrowse = browse_history.find((item) => item.level === targetLevel);
    // const previousPath = lastBrowse?.pathname || `/layer/tab/${LAYER_TAB_CATEGORIES.OPERATION}/first`;

    useEffect(() => {
        dispatch(resetLayerState());

        if (params.layer_id) {
            dispatch(getLayer(params.layer_id) as AvosAction);
        }

        dispatch(getLayerLocationHistory(params.layer_id) as any);
    }, [params.layer_id, location]);

    // * Get underlying checks if we need them
    // * Checks are used in calender view
    // * Checks are used to get set latest check in LayerMeta (bad practice, use copy_check_meta instead)
    // * Checks are used in Sublayers (Children table) to set latest check (bad practice, use copy_check_meta instead)

    // * By default load the checks done on the layer itself
    let view_strategy: any = VIEW.SELF;
    // * CHILDREN will also load checks done on the layer itself (the parent)
    if (layer_config?.show_children) {
        view_strategy = VIEW.CHILDREN;
    } else if (layer_config?.show_checks?.view) {
        // * Make sure only CHILDREN and SELF are allowed
        view_strategy = layer_config?.show_checks?.view === view_strategy.CHILDREN ? VIEW.CHILDREN : VIEW.SELF;
    } else if (layer.type === LAYER_TYPE.PURCHASE_ORDER) {
        view_strategy = VIEW.CHILDREN;
    }

    useEffect(() => {
        const request = { view_strategy, with_meta: true };

        dispatch(getLayerChecks(params.layer_id, request) as any);
    }, [params.layer_id, view_strategy]);

    // Show children if we need to display them
    useEffect(() => {
        // TODO: Readout meta_display and show_children.fields array to fetch a subset of fields
        dispatch(getLayerChildren(params.layer_id) as any);
    }, [params.layer_id]);

    useEffect(() => {
        if (layer?.id !== params.layer_id || !layer_config?.text || !location?.pathname) {
            return;
        }

        const layerType = config.get_layer_config(layer);

        dispatch(
            addBrowseHistory({
                pathname: location.pathname,
                id: layer.id,
                label: layer.label,
                text: layer_config.text,
                level: layerType?.position,
            })
        );
    }, [layer?.id, layer_config?.text, location?.pathname]);

    // if we have navigation problems I'll use this again
    // const onClose = () => {
    //     navigate(previousPath);
    // };

    const startCheckButons = [
        ...(layer_config?.special_actions || []).map((i, index) => ({
            position: i.position || (index + 1) * 10,
            el: (
                <div key={index} className="d-inline-flex d-lg-block">
                    {SpecialActionComponents[i.type](i)}
                </div>
            ),
        })),
        ...(checkLocations || []).filter((i) => !i.is_hidden).map((i, index) => ({
            position: i.position,
            el: <div key={index + 0.5} className="d-inline-flex d-lg-block">
                <StartCheckForm location_config={i} />
            </div>
        }))
    ].sort((a, b) => a.position - b.position).map((i) => i.el);

    const content = useMemo(() => {
        if (layer_config) {
            const elements = [
                isFilledArray(layer_config.show_metric_cards) && <LayerMetricCardsGroup />,
                layer_config.show_checks && <LayerChecks subconfig={layer_config.show_checks} />,
                layer_config.show_box_weight_check && <BoxWeightCheck title={layer_config.show_box_weight_check?.title} />,
                layer_config.show_arrival_check && <ArrivalCheck subconfig={layer_config.show_arrival_check?.fields} title={layer_config.show_arrival_check?.title} />,
                layer_config?.show_advance_ripening && <SubLayers subconfig={layer_config?.show_advance_ripening} show_art={true} />,
                layer_config?.show_ripening_progress && <FirmnessGraph title={layer_config?.show_ripening_progress.title} subconfig={layer_config?.show_ripening_progress} />,
                layer_config?.show_children && <SubLayers subconfig={layer_config?.show_children} />,
                layer_config.show_lab_check && <LabCheck title={layer_config.show_lab_check?.title} />,
                layer_config?.show_general_notes && <LayerNotes />,
            ].filter((el) => !!el);

            return elements.map((el, index) => (
                <div className="w-100" key={index}>
                    {el}
                </div>
            ));
        }

        return null;
    }, [layer_config]);

    // Do not unmount the whole page when calling getLayer after an update
    if (isLoading && String(layer.id) !== params.layer_id) {
        return <Loading />;
    }

    if (!layer_config) {
        return null;
    }
    // TODO: put start check button in bottomBar
    return (
        <SidebarPageLayout
            title={`${layer_config?.text} ${layer.label}`}
            breadcrumbs={<LayerBreadcrumbs />}
            sidebar={
                <>
                    <LayerMeta />
                    {layer_config?.show_upload_reports_button && LAYER_TYPE.PURCHASE_ORDER && <LayerReportsView layer={layer} />}
                    <LayerParentsChildren />
                    <LayerLocationHistory />
                </>
            }
        >
            <div className="pb-4 d-flex justify-content-end align-items-center" css={css`gap: 0.5rem; flex-wrap: wrap;`}>
                {startCheckButons}
            </div>

            <div className="w-100 d-flex flex-column" css={contentWrapper}>
                {content}
            </div>
        </SidebarPageLayout>
    );
}
