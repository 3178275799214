/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { css } from "@emotion/react";
import { useNavigate, useParams } from "react-router-dom";
import { UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { AdminPageRouter, adminMenu } from "./Router";
import FullPageLayout from "../Layout/FullPageLayout";

export const AdminAccordionBody = ({ children, accordionId }) => <AccordionBody accordionId={accordionId}
    css={css`& .accordion-body {
        padding: 0 !important;
    }`}>
    {children} </AccordionBody>;


export default function AdminPage() {
    const navigate = useNavigate();
    const [selectedPath, setSelectedPath] = useState(useParams()["*"]);
    const [open, setOpen] = useState(selectedPath?.split("/")[0] || "");
    const toggle = (id) => {
        if (open === id) {
            setOpen("");
        } else {
            setOpen(id);
        }
    };

    const onMenuItemClick = (path) => {
        setSelectedPath(path);
        navigate(`/admin/${path}`);
    };

    return (
        <FullPageLayout>
            <Row className="mt-3 mt-lg-5">
                <Col className="col-sm-2">
                    <UncontrolledAccordion toggle={toggle} defaultOpen={[open]} stayOpen>
                        {
                            adminMenu.map((item, index) => {
                                return <AccordionItem key={index}>
                                    <AccordionHeader targetId={item.path}>{item.title}</AccordionHeader>
                                    <AdminAccordionBody accordionId={item.path}>
                                        <ListGroup>
                                            {
                                                item.children?.map((subItem, subIndex) => {
                                                    const path = `${item.path}/${subItem.path}`;
                                                    return <ListGroupItem
                                                        key={subIndex}
                                                        active={selectedPath === path}
                                                        action
                                                        tag="button"
                                                        onClick={() => onMenuItemClick(path)}
                                                    >
                                                        {subItem.title}
                                                    </ListGroupItem>;
                                                })
                                            }
                                        </ListGroup>
                                    </AdminAccordionBody>
                                </AccordionItem>;
                            })
                        }
                    </UncontrolledAccordion>
                </Col>
                <Col className="col-sm-10">
                    <AdminPageRouter />
                </Col>
            </Row>
        </FullPageLayout>
    );
}
