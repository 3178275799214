/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { css } from "@emotion/react";
import { PDFViewer } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { AnyAction } from "redux";
import { getLayer, getLayerChildren, resetLayerState } from "../../../../../actions/Layers/actions";
import useConfig, { useTenantFormOptions } from "../../../../../actions/Tenants/config/configHook";
import { device_image_types } from "../../../AddItem/components/Images";
import { getFruitTableData } from "../../../AddItem/components/Summary/FruitTable";
import { PDFCheckFruitTables } from "./PDFCheckFruitTables";
import { PDFImages } from "./PDFImages";
import { PDFDocument } from "./PDFLayout";

import { getCheck } from "../../../../../actions/Checks/actions";
import { path_summary } from "../../../../../actions/Tenants/config/constants";
import { getFilledArrayOrDefault } from "../../../../../utils";
import useAuthorization from "../../../../../utils/authorization";
import { fetchBlob } from "../../../../../utils/base64";
import { useDefectsHook } from "../../../../Forms/useManagedOptionsHook";
import { Loading } from "../../../../Helper/Loading";
import LoadingProgressBar from "../../../../Helper/LoadingProgressBar";
import useCheckFlowNavigate from "../../../AddItem/useCheckFlowNavigate";
import webpToJpgBase64 from "../../../image-utils";
import FullPageLayout from "../../../Layout/FullPageLayout";
import { SendReportButton } from "../SendReportButton";
import { PDFCountDownText } from "./index";
import QCStatusButton from "./QCStatusButton";

export default function PDFCheckReport() {
    const config = useConfig();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.auth.user);
    const layer = useSelector((state: any) => state.layers.current);
    const children = useSelector((state: any) => state.layers.children);
    const tenantsIsLoading = useSelector((state: any) => state.tenants.isLoading);
    const form_options = useTenantFormOptions();
    const check = useSelector((state: any) => state.checks.current);
    const [printCheck, setPrintCheck] = useState<any>();
    const { getPrevRoute } = useCheckFlowNavigate();

    const params = useParams();
    const layer_config = config.get_layer_config(layer);

    useEffect(() => {
        dispatch(resetLayerState());
        dispatch(getLayer(params.layer_id!) as unknown as AnyAction);
        dispatch(getLayerChildren(params.layer_id) as any);
    }, [params.layer_id]);

    useEffect(() => {
        dispatch(getCheck(params.test_id) as any);
    }, [params.test_id]);

    // when you navigate from a parent layer to a child layer check summary...
    // we need to give it some time to reinstate the config (e.g. when the parent doesn't have a location)
    const location = config.get_location(layer, check);
    const all_defects = useDefectsHook(layer.fruit_type);

    useEffect(() => {
        if (!layer || !check || (all_defects?.length || 0) === 0 || String(check?.test_id) !== params.test_id) return;

        const fruitTableData = getFruitTableData({ layer, check, fruits: check.avocados, config, user, all_defects });

        const preloadImages = async () => {
            let all_images = [
                ...(check.images ? check.images.map((x) => ({ ...x, label: check.label })) : []),
                ...(check.avocados
                    ? check.avocados
                        .flatMap((a) => a.images.filter((x) => !device_image_types.includes(x.type)))
                        .filter((x) => x.url)
                        .map((x) => ({ ...x, label: check.label }))
                    : []),
            ];
            all_images = await Promise.all(
                all_images.map(async (image) => ({
                    ...image,
                    url: await webpToJpgBase64(image.url),
                }))
            );
            setPrintCheck({ ...check, label: layer.label, table_data: fruitTableData, all_images });
        };

        preloadImages();
    }, [layer, check, all_defects, params.test_id]);

    const [pdfBlob, setPdfBlob] = useState<Blob>();
    const onRender = async ({ blob }) => {
        if (!pdfBlob || pdfBlob.size !== blob.size) {
            setPdfBlob(blob);
        }
    };

    const intake_report_config = config.get_pdf_report(layer, "intake");
    const report_config_value = location?.value;
    // * If you want to enable the SendReportButton a pdf config with the same value as check.location should be set
    const report_config = config.get_pdf_report(layer, report_config_value) || {};

    const draft_report_key = `report_eye_${report_config_value}_generated`;
    const draft_report = layer?.[draft_report_key];

    const auth = useAuthorization();
    const canSendReport = report_config?.show_send_email_button || auth.userBelongsToOneOfTeams(report_config?.teams_that_can_send);


    useEffect(() => {
        // * fetch the blob if the draft report is set to send the active draft report
        const fetchAndSetBlob = async () => {
            try {
                const blob = await fetchBlob(draft_report);
                setPdfBlob(blob);
            } catch (error) {
                console.error("Error fetching the blob:", error); // eslint-disable-line no-console
                setPdfBlob(undefined);
            }
        };

        if (draft_report) fetchAndSetBlob();
    }, [draft_report]);


    if (!layer || !check || !printCheck || !location || !layer_config) {
        return <Loading />;
    }

    const title = report_config?.title || "Check report";

    const PrintReport = ({ onRender }) => {
        const logo_name = report_config?.logo_name || intake_report_config?.logo_name; // Use intake logo if not set
        const layer_fields = getFilledArrayOrDefault(location.summary_layer_fields_pdf).map((i) => i.set_value({
            config,
            layer,
            check, // * To be used in check tables and layer overview
            fruit: null, // * Fruit list is not available on overview screen
            children, // * children is only available for layer index your are viewing
            form_options, // * used to translate form values to labels
        }));
        return (
            <>
                <PDFDocument onRender={onRender} title={`${title} ${layer?.label}`}>
                    <PDFCheckFruitTables
                        header={printCheck.table_data[0]}
                        rows={printCheck.table_data[1]}
                        footer={printCheck.table_data[2]}
                        fields={layer_fields}
                        all_defects={all_defects}
                        defect_groups={location.defect_groups}
                        check={printCheck}
                        logo_name={logo_name}
                        header_title={title}
                    />

                    <PDFImages images={printCheck.all_images} />
                </PDFDocument>
            </>
        );
    };

    const isLoading: boolean = !printCheck || tenantsIsLoading;

    return (
        <FullPageLayout title={`${layer_config.text} ${layer.label}`}>
            <div className="pb-2 pb-sm-0 d-flex align-items-center ms-auto" css={css`gap: 0.5rem;`}>
                <QCStatusButton
                    pdf_config={report_config}
                    pdfBlob={pdfBlob}
                    config_value={report_config_value}
                    draft_report_key={draft_report_key}
                    edit_url={getPrevRoute(`/layer/${layer.id}/add-check/${check.test_id}/${path_summary}`)} // * always go back to check summary
                    back_url={getPrevRoute(`/layer/${layer.id}/add-check/${check.test_id}/${path_summary}`)} // * always go back to check summary
                />

                {canSendReport && <SendReportButton layerId={layer.id} blob={pdfBlob} config_value={report_config_value} status={check.satus} />}
            </div>

            {isLoading ? (<LoadingProgressBar text={PDFCountDownText.slice(0, 4)} />
            ) : (
                <div className="max-w-100">
                    {!pdfBlob && !draft_report && <LoadingProgressBar text={PDFCountDownText} startIndex={4} />}


                    {!draft_report && <div css={css`margin: auto; max-widht: 100%;`} >
                        <PDFViewer style={{ width: "100%", height: "1200px", opacity: pdfBlob ? 1 : 0 }}>
                            <PrintReport onRender={onRender} />
                        </PDFViewer>
                    </div>
                    }
                </div>
            )}
        </FullPageLayout>
    );
}
