/* eslint-disable no-case-declarations */
import { LIST, SELECT } from "./actionTypes";
import { Entity } from "../../Admin/reducer";
import { AvosAction } from "../../types";


export interface Tenant extends Entity {
    id: string;
    label: string;
    supplier_id: string;
    supplier_name: string;
    config?: any
}

export type TenantState = {
    items: Tenant[];
    isLoading: boolean;
    selected: Tenant | undefined;
}

const initialState : TenantState = {
    items: [],
    isLoading: false,
    selected: undefined,
};

export default ((state: TenantState = initialState, action: AvosAction): TenantState => {
    switch (action.type) {
    case LIST.LOADING:
        return {
            ...state,
            isLoading: true,
        };
    case LIST.SUCCESS:
        return {
            ...state,
            items: action.payload.data as any,
            isLoading: false,
        };
    case LIST.ERROR:
        return {
            ...state,
            isLoading: false,
        };
    case SELECT.SUCCESS:
        return {
            ...state,
            selected: action.payload.data as any,
        };
    default:
        return state;
    }
});
