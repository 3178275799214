/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import logo from "../../../img/logo-experience-fruit-quality.png";

const Logo = () => {
    return (
        <Link to="/" >
            <img src={logo} alt="" css={css`height: 48px; padding-bottom: 8px;`} />
        </Link>
    );
};

export default Logo;
