import {
    baseConfig,
    default_location_config, headerLinks
} from "../constants";

import { RootConfigType } from "../constantsTyped";

export const ExperienceDataConfig: RootConfigType = {
    ...baseConfig,
    is_admin: true,
    strict_check_size: false,
    indicate_soft_avocado_at_internal: false,
    indicate_quality_at_defects: true,
    suggest_color_stage: true,
    show_device_toggle: true,
    default_location_config: {
        ...default_location_config,
        show_atron_weight: true
    },
    headerSecondaryLinks: [headerLinks.labelling, headerLinks.packinglist, headerLinks.deviceManagement, headerLinks.tenants, headerLinks.settings, headerLinks.logout],
    headerMainLinks: [headerLinks.home, headerLinks.packinglist],
};
