/** @jsxImportSource @emotion/react */
import { faCaretDown, faCaretUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { useParams } from "react-router-dom";
import { LAYER_TAB_CATEGORIES } from "../../../../actions/Layers/constants";
import { CONFIG_TYPES } from "../../../../actions/Tenants/config/constants";
import { getFilledArrayOrDefault } from "../../../../utils";
import useConfig from "../../../../actions/Tenants/config/configHook";

// Preview display for configs
const preview = (item) => (
    <div className="mb-0 fw-bold">
        {item.config?.text}

        {item.type === CONFIG_TYPES.STAGE ? (
            <>
                [{getFilledArrayOrDefault(item.config?.tab_categories, [LAYER_TAB_CATEGORIES.OPERATION]).join(" | ")} <b>::</b>{" "}
                {item.value}]
            </>
        ) : (
            <>[{item.value}]</>
        )}
    </div>
);

export const ItemContent = ({
    item,
    isFirstItem,
    isLastItem,
    onDeactivate,
    onUpdatePosition,
    index,
}: {
  item: any;
  isFirstItem: boolean;
  isLastItem: boolean;
  onDeactivate(item: any): void;
  index: number;
  onUpdatePosition(from, to): void;
}) => {
    const config = useConfig();
    const params = useParams();
    const { tenant_id } = params;

    const positionUp = () => {
        // * cannot move first item up, duh
        if (index === 0) return;

        onUpdatePosition(index, index - 1);
    };

    const positionDown = () => {
        if (index + 1 === config.configs.length) return;

        onUpdatePosition(index, index + 1);
    };

    return (
        <div className="d-flex align-items-center">
            {preview(item)}

            <Button
                size="sm"
                color="light"
                className="ms-auto my-1"
                disabled={isFirstItem}
                onClick={positionUp}
            >
                <FontAwesomeIcon icon={faCaretUp} />
            </Button>

            <Button
                size="sm"
                color="light"
                className="ms-2 my-1"
                disabled={isLastItem}
                onClick={positionDown}
            >
                <FontAwesomeIcon icon={faCaretDown} />
            </Button>

            <Button color="danger" className="ms-2 my-1" outline size="sm" onClick={() => onDeactivate(item)}>
                Deactivate
            </Button>

            <Button color="light" className="ms-2 my-1" size="sm" href={`/tenants/${tenant_id}/config/${item.id}`}>
                Edit
            </Button>
        </div>
    );
};
