/** @jsxImportSource @emotion/react */
import { FIELD } from "../../../../actions/Layers/fields";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { layerFormFields } from "../../../../actions/Tenants/config/layerFormFields";
import { getFilledArrayOrDefault } from "../../../../utils";
import MetaForm from "../../../Forms/MetaForm";
import { FORMATTERS } from "../../Layers/fields/formatters";
import CategoryConfigForm from "./CategoryConfigForm";
import { flagMethods } from "./ColorCodeMetricForm";
import FilterableConfigList from "./FilterableConfigList";
import { yesOrNoRadio } from "./util";

const defaultField = {
    field: {
        field_id: null,
        fieldname_getter: "",
        label: "",
        value_if_null: "-",
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    }
};

// const formatOptionLabel = (props) => {
//     const { label, fieldname_getter, field_type, append_key } = props;
//     if (!label) {
//         return <span className="text-muted">Select field type</span>;
//     }
//     return (
//         <div className="py-1">
//             <div><b>{label}</b></div>
//             <div>
//                 <Badge size="sm" className="me-1">{field_type}</Badge>
//                 {append_key && <Badge size="sm" className="me-1">{append_key}</Badge>}
//                 <Badge size="sm" className="me-1"> [ {fieldname_getter} ] </Badge>
//             </div>
//         </div>
//     );
// };

const fieldFormHook = (config) => {

    const color_codes = config.color_codes || [];

    const metricMetaKeys = color_codes.flatMap((i) => (i.metrics || []).flatMap((j) => [
        j.metric_meta_key && {
            label: `(Metric) ${j.metric_meta_key}`,
            value: j.metric_meta_key,
            type: "metric",
        },
        (j.metric_meta_key && j.relative_absolute === "relative")
        && {
            label: `(Metric) ${j.metric_meta_key}_relative`,
            type: "metric",
            value: `${j.metric_meta_key}_relative`,
        },
        (j.flag_method === flagMethods.flag_check__check_field && j.relative_absolute === "relative")
        && {
            label: `(Metric) ${j.field}_relative`,
            type: "metric",
            value: `${j.field}_relative`,
        },
        j.flag_name && {
            label: `(Flag) ${j.flag_name}`,
            type: "flag",
            value: j.flag_name,
        },
        j.category_name && {
            label: `(Category) ${j.category_name}`,
            type: "category",
            value: j.category_name,
        }
    ])).filter((i) => i);

    const copiedFromCheckFieldNames = color_codes.flatMap((i) => (i.copy_check_to_layers_meta || []).filter((i) => i.field).map((i) => i.field).map((i) => ({
        label: `(Copied from Check) ${i.name}`,
        type: "copied_check",
        value: i.name,
    })));

    const copiedFromRoomConditionsFieldNames = getFilledArrayOrDefault(config.root_config?.ripening_room_conditions_form).filter((i) => i.field).map((i) => i.field).map((i) => ({
        label: `(Copied from room conditions) ${i.name}`,
        type: "copied_room_condition",
        value: i.name,
    }));

    const copiedFromRoomMetaFieldNames = getFilledArrayOrDefault(config.root_config?.ripening_room_meta_form).filter((i) => i.field).map((i) => i.field).map((i) => ({
        label: `(Copied from room meta) ${i.name}`,
        type: "copied_room_meta",
        value: i.name,
    }));

    const checkLocations = (config.check_locations || []).map((i) => ({ label: `${i.text} [${i.value}]`, value: i.value }));

    const getForm = (object, setObject) => [
        {
            label: "field_id",
            name: "field_id",
            description: "Which field do you want to use as a template?",
            type: "single-select",
            // formatOptionLabel,
            onchange_callback: ({ field, value }) => {
                const the_field = FIELD[value];
                if (the_field) {
                    setObject({ ...object, [field]: value, label: the_field.label, fieldname_getter: the_field.fieldname_getter });
                } else {
                    setObject({ ...object, [field]: value });
                }
            },
            options: Object.keys(FIELD).map((i) => ({ value: i, label: i })),
        },
        {
            label: "fieldname_getter",
            name: "fieldname_getter_common_field",
            description: "Common platform fields, if your field is not in the list then ask a developer to add it.",
            type: "single-select",
            options: [...layerFormFields.map((i) => ({ value: i.name, label: `(layer) ${i.label}` })), ...metricMetaKeys, ...copiedFromCheckFieldNames, ...copiedFromRoomConditionsFieldNames, ...copiedFromRoomMetaFieldNames],
            onchange_callback: ({ field, value }) => {
                const fieldname_getter = (object.fieldname_getter_location_prefix ? `${object.fieldname_getter_location_prefix}_` : "") + value;
                setObject({ ...object, [field]: value, fieldname_getter });
            }
        },
        {
            label: "Location prefix",
            name: "fieldname_getter_location_prefix",
            description: "When reading a metric or check data from layer meta, you can read from a specific location or latest registered.",
            type: "single-select",
            options: [{ label: "Latest", value: null }, ...checkLocations],
            onchange_callback: ({ field, value }) => {
                const fieldname_getter = (value ? `${value}_` : "") + object.fieldname_getter_common_field;
                setObject({ ...object, [field]: value, fieldname_getter });
            },
        },
        {
            label: "fieldname_getter",
            name: "fieldname_getter",
            description: "From which key should this field try to read data? This is a composite of the settings above, but you can adjust it here to your likings.",
            type: "text",
        },
        {
            label: "Label",
            name: "label",
            description: "What is the label of this field?",
            type: "text",

        },
        {
            label: "Options name",
            name: "options_name",
            type: "text",
            description: `Fill this in if this field has managed options that are not managed under ${object?.fieldname_getter}. leave blank to use the same as ${object?.fieldname_getter}`,
        },
        {
            label: "Apply formatter",
            name: "formatter",
            description: "Apply a formatter to this field? Will also defined how this field is filterd.",
            type: "single-select",
            options: Object.keys(FORMATTERS).map((i) => ({ value: i, label: i })),
        },
        object?.formatter && {
            label: "unity",
            type: "text",
            description: "Unity for this input, e.g. KG, LBS or %.",
            name: "unity",
        },
        object?.formatter && {
            label: "Decimals",
            type: "number",
            description: "How many decimals should this field have? Use in combination with formatter.",
            name: "decimals",
        },
        object?.formatter && {
            label: "Value if null",
            type: "text",
            description: "What should be displayed if the value is null?",
            name: "value_if_null",
        },
        object?.formatter && {
            label: "Overwrite datetime format",
            type: "text",
            name: "datetime_format",
            description: "How should the date be formatted? Leave blank to use `DD-MM-YYYY` for dates and `HH:mm DD-MM-YYYY` for datetimes.",
        },
        // TODO: add support for category colors here
        {
            label: "Flag name to color code",
            name: "base_color_code_field",
            type: "single-select",
            description: "Which field should be used to color code this field?",
            options: metricMetaKeys.filter((i) => i.type === "flag"),
            onchange_callback: ({ field, value }) => {
                const color_code_field = (object.color_code_field_location_prefix ? `${object.color_code_field_location_prefix}_` : "") + value;
                setObject({ ...object, [field]: value, color_code_field });
            },

        },
        {
            label: "Location prefix",
            name: "color_code_field_location_prefix",
            description: `When reading a flag to use for color code, you can read from a specific location or latest registered. ${object?.color_code_field ? `Will read from '${object.color_code_field}'` : ""}`,
            type: "single-select",
            options: [{ label: "Latest", value: null }, ...checkLocations],
            onchange_callback: ({ field, value }) => {
                const color_code_field = (value ? `${value}_` : "") + object.base_color_code_field;
                setObject({ ...object, [field]: value, color_code_field });
            },

        },
        {
            label: "Exclude from download",
            name: "excluded_from_download",
            description: "Exclude this field from download?",
            ...yesOrNoRadio
        },
        {
            label: "Reverse Ripening Axis?",
            name: "higher_value_is_more_ripe",
            description: "Is the ripening axis reversed? Default is no. (e.g. Firmness is lower when ripe)",
            type: "radio",
            options: [
                { label: "No, lower value is more ripe (e.g. Firmness)", value: false },
                { label: "Yes, higher value is more ripe (e.g. Maturity)", value: true }
            ]
        },
        {
            type: "element",
            el: <CategoryConfigForm
                fields={object.categories || []}
                description="Categories for this field, used for on the fly color coding and background/legend in ripening graphs."
                name="categories"
                useDisplayFilter={false}
                title="Categories"
                setArray={(categories) => setObject({ ...object, categories })} />
        },
    ].filter((i) => i);
    return getForm;
};


export function SingleFieldForm({ setField, field, title, }) {
    const config = useConfig();
    const getFieldForm = fieldFormHook(config);

    const header = {
        label: title,
        type: "header"
    };

    return <MetaForm
        meta={[header, ...getFieldForm(field, setField)]}
        setValue={(field_name, value) => setField({ ...field, [field_name]: value })}
        object={field}
        config={config}
    />;
}

export default function FieldConfigForm({ name, title, fields, description, setArray, useDisplayFilter = ["fruit_types", "exclude_fruit_types", "layer_types", "manual_or_with_mini"] }) {
    const getFieldTitle = (object) => <span>{object.label} [{object.fieldname_getter}] - {object.field_id}</span>;
    const config = useConfig();
    const getFieldForm = fieldFormHook(config);

    const fields_checked_for_error = fields.map((i) => ({ ...i, error: !FIELD[i.field.field_id] }));
    const has_undefined_field = fields_checked_for_error.some((i) => i.error);
    const error = has_undefined_field ? "Some fields are using deprecated fields or are not properly defined." : false;

    return <FilterableConfigList
        name={name}
        useDisplayFilter={useDisplayFilter}
        title={title}
        description={description}
        fields={fields_checked_for_error}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        error={error}
        getFieldTitle={getFieldTitle} />;

}


