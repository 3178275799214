import { useState } from "react";

interface FileNameProps {
  name: string;
}

const TruncatedFileName: React.FC<FileNameProps> = ({ name }) => {
    const [showFull, setShowFull] = useState(false);

    const toggleShowFull = () => setShowFull((prev) => !prev);

    const truncateFileName = (name: string) => {
        if (name.length <= 14) return name; // If the name is already short, don't truncate
        return `${name.slice(0, 6)}...${name.slice(-8)}`;
    };

    return (
        <span
            onClick={toggleShowFull}
            className="cursor-pointer text-blue-600 hover:underline"
        >
            {showFull ? name : truncateFileName(name)}
        </span>
    );
};

export default TruncatedFileName;
