/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import {
    Col,
    Nav, NavItem, NavLink,
    Row,
} from "reactstrap";

import classnames from "classnames";
import { useEffect } from "react";
import { useParams } from "react-router";
import Select from "react-select";

import { useNavigate } from "react-router-dom";
import { getDevice, updateDevice } from "../../../actions/Devices/actions";
import miniIcon from "../../../img/Mini.png";
import DeviceLastSeen from "../../Devices/DeviceLastSeen";
import { Loading } from "../../Helper/Loading";
import { DeviceAdminCommand, DeviceAdminCommandSections } from "./components/DeviceAdminCommands";
import DeviceEventsHistory from "./components/DeviceEventsHistory";
import { DeviceStatusTable } from "./components/DeviceStatusTable";
import { DeviceUpdateOptionTable } from "./components/DeviceUpdateOptionTable";
import FullPageLayout from "../Layout/FullPageLayout";

const tablist = [
    { name: "Device status logs", key: "status", tab: (props) => <DeviceStatusTable {...props} /> }, // eslint-disable-line react/display-name
    { name: "Power Options", key: "power", tab: (props) => <DeviceAdminCommand type={DeviceAdminCommandSections.POWER} {...props} /> }, // eslint-disable-line react/display-name
    { name: "Update options", key: "update", tab: (props) => <DeviceUpdateOptionTable type={DeviceAdminCommandSections.POWER} {...props} /> }, // eslint-disable-line react/display-name
    { name: "Test device", key: "test", tab: (props) => <DeviceAdminCommand type={DeviceAdminCommandSections.TEST} {...props} /> }, // eslint-disable-line react/display-name
    { name: "Event History", key: "event", tab: (props) => <DeviceEventsHistory {...props} /> }, // eslint-disable-line react/display-name
];


export default function DeviceManagement() {
    const device = useSelector((state) => state.devices.current);

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const params = useParams();
    const activeTab = params.view || tablist[0].key;

    useEffect(() => {
        if (device?.UID !== params.device_uid) {
            dispatch(getDevice(params.device_uid));
        }
    }, [params.device_uid, device?.UID]);


    if (!device || !device.UID || String(device?.UID) !== params.device_uid) {
        return <div css={css`min-height: 100vh;`}><Loading></Loading></div>;
    }

    const joinStatus = [
        { value: "on_hold", label: "On hold" },
        { value: "joining", label: "Joining" },
        { value: "joined", label: "Joined" },
        { value: "declined", label: "Declined" }
    ];
    // TODO implement update system to the backend, like the thing done here above

    return (
        <FullPageLayout>
            <Row className="justify-content-center pb-3 mt-5">
                <Col >
                    <div>
                        <Row className="justify-content-between py-3">
                            <Col xs="6">
                                <img src={miniIcon} alt="Meneer AVOS" className="float-left" />
                                <div className="py-3" >
                                    <h3>{device.name}</h3>
                                    <h6>UUID: {device.UID} </h6>
                                    <h6><DeviceLastSeen device={device} refresh={true} /> </h6>
                                </div>

                            </Col>
                            <Col xs="6" >
                                <div className="d-flex text-start justify-content-end">
                                    <div css={css`width: 20rem;`}>
                                        <Select options={joinStatus} name="action" value={joinStatus.find((x) => x.value === device.joinstatus)} onChange={(joinStatus_item) => dispatch(updateDevice({ ...device, joinstatus: joinStatus_item.value }))} />
                                    </div>
                                    <a className="btn btn-primary ms-2 text-nowrap" href={`https://experiencedata.grafana.net/explore?orgId=1&left=[%22now-1h%22,%22now%22,%22grafanacloud-experiencedata-logs%22,%7B%22expr%22:%22%7Binstance%3D%5C%22${device.name}%5C%22%7D%22%7D]`} >Grafana Logs</a>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center py-3">
                            <Nav tabs>
                                {tablist.map((tab) => <NavItem key={tab.key} >
                                    <NavLink
                                        className={classnames({ active: activeTab === tab.key })}
                                        onClick={() => navigate(`/device-managment/${params.device_uid}/view/${tab.key}`)}>
                                        {tab.name}
                                    </NavLink>
                                </NavItem>)}
                            </Nav>
                        </Row>

                        <Row className="justify-content-center">
                            <Col>
                                {tablist.find((i) => i.key === activeTab).tab()}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </FullPageLayout>
    );
}

