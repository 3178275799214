import { setupFormatter } from "../../../components/scenes/Layers/fields/formatters";
import { FIELD } from "../../Layers/fields";

// * base function to filter based on .display_filter props
// * use this directly to filter check locations and layers (root configs)
export function applyDisplayFilter(fields, filter: object | any = {}) {
    if (!fields) {
        return [];
    }

    // * make sure fields is an array
    if (!Array.isArray(fields)) {
        fields = [fields];
    }

    // * Filter for fruit_types, layer_types and manuar / device check
    return fields.filter((i) => {
        // * first check fruit_type
        if (!i.display_filter || !filter?.fruit_type) {
            return true;
        }

        // * give preference to the including filter
        if ((i.display_filter?.fruit_types || []).length > 0) {
            return i.display_filter.fruit_types.includes(filter.fruit_type);
        }

        if ((i.display_filter?.exclude_fruit_types || []).length > 0) {
            return !i.display_filter.exclude_fruit_types.includes(filter.fruit_type);
        }

        return true;

    }).filter((i) => {
        // * then check layer_type
        if (!i.display_filter || !filter?.layer_type) {
            return true;
        }

        // give preference to the including filter
        if ((i.display_filter?.layer_types || []).length > 0) {
            return i.display_filter.layer_types.includes(filter.layer_type);
        }

        // * not using this yet
        // if ((i.display_filter?.exclude_layer_types || []).length > 0) {
        //     return !i.display_filter.layer_types.includes(filter.layer_type);
        // }

        return true;

    }).filter((i) => {
        // * then check is_manual
        if (!i.display_filter) {
            return true;
        }

        if (i.display_filter?.manual_or_with_mini === "only_on_mini_check" && filter?.is_manual === true) {
            return false;
        }

        if (i.display_filter?.manual_or_with_mini === "only_on_manual_check" && filter?.is_manual === false) {
            return false;
        }

        return true;
    });
}

// * Shortcut to first apply the filtering based on .display_filter props and then return and instansce of FIELD.FIELD
// * use this for where you want to dispaly layer fields
export function applyDisplayFilterLayerFields(fields, filter = {}) {
    return applyDisplayFilterFields(fields, filter).filter((i) => {
        // This never should be the case, but just in case there is an error in the migration script we can use this console log to find out where it the issue comes from
        if (!i?.field_id) {
            console.log(`Error: field_id is undefined?`, i, fields); // eslint-disable-line no-console
            return false; // Remove items with invalid field_id
        }

        if (FIELD[i.field_id]) {
            return true; // Keep items with a valid field_id
        }

        console.log(`Error: Field with id ${i.field_id} does not exist. Trying to show ${i.fieldname_getter}.`); // eslint-disable-line no-console
        return false; // Remove items with invalid field_id
    }).map(setupFieldClassFromConfig);
}

export function setupFieldClassFromConfig(fieldConfig) {
    if (!fieldConfig.field_id || !FIELD[fieldConfig.field_id]) {
        console.log(`Error: Field with id ${fieldConfig.field_id} does not exist. Trying to show ${fieldConfig.fieldname_getter}.`); // eslint-disable-line no-console
        return null;
    }

    const field = FIELD[fieldConfig.field_id]
        .clone() // * Clone the field to avoid sketchy behavior
        .set_label(fieldConfig.label) // * set the label
        .set_fieldname_getter(fieldConfig.fieldname_getter) // * Set the fieldname for reading the value
        .set_color_code_field(fieldConfig.color_code_field || false) // * Set the fieldname for reading a color code from IDP
        .set_excluded_from_download(fieldConfig.excluded_from_download || false); // * Exclude field from download or not

    // * Setup the formatter if it is set
    if (fieldConfig.formatter) {
        const { decimals, unity, value_if_null, datetime_format } = fieldConfig;
        //*  Note that we overwrite any previous setup formatters to avoid funny results
        const formatter = setupFormatter(fieldConfig.formatter, { decimals, unity, value_if_null, datetime_format });

        field.set_option("formatters", [formatter]);
        // * set the filter type for the overview page
        field.set_filter_type_for_formatter(fieldConfig.formatter, { formatter, decimals, unity, value_if_null, datetime_format });
    }

    if (fieldConfig.options_name) {
        field.set_option("options_name", fieldConfig.options_name);
    }

    return field;
}

// * Shortcut to first apply the filtering based on .display_filter props and then return the field
// * use this for flows, fruit table fields and form definitions etc etc
// * if list of objects doesnt use {display_fiter, field} it will just return the list as is (for backward compability)
export function applyDisplayFilterFields(fields, filter = {}) {
    return applyDisplayFilter(fields, filter).map((i) => i.field || i);
}

// * function to filter the configured flags based on the trigger_meta
// * only used for indivudal defect, normally you would run this logic in internal data processing.
export function applyTriggerFilterFlags(flags, layer, check = {}, fruit = {}) {
    const meta_location_object = {
        layer_meta: layer,
        check_meta: check,
        fruit_meta: fruit
    };

    return flags.filter((flag) => {
        if (!flag.trigger_meta || !Array.isArray(flag.trigger_meta)) {
            return true;
        }

        // check if include values are set
        return flag.trigger_meta.every((i) => {
            // * flag not setup correctly
            if (!i.field || !i.meta_location || !meta_location_object[i.meta_location]) {
                return true;
            }

            const value = meta_location_object[i.meta_location][i.field];

            if (i.include_values && i.include_values.length > 0) {
                if (!i.include_values.includes(value)) {
                    return false;
                }
            }

            if (i.exclude_values && i.exclude_values.length > 0) {
                if (i.exclude_values.includes(value)) {
                    return false;
                }
            }

            return true;
        });

    });
}
