import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { getFlagColor } from "./utils";

const styles = StyleSheet.create({
    container: {
        borderRadius: "5px",
        paddingTop: "6px",
        paddingBottom: "3px",
        paddingLeft: "10px",
        paddingRight: "10px",
        height: "8%",
        flexDirection: "row",
        justifyContent: "space-between",
        display: "flex",
        color: "black",
        fontFamily: "Poppins-Medium",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "14px",
    },
    horizontalMargins: {
        marginLeft: "5px",
        marginRight: "5px",
    },
    title: {
        marginRight: "40px",
    },
    progressBar: {
        marginTop: "1px",
        marginLeft: "20px",
    },
    progressBarContainer: {
        flexDirection: "row",
        width: "100%",
        height: 18,
        backgroundColor: "#e0e0e0",
    },
    progressBarSegment: {
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    progressBarText: {
        color: "white",
        fontSize: 10,
    },
});

interface PalletScoringOverviewProps {
    status?: string;
    percentages: any;
}

interface PrintChild {
    intake_status: string;
}

interface ProgressBarProps {
    percentages: {
        danger: number;
        warning: number;
        success: number;
    };
}

export const calculateStatusPercentages = (printChildren: PrintChild[], checkTypes: any): { danger: number; warning: number; success: number } => {
    const statusCounts = printChildren.reduce(
        (counts, child) => {
            return checkTypes.reduce((acc, checkType) => {
                const key = `${checkType}_status`;
                if (child[key]) {
                    acc[child[key]] = (acc[child[key]] || 0) + 1;
                    acc.total++;

                }
                return acc;
            }, counts);
        },
        { danger: 0, warning: 0, success: 0, total: 0 }
    );

    const total = statusCounts.total || 1;

    return {
        danger: (statusCounts.danger / total) * 100 || 0,
        warning: (statusCounts.warning / total) * 100 || 0,
        success: (statusCounts.success / total) * 100 || 0,
    };
};

const ProgressBar = ({ percentages }: ProgressBarProps) => {
    const segmentStyle = (color: string, percentage: number) => ({
        backgroundColor: color,
        width: `${percentage}%`,
    });

    return (
        <View style={styles.progressBarContainer}>
            { percentages.success > 0 && <View style={[segmentStyle(getFlagColor("success"), percentages.success), styles.progressBarSegment]}>
                <Text style={styles.progressBarText}>
                    {percentages.success.toFixed(0)}%
                </Text>
            </View>
            }
            { percentages.warning > 0 && <View style={[segmentStyle(getFlagColor("warning"), percentages.warning), styles.progressBarSegment]}>
                <Text style={styles.progressBarText}>
                    {percentages.warning.toFixed(0)}%
                </Text>
            </View>
            }
            { percentages.danger > 0 && <View style={[segmentStyle(getFlagColor("danger"), percentages.danger), styles.progressBarSegment]}>
                <Text style={styles.progressBarText}>
                    {percentages.danger.toFixed(0)}%
                </Text>
            </View>
            }
        </View>
    );
};

const PDFPalletScoringOverview = ({ status, percentages }: PalletScoringOverviewProps) => {
    return (
        <View style={[styles.container, styles.horizontalMargins, { border: `1px solid ${getFlagColor(status)}` }]}>
            <View style={styles.title}>
                <Text>Pallet scoring overview:</Text>
            </View>
            <View style={styles.progressBar}>
                <ProgressBar percentages={percentages} />
            </View>
        </View>
    );
};

export default PDFPalletScoringOverview;
