/** @jsxImportSource @emotion/react */
import { useSelector } from "react-redux";
import { css } from "@emotion/react";
import { Layer } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault, isFilledArray } from "../../../../utils";
import { MetricCardComponents } from "./Components/MetricCards";


export default function LayerMetricCardsGroup() {
    const config = useConfig();
    const layer = useSelector<any, Layer>((state) => state.layers.current);
    const layerTypeConfig = config.get_layer_config(layer);
    const layerMetricList = layerTypeConfig?.show_metric_cards;

    if (!isFilledArray(layerMetricList)) {
        return null;
    }

    return (
        <div className="w-100" css={css`display: grid; grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); grid-gap: 1rem;`}>
            {getFilledArrayOrDefault(layerMetricList, []).map((i, index) => {
                const MetricComponent = MetricCardComponents[i.key];
                if (!MetricComponent) {
                    // eslint-disable-next-line no-console
                    console.log(`MetricCardComponent not found for key: ${i.key}`);
                    return null;
                }
                return <MetricComponent key={index} metric={i} />;
            })}
        </div>
    );
}
