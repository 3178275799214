/** @jsxImportSource @emotion/react */
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate
} from "react-router-dom";
import { logoutUser } from "../../../actions/Auth/actions";
import { RootState } from "../../../reducers";
import { HeaderWrapper } from "../Layout/Header";

const useMainLinks = () => [
    // <GeneralLink key={headerLinks.reports} path={`/reports`} label={"Reports"} />
];

const useSecondaryLinks = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { authenticated, isLoading } = useSelector<RootState, any>((state) => state.auth);
    const dispatch = useDispatch();
    const { instance } = useMsal();

    const logoutHandler = () => {
        // make sure to redirect to login so that the redirect_to=/add-check/134/summary param is not set to the currect page
        navigate("/login");
        dispatch(logoutUser());
        instance.logoutRedirect();
    };

    if (!authenticated) {
        // also show login item when loading, this will prevent "flickering" of the header during page load
        return [
            (location.pathname !== "/login" || isLoading) && <li className="nav-item" key="login">
                <NavLink className="nav-link" to="/login" >Login</NavLink>
            </li>,
        ];
    }

    return [
        <li className="nav-item" key="logout">
            <button className="nav-link link btn btn-link " onClick={() => logoutHandler()}><span className="text-danger">Logout</span></button>
        </li>
    ];

};

export default function Header() {
    const mainLinks = useMainLinks();
    const secondaryLinks = useSecondaryLinks();

    return (
        <HeaderWrapper mainLinks={mainLinks} secondaryLinks={secondaryLinks} />
    );
}
