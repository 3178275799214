import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";

// Extract filename from url
const getFilename = (str) => {
    if (!str) return null;
    const regex = /\/([^/?]+)\?/;
    const match = str.match(regex);
    if (match && match.length >= 2) {
        return match[1];
    }
    return null;
};

export interface AttachmentLinkProps {
    url: string;
    label?: string;
    id?: string;
}

export interface AttachmentButtonProps extends AttachmentLinkProps {
    icon: any;
}

export const AttachmentLink = ({ url, label = getFilename(url), id = label }: AttachmentLinkProps) => (
    <a id={id} href={url} target="_blank" rel="noreferrer" style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {label}
    </a>
);

export const AttachmentButton = ({ url, icon, label = getFilename(url), id = label }: AttachmentButtonProps) => {

    const onClick = (e) => {
        e.preventDefault();
        window.open(url, "_blank"); // Open in a new tab
    };

    return (
        <Button color="light" id={id} onClick={onClick}>
            {icon ? <FontAwesomeIcon icon={icon} /> : label}
        </Button>
    );
};
