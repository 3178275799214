import { useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// * Custom hook to handle navigation with query parameters inside the check flow
export default function useNavigateFrom() {
    const navigate = useNavigate();
    const location = useLocation();

    const currentParams = new URLSearchParams(location.search);
    const from = currentParams.get("from");

    const getNextRoute = (route, persistent_from = false, params:any = {}) => {
        const queryParams = new URLSearchParams({
            ...params,
            from: persistent_from && from ? from : location.pathname + location.search, // * use persistent_from to keep the from param
        });

        return `${route}?${queryParams.toString()}`;
    };

    // Navigate to new (fullscreen) route with current from as param, use persistent_from to keep the from param
    // e.g when navigating between check flow steps
    const navigateTo = useCallback((route, persistent_from = false, params:any = {}) => {
        navigate(getNextRoute(route, persistent_from, params));
    }, [navigate, from]);

    const getPrevRoute = useCallback((fallback = "/") => from || fallback, [from]);
    const navigateBack = useCallback((fallback = "/") => navigate(from || fallback), [navigate, from]);

    return { getNextRoute, getPrevRoute, navigateTo, navigateBack };
}


export function FromLink({ to, children, ...props }) {
    const { getNextRoute } = useNavigateFrom();
    return <Link to={getNextRoute(to)} {...props}>{children}</Link>;
}
