/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatCheck2Frontend } from "../../../../actions/Checks/util";
import { Check, CHECK_LOCATION, Layer } from "../../../../actions/Layers/constants";
import useConfig, { useTenantFormOptions } from "../../../../actions/Tenants/config/configHook";
import { callAvosApi } from "../../../../utils/useAvosApiHook";
import { MetaRow } from "./LayerMeta";

export default function ArrivalCheck({ subconfig, title }) {
    const layer = useSelector<any, Layer>((state) => state.layers.current);
    const children = useSelector<any, Check>((state) => state.layers.children);
    const checks = useSelector<any, Check[]>((state) => state.layers.checks);
    const arrivalCheck = checks.filter((i) => i.location === CHECK_LOCATION.ARRIVAL).sort((a, b) => b.test_id - a.test_id);
    const [check, setCheck] = useState<Check | null>(null);

    const LatestArrivalCheckkId = arrivalCheck[0];

    useEffect(() => {
        if (!LatestArrivalCheckkId) {
            return;
        }
        callAvosApi(`/tests/${LatestArrivalCheckkId.test_id}/`).then((response) => {
            setCheck(formatCheck2Frontend(response.data));
        });
    }, [LatestArrivalCheckkId]);


    const form_options = useTenantFormOptions();
    const config = useConfig();

    if (!layer || !layer.id || !subconfig) {
        return null;
    }

    return (
        <>
            <h3 className="mb-3">{title}</h3>

            {subconfig.map((i, index) => <MetaRow key={index} label={i.label} value={i.set_value({
                config,
                layer,
                check, // * To be used in check tables and layer overview
                fruit: null, // * Fruit list is not available on overview screen
                children,
                form_options // * used to translate form values to labels
            }).display_component()} />)}
        </>
    );
}

ArrivalCheck.propTypes = {
    subconfig: PropTypes.array,
    title: PropTypes.string
};
