import { LogLevel } from "@azure/msal-browser";

const DEV_CLIENT_ID = "12ef3e6c-6e1c-4ebd-a6ab-9fd3097389e2";
const PROD_CLIENT_ID = "30bb5e28-280f-4e1f-95fe-63c37ba246b8";

export enum ENV {
    Default = "default",
    Test = "test",
    Staging = "staging",
}

export const subdomainByEnv = {
    avos: {
        [ENV.Default]: "frontend",
        [ENV.Staging]: "staging-avos",
        [ENV.Test]: "test-avos",
    },
    supplierPortal: {
        [ENV.Default]: "portal",
        [ENV.Staging]: "staging-portal",
        [ENV.Test]: "test-portal",
    },
};

// https://frontend is both the develop as the production subdomain.
const subdomain = !window.location.origin.endsWith("local") && window.location.host.split(".")[1]
    ? window.location.host.split(".")[0]
    : false;

const PRODUCTION_URLS = [subdomainByEnv.avos[ENV.Default], subdomainByEnv.supplierPortal[ENV.Default]];
const STAGING_URLS = [subdomainByEnv.avos[ENV.Staging], subdomainByEnv.supplierPortal[ENV.Staging]];
const TEST_URLS = [subdomainByEnv.avos[ENV.Test], subdomainByEnv.supplierPortal[ENV.Test]];

export const isStagingEnv = !!subdomain && STAGING_URLS.includes(subdomain);
export const isProductionEnv = !!subdomain && PRODUCTION_URLS.includes(subdomain);
export const isTestEnv = !!subdomain && TEST_URLS.includes(subdomain);

const clientId = isProductionEnv ? PROD_CLIENT_ID : DEV_CLIENT_ID;
const config = {
    backend_client: clientId,
    frontend_client: clientId,
    scopes: ["openid", "profile", "email", `api://${clientId}/user_impersonation`],
    tokenType: "accessToken" // leave prod unchanged
};

export const { scopes, tokenType } = config;

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId,
        // authority: "https://login.windows-ppe.net/common",
        // redirectUri: window.location.protocol + "://" + window.location.host +"/auth",
        postLogoutRedirectUri: "/login",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                case LogLevel.Error:
                    console.error(message); // eslint-disable-line no-console
                    break;
                default:
                }
            },
        },
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes,
    prompt: "select_account",
};
