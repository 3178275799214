/** @jsxImportSource @emotion/react */
import { faMinus, faTemperatureHigh } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { getLayer, getLayerChildren, removeLayerChild } from "../../../../actions/Layers/actions";
import { LAYER_TYPE } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { RootState } from "../../../../reducers";
import { Loading } from "../../../Helper/Loading";
import AvosModal from "../../Helper/Modal";
import { MetaRow } from "./LayerMeta";


export default function LayerParentsChildren() {
    const isLoading = useSelector((state: RootState) => state.layers.isLoading);
    const layer = useSelector((state: RootState) => state.layers.current);
    const { children, parents } = layer;
    const childrenWithMeta = useSelector((state: RootState) => state.layers.children);

    const [parentModal, setParentModal] = useState(false);
    const [childrenModal, setChildrenModal] = useState(false);

    const layerWithTempSensors = childrenWithMeta?.results?.filter(
        (i:any) => i.temptale_number && i?.temptale_number !== "None" && i?.temptale_number !== "-" && i?.temptale_number !== "0"
    ).map((i) => i.label) || [];

    const config = useConfig();
    const displayLayerType = (type: string) => config.get_raw_layer_config(type)?.text || type;

    const layerConfig = config.get_layer_config(layer);
    const { hide_sub_pallets } = layerConfig;

    if (isLoading) {
        return <Loading />;
    }

    const sortedChildren = children
        .filter(({ type }) => (!hide_sub_pallets || type !== LAYER_TYPE.SUBPALLET))
        .sort((a, b) => a.label.localeCompare(b.label));

    return (
        <div className="pt-4">
            {parents.length > 0 && (
                <div className="cursor-pointer" onClick={() => setParentModal(true)}>
                    {parents.map((parent) => (
                        <MetaRow key={parent.id} label={displayLayerType(parent.type)} value={<Link to={`/layer/${parent.id}`}>{parent.label}</Link>} />
                    ))}
                </div>
            )}
            <AvosModal
                header={`Linked ${(displayLayerType(layerConfig.parent_layer_type) || "items")}`}
                onClose={() => setParentModal(false)}
                size="md"
                showSaveButton={false}
                isOpen={parentModal}
            >
                {parents.map((parent) => (
                    <RelationRow
                        key={parent.id}
                        parent_layer_id={parent.id}
                        layer_id={layer.id}
                        type={displayLayerType(parent.type)}
                    >
                        <Link to={`/layer/${parent.id}`}>{parent.label}</Link>
                    </RelationRow>
                ))}
            </AvosModal>
            {sortedChildren.length > 0 && (
                <div className="cursor-pointer" onClick={() => setChildrenModal(true)}>
                    {sortedChildren.map(({ id, type, label }) => {
                        const hasTempSensor = layerWithTempSensors.includes(label);
                        return (
                            <MetaRow
                                key={id}
                                label={
                                    <div>
                                        {displayLayerType(type)}{" "}
                                        {hasTempSensor && (
                                            <span className="me-2">
                                                <FontAwesomeIcon icon={faTemperatureHigh} />
                                            </span>
                                        )}
                                    </div>
                                }
                                value={<Link to={`/layer/${id}`}>{label}</Link>}
                            />
                        );
                    })}
                </div>
            )}
            <AvosModal
                header={`Linked ${(displayLayerType(layerConfig.children_layer_type) || "items")}`}
                onClose={() => setChildrenModal(false)}
                size="md"
                showSaveButton={false}
                isOpen={childrenModal}
            >
                {sortedChildren.map(({ id, type, label }) => {
                    const hasTempSensor = layerWithTempSensors.includes(label);
                    return (
                        <RelationRow
                            key={id}
                            parent_layer_id={layer.id}
                            layer_id={id}
                            type={displayLayerType(type)}
                        >
                            <Link to={`/layer/${id}`}>{label}</Link>
                            {hasTempSensor && (
                                <span className="me-2">
                                    <FontAwesomeIcon icon={faTemperatureHigh} />
                                </span>
                            )}
                        </RelationRow>
                    );
                })}
            </AvosModal>
        </div>
    );
}

interface RelationRowProps {
    children: React.ReactNode;
    parent_layer_id: string;
    layer_id: string;
    type: string;
}

function RelationRow({ children, parent_layer_id, layer_id, type }: RelationRowProps) {
    const dispatch = useDispatch();

    const deleteRelation = () => dispatch(removeLayerChild(parent_layer_id, layer_id) as any)
        .then(() => {
            dispatch(getLayer(layer_id) as any);
            dispatch(getLayerChildren(layer_id) as any);
        });

    return (
        <div className="d-sm-flex align-items-center py-1">
            <div className="pe-2">
                <Button
                    onClick={() => deleteRelation()}
                    color="danger"
                    outline
                    size="sm"
                >
                    <FontAwesomeIcon icon={faMinus} />
                </Button>
            </div>
            <div className="ms-auto">{type} {children}</div>
        </div>
    );
}
