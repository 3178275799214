/** @jsxImportSource @emotion/react */
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "../../../actions/Admin/reducer";
import { getReports, updateReportsFilter } from "../../../actions/SupplierPortal/report/actions";
import { Report, ReportState } from "../../../actions/SupplierPortal/report/reducer";
import { Tenant } from "../../../actions/SupplierPortal/tenant/reducer";
import { applyDisplayFilterLayerFields } from "../../../actions/Tenants/config/applyDisplayFilter";
import { RootState } from "../../../reducers";
import { AttachmentButton } from "../../Helper/AttachmentLink";
import { PageBase } from "../Admin/PageBase";
import { formatDateTime } from "../Layers/fields/formatters";

const ReportsPage = () => {
    const dispatch = useDispatch();
    const reportState = useSelector<RootState, ReportState>((state) => state.supplierPortal.report);
    const selectedTenant = useSelector<RootState, Tenant | undefined>((state) => state.supplierPortal.tenant.selected);

    const onUpdateFilter = (filter: Filter) => {
        dispatch(updateReportsFilter(filter));
    };

    const onListEntities = (filter: Filter) => {
        if (selectedTenant) dispatch(getReports(selectedTenant.id, selectedTenant.supplier_id, filter));
    };

    const portalFields = applyDisplayFilterLayerFields(selectedTenant?.config?.supplier_portal_overview_fields).map((field) => ({
        ...field,
        // * Overwrite display_component
        display_component: (report) => field.set_value({
            config: {},
            layer: report.layer,
            check: null, // * To be used in check tables and layer overview
            fruit: null, // * Fruit list is not available on overview screen
            children: null, // * children is only available on layer index page
            form_options: [] // * used to translate form values to labels
        }).display_component()
    }));

    const commonFields = [
        {
            label: "Date",
            name: "created",
            lg: 2,
            display_component: (report) => formatDateTime(dayjs(report.created), "DD/MM/YYYY - HH:mm"),
        },
        {
            label: "PDF",
            name: "url",
            display_component: (report) => <AttachmentButton url={report.url} icon={faFilePdf} />,
            lg: 1,
        },
    ];

    return <PageBase<Report>
        title="Reports"
        tableRow={[...portalFields, ...commonFields]}
        actionsColumnWidth={1}
        createForms={[]}
        state={reportState}
        events={{ onUpdateFilter, onListEntities }}
        createable={false}
    />;
};

export default ReportsPage;


