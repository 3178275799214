import {
    baseConfig,
} from "../constants";
import { RootConfigType } from "../constantsTyped";

export const ColruytConfig: RootConfigType = {
    ...baseConfig,
    indicate_quality_at_defects: false,
    strict_check_size: false,
    indicate_soft_avocado_at_internal: false,
    suggest_color_stage: true,

};
