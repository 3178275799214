/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faBars, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Container } from "reactstrap";
import FindLayerBox from "../../Layers/FindLayerBox";
import FruitFilter from "../../Layers/FruitFilter";
import { NavMainLinks } from "./NavMainLinks";
import { NavSecondaryLinks } from "./NavSecondaryLinks";
import Logo from "../Logo";
import * as styles from "./header.styles";

function NavToggle({ open, toggleNav }: {open: boolean; toggleNav(val: boolean): void }) {
    return (
        <Button color="light" css={styles.navItem} onClick={() => toggleNav(!open)}>
            {!open && <span><FontAwesomeIcon icon={faBars} /></span>}
            {open && <span><FontAwesomeIcon icon={faTimes} /></span>}
        </Button>
    );
}

type HeaderWrapperProps = {
    mainLinks?: React.ReactNode;
    secondaryLinks?: React.ReactNode;
    children?: React.ReactNode;
}

export const HeaderWrapper = ({ mainLinks, secondaryLinks, children }: HeaderWrapperProps) => {
    const [open, toggleNav] = useState(false);

    return (
        <div className="w-100">
            <div className="bg-white px-3 px-lg-4" css={css`box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12); transition: color 0.3s ease-in;`}>
                <Container className="container d-flex align-items-center justify-content-between w-100" css={styles.headerWrapper}>
                    {/* MOBILE NAV */}
                    {/* @TODO: make this into a drawer/modal? */}
                    <div className="d-lg-none w-100 bg-white">
                        <div className="w-100 d-flex py-2 justify-content-between align-items-center">
                            <Logo />

                            <NavToggle open={open} toggleNav={toggleNav}></NavToggle>
                        </div>

                        {open && (
                            <div className="w-100">
                                <div className="navbar">
                                    <ul className="navbar-nav w-100">
                                        {mainLinks}
                                        {secondaryLinks}
                                    </ul>
                                </div>
                            </div>
                        )}

                    </div>

                    {/* TABLET + DESKTOP NAV */}
                    <div className="w-100 d-none d-lg-flex align-items-center justify-content-between">
                        <Logo />

                        <div className="w-100 d-flex align-items-center">
                            {open ? (
                                <div className="ms-auto">
                                    <div className="navbar navbar-expand-lg">
                                        <ul className="d-flex navbar-nav justify-content-end align-items-center w-100">
                                            {secondaryLinks}
                                        </ul>
                                    </div>
                                </div>
                            ) : (
                                <div className="ms-auto">
                                    <div className="navbar navbar-expand-lg">
                                        <ul className="d-flex navbar-nav justify-content-end w-100">
                                            {mainLinks}
                                        </ul>
                                    </div>
                                </div>
                            )}

                            <div className="ms-3">
                                <NavToggle open={open} toggleNav={toggleNav} />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

            {children && (
                <div
                    className="w-100 d-none d-lg-flex align-items-center justify-content-end mt-3"
                    css={css`flex-wrap: wrap; gap: 1rem;`}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

type HeaderProps = {
    showLayerFilters?: boolean;
}

export default function Header({ showLayerFilters }: HeaderProps) {
    return (
        <HeaderWrapper
            mainLinks={<NavMainLinks />}
            secondaryLinks={<NavSecondaryLinks />}
        >
            {showLayerFilters && (
                <Container>
                    <div
                        className="d-none d-lg-flex align-items-center justify-content-end px-3 px-lg-4"
                        css={css`flex-wrap: wrap; gap: 1rem;`}
                    >
                        <FruitFilter />
                        <FindLayerBox />
                    </div>
                </Container>
            )}
        </HeaderWrapper>
    );
}
