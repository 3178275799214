/** @jsxImportSource @emotion/react */
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import { Tenant } from "../../../actions/SupplierPortal/tenant/reducer";
import { RootState } from "../../../reducers";
import {
    selectTenant,
    getTenants,
} from "../../../actions/SupplierPortal/tenant/actions";
import Reports from "./Reports";
import Header from "./Header";
import FullPageLayout from "../Layout/FullPageLayout";
import StyledBox from "../Layout/StyledBox";

type SupplierData = {
    supplier_id: string;
    id: string;
    label: string;
    supplier_name: string;
};

type SupplierCollection = Record<string, SupplierData[]>;

export default function SupplierPortalPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTenants());
    }, []);

    const selectedTenant = useSelector<RootState, Tenant | undefined>(
        (state) => state.supplierPortal.tenant.selected
    );

    const tenants = useSelector<RootState, Tenant[]>(
        (state) => state.supplierPortal.tenant.items
    );

    const tenantsBySupplier = useMemo(
        () => tenants.reduce((acc, curr) => {
            acc[curr.supplier_id] = [...(acc[curr.supplier_id] ?? [])].concat(curr);
            return acc;
        }, {}),
        [tenants]
    ) as SupplierCollection;

    useEffect(() => {
        if (tenants.length > 0 && !selectedTenant) {
            dispatch(selectTenant(tenants[0]));
        }
    }, [tenantsBySupplier]);

    const onTenantSelect = (tenant: Tenant) => {
        dispatch(selectTenant(tenant));
    };

    const hasMultipleSuppliers = useMemo(() => Object.keys(tenantsBySupplier)?.length > 1, [tenantsBySupplier]);

    return (
        <FullPageLayout header={<Header />} withStyledBox={false}>
            <Container className="py-md-5 py-3">
                <Row className="g-5">
                    {/* Left Side: Tenants */}
                    {/* @TODO: Use select on mobile? */}
                    <Col xs="3" sm="3" md="2" className="border-right p-3">
                        {!!tenants.length && !hasMultipleSuppliers && <h6 className="mb-2 text-muted fs-6">Clients</h6>}

                        {Object.values(tenantsBySupplier).map((items, i) => (
                            <div className="mb-4" key={`${items[0].supplier_id}-${i}`}>
                                {hasMultipleSuppliers && <h6 className="mb-2 text-muted fs-6">{items[0].supplier_name}</h6>}

                                <ListGroup>
                                    {items.map((tenant, index) => (
                                        <ListGroupItem
                                            key={index}
                                            tag="button"
                                            action
                                            onClick={() => onTenantSelect(tenant)}
                                            active={
                                                tenant.id === selectedTenant?.id
                                                && tenant.supplier_id === selectedTenant?.supplier_id
                                            }
                                        >
                                            {tenant.label}
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </div>
                        ))}
                    </Col>

                    {/* Right Side: Reports */}
                    <Col xs="9" sm="9" md="10">
                        <StyledBox>
                            <h4 className="text-muted fs-6 fw-bold border-bottom pb-2 mb-4">Reports</h4>

                            <Reports />
                        </StyledBox>
                    </Col>
                </Row>
            </Container>
        </FullPageLayout>
    );
}
