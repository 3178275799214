/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import { CATEGORIES_WITH_CHECK_BUTTON, LAYER_TAB_CATEGORIES } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { headerLinks } from "../../../../actions/Tenants/config/constants";
import MetaForm from "../../../Forms/MetaForm";
import FieldConfigForm from "./FieldConfigForm";
import FormConfigForm from "./FormConfigForm";
import NavigationConfigForm from "./NavigationConfigForm";
import RipeningRoomMetricCardsConfigForm from "./RipeningRoomMetricCardsConfigForm";
import { yesOrNoRadio } from "./util";

// SUPPLIER PORTAL CONFIG FILE
export const defaultRootConfig = {
    text: "root",
    value: "root",
    primary_header_links: [
        { path: headerLinks.home, label: "Overview" }
    ],
    secondary_header_links: [
        { path: headerLinks.settings, label: "Settings" },
        { path: headerLinks.logout, label: "Logout" },
    ],
    overview_general_filter_form: []
};

export default function RootConfigForm({ config_data, setConfigData }) {
    const config = useConfig();
    const { tenant_id } = useConfig();

    const form = [
        {
            label: "Value",
            description: "What is the slug or identifier of this layer type. Used in the database and in urls.",
            name: "value",
            type: "single-select",
            readOnly: true,
            default: "root",
            options: [{ value: "root", label: "root" }]
        },
        {
            type: "element",
            el: <NavigationConfigForm
                name="primary_header_links"
                title="Primary header links"
                description="Primary navigation links in the header"
                fields={config_data.primary_header_links}
                setArray={(value) => setConfigData({ ...config_data, primary_header_links: value })}
            />
        },
        {
            type: "element",
            el: <NavigationConfigForm
                name="secondary_header_links"
                title="Secondary header links"
                description="Secondary navigation links in the header"
                fields={config_data.secondary_header_links}
                setArray={(value) => setConfigData({ ...config_data, secondary_header_links: value })}
            />
        },
        {
            label: "Default Fruit type for Layer Creation",
            name: "default_fruit_type",
            type: "single-select",
            options: config.fruit_types.map((i) => ({ value: i.value, label: i.text }))
        },
        {
            type: "element",
            el: <FormConfigForm
                fields={config_data.overview_general_filter_form || []}
                description="Specify the fields will generate the general layer filter in the overview screen."
                name="overview_general_filter_form"
                title="General Filter"
                setArray={(overview_general_filter_form) => setConfigData({ ...config_data, overview_general_filter_form })} />
        },
        {
            type: "header",
            label: "Tab Config"
        },
        {
            label: "Tab categories with Start Check button",
            name: "tab_categories_with_check_button",
            description: <div>What tab categores should show the big green button to start check. Will default to {CATEGORIES_WITH_CHECK_BUTTON.join(", ")}</div>,
            type: "single-select",
            multi: true,
            return_array: true,
            options: Object.keys(LAYER_TAB_CATEGORIES).map((i) => ({ value: LAYER_TAB_CATEGORIES[i], label: i }))
        },
        {
            type: "header",
            label: "Ripening module Config"
        },
        {
            type: "element",
            el: <FieldConfigForm
                fields={config_data.ripening_room_overview_fields || []}
                description={<span>What fields to display on the ripening room overview screen (<Link to={`/tenants/${tenant_id}/facility-locations`}>list of rooms</Link>).</span>}
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                name="ripening_room_overview_fields"
                title="Ripening Room Overview"
                setArray={(ripening_room_overview_fields) => setConfigData({ ...config_data, ripening_room_overview_fields })} />
        },
        {
            type: "number",
            name: "ripening_room_number_of_rooms_per_row",
            label: "Number of Rooms per Row in Grid view",
            description: "How many rooms to show per row in the grid view of the ripening rooms.",
            placeholder: "9",
        },
        {
            type: "element",
            el: <FieldConfigForm
                fields={config_data.ripening_room_pallet_fields || []}
                description="What fields to display on the ripening room screen (list of allocated pallets)."
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                name="ripening_room_pallet_fields"
                title="Ripening Room Overview"
                setArray={(ripening_room_pallet_fields) => setConfigData({ ...config_data, ripening_room_pallet_fields })} />
        },
        {
            type: "element",
            el: <RipeningRoomMetricCardsConfigForm
                fields={config_data.ripening_room_metric_cards || []}
                description="Show metric cards on the ripening room screen. Is also being used in table in side bar."
                name="ripening_room_metric_cards"
                title="Ripening Room Metrics"
                setArray={(ripening_room_metric_cards) => setConfigData({ ...config_data, ripening_room_metric_cards })} />

        },
        {
            type: "element",
            el: <FieldConfigForm
                fields={config_data.ripening_room_sidebar_meta_fields_1 || []}
                description={<span>What fields to display on the ripening room side bar (first group)</span>}
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                name="ripening_room_sidebar_meta_fields_1"
                title="Ripening Room Overview"
                setArray={(ripening_room_sidebar_meta_fields_1) => setConfigData({ ...config_data, ripening_room_sidebar_meta_fields_1 })} />
        },
        {
            type: "element",
            el: <FieldConfigForm
                fields={config_data.ripening_room_sidebar_meta_fields_2 || []}
                description={<span>What fields to display on the ripening room side bar (second group)</span>}
                useDisplayFilter={["fruit_types", "exclude_fruit_types"]}
                name="ripening_room_sidebar_meta_fields_2"
                title="Ripening Room Overview"
                setArray={(ripening_room_sidebar_meta_fields_2) => setConfigData({ ...config_data, ripening_room_sidebar_meta_fields_2 })} />
        },
        {
            type: "element",
            name: "ripening_room_meta_form",
            label: "Room Meta Form",
            el: <FormConfigForm
                fields={config_data.ripening_room_meta_form || []}
                description="A form to manually set the meta data of the ripening room."
                name="ripening_room_meta_form"
                title="Meta Form"
                setArray={(ripening_room_meta_form) => setConfigData({ ...config_data, ripening_room_meta_form })} />
        },
        {
            type: "element",
            name: "ripening_room_conditions_form",
            label: "Room Conditions Form",
            el: <FormConfigForm
                fields={config_data.ripening_room_conditions_form || []}
                description="A form to manually set the conditions of the ripening room."
                name="ripening_room_conditions_form"
                title="Conditions Form"
                setArray={(ripening_room_conditions_form) => setConfigData({ ...config_data, ripening_room_conditions_form })} />
        },
        {
            type: "header",
            label: "Ripening Room Layout"
        },
        {
            name: "ripening_room_slot_allocation_from_erp",
            label: "Slot Allocation in Room",
            description: "Enable slot allocation in the ripening room. This will allow an ERP/WHS to allocate pallets to specific slots in the ripening room.",
            ...yesOrNoRadio
        },
        {
            name: "ripening_room_mock_ripening_class_distribution",
            label: "Mock Ripening Class Distribution",
            description: "Show a mocked ripening class distribution in the ripening room.",
            ...yesOrNoRadio
        },
        {
            type: "text",
            name: "ripening_room_levels",
            label: "Ripening Room Levels",
            description: "Give a comma seperated list of the cell levels. For example: A,B,C or -1,0,1 or 1,2,3,4,5 or Ground Floor, First Floor, Second Floor. Defaults to Level 0, Level 1, Level 2",
            placeholder: "0,1,2",
        },
        {
            type: "text",
            name: "ripening_room_columns",
            label: "Ripening Room Columns",
            description: "Give a comma seperated list of the cell columns.  Use `hall` to define a hallway. For example: A,hall,B or 1,2,hall,3,5",
        },
        {
            type: "text",
            name: "ripening_room_rows",
            label: "Ripening Room Rows",
            description: "Give a comma seperated list of the cell rows. Use `hall` to define a hallway. For example: A,hall,B or 1,2,hall,3,5",
        },
        {
            type: "header",
            label: "Supplier Portal Config",
        },
        {
            type: "element",
            el: <FieldConfigForm
                fields={config_data.supplier_portal_overview_fields || []}
                description={"What fields to display on the supplier's reports table."}
                name="supplier_portal_overview_fields"
                title="Supplier portal config"
                setArray={(supplier_portal_overview_fields) => setConfigData({ ...config_data, supplier_portal_overview_fields })} />
        },
        {
            label: "Packing List Config",
            type: "header",
        },
        {
            type: "single-select",
            name: "default_parent_label_field",
            label: "Default Parent Label Field",
            description: "What field to use as the parent label field in the packing list.",
            options: ["container_no", "purchase_order_number", "receipt", "order_number", "vessel_number", "voyage_number"].map((i) => ({ value: i, label: i }))
        },
        {
            type: "single-select",
            name: "default_child_label_field",
            label: "Default Child Label Field",
            description: "What field to use as the child label field in the packing list.",
            options: ["supplier_pallet_number", "license_plate"].map((i) => ({ value: i, label: i }))
        }
    ];

    return <MetaForm meta={form} setValue={(field, value) => setConfigData({ ...config_data, [field]: value })} object={config_data} config={config} />;
}
