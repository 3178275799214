/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import Header from "./Header";
import Footer from "./Footer";

interface LayoutProps {
    children: React.ReactNode;
    header?: React.ReactNode;
    fullscreen?: boolean;
}

export default function DefaultLayout({ children, header, fullscreen = false }: LayoutProps) {
    const styles = fullscreen ? undefined : css`background-color: white;`;

    return (
        <div
            className={`d-flex flex-column ${fullscreen ? "bg-gray" : ""}`}
            css={[css`min-height: 100vh;`, styles]}
        >
            {!fullscreen && (header ?? <Header />)}

            <div>{children}</div>

            <div
                className="mt-auto"
                css={styles}
            >
                <Footer />
            </div>
        </div>
    );
}
