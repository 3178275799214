/* eslint-disable no-nested-ternary */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Container, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { Entity, EntityState, Filter } from "../../../../actions/Admin/reducer";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { FormField, SelectOption } from "../../../../actions/Tenants/config/constantsTyped";
import MetaForm, { metaFormIsValid } from "../../../Forms/MetaForm";
import { Loading } from "../../../Helper/Loading";
import { PaginationRow } from "../../../Helper/PaginationRow";
import { TableCell } from "../../../Helper/Table";
import { ConfirmationModal } from "../../Helper/ConfirmationModal";
import { ModalResult } from "../../Helper/Modal";
import { filterResults } from "../utils";
import * as styles from "./pageBase.styles";

export interface PageBaseTableRowField<T> {
    label: string;
    name: string;
    display_component?: (entity: T) => JSX.Element | string;
    lg?: number;
    set_value?: any;
}

export interface Form<FormObject extends object = object, RowEntity extends Entity = Entity> {
    title: string;
    fields: FormField[];
    modalButtonLabel: string;
    modalButtonHidden?: boolean;
    onSave: (newObject: FormObject, entity: Partial<RowEntity>) => void;
    onDelete?: (newObject: FormObject, entity: Partial<RowEntity>) => void;
    onFieldValueChange?: (field: string, value: any, formObject: Partial<FormObject>, entity: Partial<RowEntity>) => Partial<FormObject>;
    getFormObjectFromEntity: (entity: Partial<RowEntity>) => Partial<FormObject>;
    onPreEditModalOpen?: () => void;
    onPreAddModalOpen?: () => void;
}

export interface FormOptions { [optionType: string]: SelectOption[] }

export interface PageBaseProps<T extends Entity> {
    title: string;
    tableRow: PageBaseTableRowField<T>[];
    actionsColumnWidth: number;
    state: EntityState<T>;
    events: {
        onUpdateFilter: (filter: Filter) => void;
        onListEntities: (filter: Filter) => void;
    },
    createForms: Array<(formOptions: FormOptions | undefined, entity: Partial<T> | undefined, formObject: object, state: EntityState<T>) => Form>;
    defaultModalIndex?: number;
    deletable?: boolean;
    createable?: boolean;
}

export const PageBase = <T extends Entity>({
    title,
    tableRow,
    actionsColumnWidth,
    state,
    events: { onUpdateFilter, onListEntities },
    createForms,
    defaultModalIndex = 0,
    deletable = true,
    createable = true,
}: PageBaseProps<T>) => {
    const config = useConfig();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [currentEntity, setCurrentEntity] = useState<Partial<T>>({});
    const [modalObject, setModalObject] = useState({} as any);
    const [selectedModalIndex, setSelectedModalIndex] = useState<number>(-1);
    const allOptions = useSelector((state: any) => state?.tenants?.options_by_field);
    const tenant_options = allOptions?.[config.tenant_id];
    const forms = createForms.map((form) => form(tenant_options, currentEntity, modalObject, state));
    const selectedModal = forms[selectedModalIndex];
    const isModalOpen = selectedModalIndex > -1 && !!selectedModal;
    const setModalClosed = () => setSelectedModalIndex(-1);
    const { items, isLoading, filter, localFiltering, reload } = state;
    const results = localFiltering ? filterResults(items.results, filter) : items.results;

    useEffect(() => {
        if ((!isLoading && (!localFiltering || !results?.length)) || reload) {
            onListEntities(localFiltering ? {} : filter);
        }
    }, [filter, localFiltering, reload]);

    const onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = Math.max((currentPage - 1) * pageLimit, 0);

        if (filter.offset !== offset) {
            onUpdateFilter({ ...filter, offset });
        }
    };

    const updateLimit = (limit) => {
        onUpdateFilter({ ...filter, limit });
    };

    const onDeleteClick = () => {
        setConfirmationModalOpen(true);
    };

    const onConfirm = () => {
        if (typeof selectedModal?.onDelete === "function") {
            selectedModal.onDelete(modalObject, currentEntity);
        }

        setModalClosed();
    };

    const onCancelClick = () => {
        setModalClosed();
    };

    const onAddRowClick = async () => {
        const selectedModal = forms[defaultModalIndex];

        await selectedModal.onPreAddModalOpen?.();

        setCurrentEntity({});
        setSelectedModalIndex(defaultModalIndex);
        setModalObject({});
    };

    const onSaveClick = () => {
        if (metaFormIsValid(selectedModal.fields, modalObject)) {
            selectedModal.onSave(modalObject, currentEntity);
            setModalClosed();
        }
    };

    const onModalButtonClick = async (modalIndex: number, entity: T) => {
        const selectedModal = forms[modalIndex];

        selectedModal.onPreEditModalOpen?.();

        setCurrentEntity(entity);
        setSelectedModalIndex(modalIndex);
        setModalObject(selectedModal.getFormObjectFromEntity(entity));
    };

    const onConfirmClose = (result: ModalResult) => {
        if (result === ModalResult.Save) {
            onConfirm();
        }

        setConfirmationModalOpen(false);
    };

    const header = useMemo(() => tableRow.map((field, index) => (
        <th
            key={index}
            className={`${"lg" in field ? `col-${field.lg}` : ""}`}
            css={styles.tableThStyle}
        >
            {field.label}
        </th>
    )), [tableRow]);

    const values = (p) => tableRow.map((i, index) => (
        <TableCell key={index}>
            {i.display_component ? i.display_component(p) : p[i.name]}
        </TableCell>
    ));

    return (
        <>
            <ConfirmationModal size="md" isOpen={confirmationModalOpen} onClose={onConfirmClose}>
                Are you sure you want to delete this {selectedModal?.title}?
            </ConfirmationModal>

            <Modal isOpen={isModalOpen} size="l" onClosed={onCancelClick}>
                <ModalHeader><span>{selectedModal?.title || title}</span></ModalHeader>

                <ModalBody>
                    <MetaForm
                        meta={selectedModal?.fields || []}
                        setValue={(field: string, value: unknown) => {
                            if (selectedModal.onFieldValueChange) {
                                setModalObject(selectedModal.onFieldValueChange(field, value, modalObject, currentEntity));
                            } else {
                                setModalObject({ ...modalObject, [field]: value, });
                            }
                        }}
                        object={modalObject}
                        config={config}
                    />
                </ModalBody>

                <ModalFooter css={styles.modalFooterStyles}>
                    {modalObject.id && deletable && (
                        <Button color="danger" outline onClick={onDeleteClick}>Delete</Button>
                    )}

                    <div css={styles.rightAlignButtons}>
                        <Button color="secondary" onClick={onCancelClick}>Cancel</Button>
                        <Button color="primary" onClick={onSaveClick}>Save</Button>
                    </div>
                </ModalFooter>
            </Modal>

            <Container className="d-flex flex-column" css={css`min-height: 50vh;`} >
                {isLoading ? <Loading /> : (
                    <>
                        {createable && (
                            <Button className="m-2" outline color="primary" onClick={onAddRowClick}>
                                    Add {title}
                            </Button>
                        )}

                        <Table size="sm" responsive={true} borderless={true}>
                            <thead>
                                <tr>
                                    {header}

                                    {forms.length > 0 && (
                                        <th className={`col-${actionsColumnWidth}`} css={styles.tableThStyle} />
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {results.map((result, index) => (
                                    <tr key={index} title={result.id} css={styles.tableRow}>
                                        {values(result)}

                                        {!!forms.length && (
                                            <TableCell className="text-end">
                                                {forms
                                                    .filter((f) => !f.modalButtonHidden)
                                                    .map((modal, index) => (
                                                        <Button
                                                            key={index}
                                                            size="sm"
                                                            className="m-1"
                                                            color="light"
                                                            onClick={() => onModalButtonClick(forms.indexOf(modal), result)}
                                                        >
                                                            {modal.modalButtonLabel}
                                                        </Button>
                                                    ))}
                                            </TableCell>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </Table >
                    </>
                )}

                <PaginationRow
                    results={results}
                    totalCount={items.count}
                    filter={filter}
                    onPageChanged={onPageChanged}
                    updateLimit={updateLimit}
                />
            </Container>
        </>
    );
};
