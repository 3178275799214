import { css } from "@emotion/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import {
    assignDeviceToTenant,
    deleteDevice,
    listDevices,
    selectDevice
} from "../../actions/Devices/actions";
import { listTenants } from "../../actions/Tenants/actions";
import useConfig from "../../actions/Tenants/config/configHook";
import { Loading } from "../Helper/Loading";
import DeviceLastSeen from "./DeviceLastSeen";
import DeviceTenantDropdown from "./DeviceTenantDropdown";

export const DataTestId = {
    LIST: "LIST",
    LIST_FTA: "LIST_FTA",
};

export function DeviceList() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const devices = useSelector((state) => state.devices.devices) || [];
    const minis = devices.filter((i) => i.type === "mini");
    const ftas = devices.filter((i) => i.type === "fta");
    const selected_mini = useSelector((state) => state.devices.selected_mini);
    const selected_fta = useSelector((state) => state.devices.selected_fta);
    const isLoading = useSelector((state) => state.devices.isLoading);
    const config = useConfig();

    const dispatchListDevices = () => {
        dispatch(listDevices());
    };
    // do init load of minis and fetch hearbeat and repeat
    useEffect(() => {
        dispatchListDevices();
        const process = setInterval(dispatchListDevices, 45000);
        return () => clearInterval(process);
    }, []);

    // Fetch list of tenants on component mount
    useEffect(() => {
        if (config.is_admin) {
            dispatch(listTenants());
        }
    }, []);

    if (isLoading || !devices) {
        return <div css={css`min-height: 100vh;`}>
            <Loading></Loading>
        </div>;
    }
    return (
        <>
            {
                minis.length > 0 && <>
                    <h3 className="text-primary py-2">Mini</h3>
                    <ListGroup data-testid={`${DataTestId.LIST}`}>
                        {minis.map((i) => <ListGroupItem key={`mini-${i.UID}`}>
                            <div className="d-lg-flex justify-content-between align-items-center py-2">
                                <div>
                                    <strong>{i.name}</strong>
                                    <div className="text-muted text-small"><DeviceLastSeen device={i}/></div>
                                </div>
                                <div className="d-flex align-items-end pt-1 ">
                                    {config.is_admin && <div className="me-3 mb-1 mb-sm-0" css={css`width: 150px;`} >
                                        <DeviceTenantDropdown device={i} onChange={(tenant_id) => dispatch(assignDeviceToTenant({ ...i, tenant_id, tenant: tenant_id }))} />
                                    </div>}
                                    { config.is_admin && <Button className="me-3 mb-1 mb-sm-0" disabled={selected_mini?.UID === i.UID} color="danger" outline onClick={() => dispatch(deleteDevice(i.UID))} >Delete</Button>}
                                    {(i.tenant_id === user.tenant.tenant_id || user.email.endsWith("@experiencedata.nl")) && <Button color="success" outline={selected_mini?.UID !== i.UID} onClick={() => dispatch(selectDevice(i))} > Select </Button>}
                                </div>
                            </div>
                        </ListGroupItem>)}
                    </ListGroup>
                </>
            }
            {
                ftas.length > 0 && <>
                    <h3 className="text-primary py-2">FTA</h3>
                    <ListGroup data-testid={`${DataTestId.LIST_FTA}`}>
                        {ftas.map((i) => <ListGroupItem key={`fta-${i.UID}`}>
                            <div className="d-lg-flex justify-content-between align-items-center py-2">
                                <div>
                                    <strong>{i.name}</strong>
                                    <div className="text-muted text-small"><DeviceLastSeen device={i}/></div>
                                </div>
                                <div className="d-flex align-items-end pt-1 ">
                                    {config.is_admin && <div className="me-3 mb-1 mb-sm-0" css={css`width: 150px;`} >
                                        <DeviceTenantDropdown device={i} onChange={(tenant_id) => dispatch(assignDeviceToTenant({ ...i, tenant_id, tenant: tenant_id }))} />
                                    </div>}
                                    { config.is_admin && <Button className="me-3 mb-1 mb-sm-0" disabled={selected_fta?.UID === i.UID} color="danger" outline onClick={() => dispatch(deleteDevice(i.UID))} >Delete</Button>}
                                    {(i.tenant_id === user.tenant.tenant_id || user.email.endsWith("@experiencedata.nl")) && <Button color="success" outline={selected_fta?.UID !== i.UID} onClick={() => dispatch(selectDevice(i))} > Select </Button>}
                                </div>
                            </div>
                        </ListGroupItem>)}
                    </ListGroup>
                </>
            }
        </>
    );
}
