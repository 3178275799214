/** @jsxImportSource @emotion/react */

import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import { patchLayer } from "../../../../actions/Layers/actions";

export function InvalidLayerModal(props) {
    const {
        layer
    } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isInvalidModalOpen, toggleInvalidModal] = useState(false);


    const markInvalid = () => {
        if (layer) {
            dispatch(patchLayer(layer.id, "active", false) as any).then((response) => {
                if (!response.error) {
                    toggleInvalidModal(false);
                    if (layer.parents.length > 0) {
                        const layer_id = layer.parents[0].id;
                        navigate(`/layer/${layer_id}`);
                    } else navigate(`/layer`);
                }
            });
        }
    };
    return (
        <>
            <div className="ps-2"><Button size="sm" className="mb-2 text-nowrap" color="danger" outline={true} onClick={() => toggleInvalidModal(true)}>Delete <FontAwesomeIcon icon={faTrash} /></Button></div>
            <Modal isOpen={isInvalidModalOpen} className="modal-lg" toggle={() => toggleInvalidModal(!isInvalidModalOpen)}>
                <ModalHeader toggle={() => toggleInvalidModal(!isInvalidModalOpen)} >{`${layer.type} ${layer.label}`}</ModalHeader>
                <ModalBody>
                    <span>Please mark the {layer.type} as invalid if you think something went wrong.</span>
                </ModalBody>
                <ModalFooter>
                    <Button color="light" onClick={() => toggleInvalidModal(!isInvalidModalOpen)}>Cancel</Button>
                    <Button color="danger" onClick={() => markInvalid()}>Mark Invalid</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

InvalidLayerModal.propTypes = {
    layer: PropTypes.object,
};
