import {
    CREATE,
    DELETE,
    GET,
    LIST,
    UPDATE,
} from "./actionsTypes";

const endpoint = "tenants/";

export {
    createTenantDefect, deleteAllTenantDefect, deleteTenantDefect, getAllTenantDefects, getTenantDefects, importTenantDefect,
    resetAllTenantDefect, updateTenantDefect
} from "./tenantDefectActions";

export {
    createTenantConfig, getAllActiveTenantConfigs, getTenantConfig, getTenantConfigRevisions, patchTenantConfig, resetAllTenantConfig, updateTenantConfigState, patchTenantConfigPosition
} from "./tenantConfigActions";

export {
    createTenantFieldOption, deleteAllTenantFieldOption, deleteTenantFieldOption, getAllTenantFieldOptions, getTenantFieldOptions, importTenantFieldOption,
    resetAllTenantFieldOption, updateTenantFieldOption
} from "./tenantFieldOptionsActions";

export {
    createTenantFacilityLocation, deleteAllTenantFacilityLocations, deleteTenantFacilityLocation, importTenantFacilityLocation, listTenantFacilityLocations, resetAllTenantFacilityLocation, updateTenantFacilityLocation
} from "./tenantFacilityLocationActions";

export function listTenants() {
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                method: "GET",
                url: endpoint,
            },
        },
    };
}

export function getTenant(tenant_id) {
    return {
        types: [GET.LOADING, GET.SUCCESS, GET.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint + tenant_id}/`,
            },
        },
    };
}
export function createTenant(data) {
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: endpoint,
                data,
            },
        },
    };
}

export function deleteTenant(tenant_id) {
    return {
        types: [DELETE.LOADING, DELETE.SUCCESS, DELETE.ERROR],
        payload: {
            request: {
                method: "DELETE",
                tenant_id,
                url: `${endpoint + tenant_id}/`,
            },
        },
    };
}

export function updateTenant(data) {
    const { tenant_id } = data;
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                method: "PUT",
                tenant_id,
                url: `${endpoint + tenant_id}/`,
                data,
            },
        },
    };
}

export function updateTenantState(data) {
    return {
        type: GET.SUCCESS,
        payload: { data },
    };
}

