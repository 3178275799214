

import {
    DeviceResultActions,
    baseConfig, default_location_config,
    ripening_colors
} from "../constants";
import { RootConfigType } from "../constantsTyped";

const dry_matter_classes = [
    {
        gte: false,
        lt: 21.5,
        label: "Below required range",
        color: ripening_colors.Red,
    },
    {
        gte: 21.5,
        lt: 29,
        label: "Within range",
        color: ripening_colors.Green,
    },
    {
        gte: 29,
        lt: false,
        label: "Above required range",
        color: ripening_colors.Red,
    },
];

export const AgrovisionConfig: RootConfigType = {
    ...baseConfig,
    use_barcode_scanner: false,
    indicate_quality_at_defects: false,
    strict_check_size: false,
    indicate_soft_avocado_at_internal: false,
    suggest_color_stage: true,
    dry_matter_classes,
    default_location_config: {
        ...default_location_config,
        device_result_action: DeviceResultActions.none,
        show_atron_color: false,
    },
};
