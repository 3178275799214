
/** @jsxImportSource @emotion/react */
import { faUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import useConfig from "../../../../actions/Tenants/config/configHook";
import FilterableConfigList from "./FilterableConfigList";
import SubconfigForm from "./SubConfigForm";

export const default_trigger_config = {
    check_locations: [],
    fruit_types: [],
    layer_types: [],
    only_trigger_for_check_type: undefined,
    trigger_meta: []
};


export default function ColorCodeTriggerForm({ object, setObject, description, attributes = ["check_locations", "fruit_types", "exclude_fruit_types", "fruit_varieties", "exclude_fruit_varieties", "layer_types", "trigger_meta"] }) {
    const config = useConfig();
    const fruitOptions = config.fruit_types.map(({ value, text }) => ({ value, label: `${text} (${value})` }));
    const layerTypeOptions = config.layer_types.map(({ value, text }) => ({ value, label: `${text} (${value})` }));
    const checkLocationOptions = config.check_locations.map(({ value, text }) => ({ value, label: `${text} (${value})` }));
    const getForm = (object) => {
        const form = [
            // {
            //     type: "header",
            //     label: "Triggers conditions",
            // },
            {
                label: "Trigger on Check Location",
                name: "check_locations",
                description: "Trigger these rules on specific check locations. Leave empty to trigger on all.",
                type: "single-select",
                options: checkLocationOptions,
                return_array: true,
                multi: true,
            },
            {
                label: "Trigger on Layer Types",
                name: "layer_types",
                description: "Trigger these rules on specific layer types. Leave empty to trigger on all.",
                type: "single-select",
                options: layerTypeOptions,
                return_array: true,
                multi: true,
            },
            {
                label: "Trigger on Fruit Types",
                name: "fruit_types",
                description: "Only trigger these rules on specific fruit types. Leave empty to trigger on all.",
                type: "single-select",
                return_array: true,
                options: fruitOptions,
                multi: true,
            },
            {
                label: "Ignore Fruit Types",
                name: "exclude_fruit_types",
                description: "Do not trigger these rules on specific fruit types. Leave empty to trigger on all.",
                type: "single-select",
                return_array: true,
                options: fruitOptions,
                multi: true,
            },
            {
                label: "Only trigger on specific check",
                name: "manual_or_with_mini",
                description: "Only trigger on specific checks. Leave empty to trigger on all.",
                options: [
                    {
                        value: "only_on_mini_check",
                        label: "Only on mini checks"
                    },
                    {
                        value: "only_on_manual_check",
                        label: "Only on manual checks"
                    },
                ],
                type: "single-select",
            },
            {
                type: "element",
                name: "trigger_meta",
                el: <TriggerMetaForm name="tigger_meta" title="Trigger meta" description="Define meta data to trigger on" fields={object.trigger_meta || []} setArray={(trigger_meta) => setObject({ ...object, trigger_meta })} />
            }
        ];

        return form.filter((i) => i.type === "header" || attributes.includes(i.name as any));
    };
    return <SubconfigForm
        name="trigger"
        title="Trigger"
        description={description}
        getForm={getForm}
        subconfig={object}
        setSubconfig={setObject}
        defaultSubconfig={default_trigger_config}
    />;
}


const defaultField = {
    field: {
        meta_location: "layer_meta",
        field: "sup_ref",
        options_name: "sup_ref",
        include_values: [],
        exclude_values: [],
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
        // exclude_layer_types: [],
    }
};

export function TriggerMetaForm({ name, title, fields, description, setArray }) {
    const { tenant_id } = useParams();
    const getFieldTitle = (object) => {
        const link = <Link to={`/tenants/${tenant_id}/form-field-options/${object.options_name}`} target="_blank" rel="noopener noreferrer" >{object.meta_location} [{object.options_name}]<FontAwesomeIcon icon={faUpRightFromSquare} /> </Link>;
        // Generate string for included values
        const includeValuesString = object.include_values && object.include_values.length > 0
            ? `incl: ${object.include_values.join(", ")}`
            : "";
        // Generate string for excluded values
        const excludeValuesString = object.exclude_values && object.exclude_values.length > 0
            ? `excl: ${object.exclude_values.join(", ")}`
            : "";
        // Combine the options name with include and exclude values strings, separating with a space when necessary
        const titleParts = [includeValuesString, excludeValuesString].filter((part) => part !== "");
        return <span><span className="me-3">{link}</span> {titleParts.join(" - ")}</span>;
    };
    const getFieldForm = (object, setObject) => {

        return [
            {
                label: "Meta location",
                type: "single-select",
                name: "meta_location",
                description: "Where to find the meta data. e.g. layer_meta, check_meta or fruit_meta",
                options: [
                    { label: "Layer meta", value: "layer_meta" },
                    { label: "Check meta", value: "check_meta" },
                    { label: "Fruit meta", value: "fruit_meta" },
                ]
            },
            {
                label: "Field",
                type: "text",
                description: "The meta field name to look for. e.g. sup_ref, country, etc.",
                name: "field",
                onchange_callback: ({ field, value }) => {
                    setObject({ ...object, [field]: value, options_name: value });
                }
            },
            {
                label: "Options name",
                type: "text",
                description: "The field name to look for managed options. Most of the times will be the same as field.",
                name: "options_name",
            },
            {
                label: "Include values",
                type: "select-managed",
                options_name: object.options_name,
                name: "include_values",
                return_array: true,
                multi: true
            },
            {
                label: "Exclude values",
                type: "select-managed",
                options_name: object.options_name,
                name: "exclude_values",
                return_array: true,
                multi: true
            },

        ];

    };
    return <FilterableConfigList
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle}
        useDisplayFilter={false}
    />;

}


