/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

export const navItem = css`
    color: black;
    min-width: 2.5rem;
`;

export const headerWrapper = css`
    min-height: 56px;

    @media (min-width: 992px) {
        height: 72px;
    }
`;
