/** @jsxImportSource @emotion/react */
// TODO make defect distribution configurable
import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { css } from "@emotion/react";
import { getLayerChecks } from "../../../../../actions/Layers/actions";
import useConfig, { useTenantFormOptions } from "../../../../../actions/Tenants/config/configHook";
import { calculateMinMaxAvg, value_to_category } from "../../../../../actions/Tenants/config/utils";
import { isFilledArray } from "../../../../../utils";
import BarChartHorizontaMaturity from "../../../Layers/Layer/BarChartHorizontaMaturity";
import CategoryDistribution from "../../../Layers/Layer/CategoryDistribution";
import { DefectGroupSummary } from "../VisualCheck";


export default function PalletSummary() {
    const config = useConfig();
    const children = useSelector((state) => state.layers.children);
    const check = useSelector((state) => state.checks.current);
    const layer = useSelector((state) => state.layers.current);
    const location = config.get_location(layer, check);
    const form_options = useTenantFormOptions();
    const fruitConfig = config.get_fruit_type(layer.fruit_type);
    const dispatch = useDispatch();

    const initialMaturity = form_options.manual_maturity_mango;

    const loadSiblingChecks = location?.summary_min_max_breakdown.find((i) => i.speed);

    useEffect(() => {
        if (loadSiblingChecks) {
            dispatch(getLayerChecks(layer.id, {}));
        }
    }, [check.layer_id, loadSiblingChecks]);

    const { summary_min_max_breakdown } = location;
    const summaryLayerFieldsList = ["summary_layer_fields_1", "summary_layer_fields_2", "summary_layer_fields_3", "summary_layer_fields_4"];
    return (<><div className="d-flex flex-lg-row flex-column align-items-center pb-5" css={css`flex-wrap: wrap;`}>
        {summaryLayerFieldsList.map((field, fieldIndex) => (
            location?.[field] && (
                <div key={fieldIndex} className="pe-5 me-auto align-self-start">
                    <table>
                        <tbody>
                            {location[field].map((i, index) => (
                                <tr key={index}>
                                    <th>{i.label}: </th>
                                    <td className="ps-3 text-capitalize">
                                        {i.set_value({
                                            config,
                                            layer,
                                            check, // * To be used in check tables and layer overview
                                            fruit: null, // * Fruit list is not available on overview screen
                                            children,
                                            form_options // * used to translate form values to labels
                                        }).display_component()}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )
        ))}
        {summary_min_max_breakdown && summary_min_max_breakdown.map((i, index) => <MinMaxBreakDown key={`${i.field}-${index}`} summary_min_max_breakdown={i} />)}

        {layer.fruit_type === "mango" && location?.value === "returns" && <div className="pe-5">
            <div className="fw-bold">Maturity histogram</div>
            <BarChartHorizontaMaturity fruits={check.avocados} initialMaturity={initialMaturity} style={({ width: "300px", height: "140px" })} />
        </div>}

        {/*  {layer.fruit_type === "mango" && location?.value === "returns" && <div className="pe-5">
            <div className="fw-bold">Internal defects</div>
            <DefectDistribution fruits={internalData} style={({ width: "300px", height: "140px" })} />
            </div>} */}

        {location?.summary_category_charts && location.summary_category_charts.map((cat, index) => <div key={index} className="pe-5 align-self-start">
            {cat.title && <div className="fw-bold">{cat.title}</div>}
            <CategoryDistribution catField={cat.field} categories={cat.field === "atron_pressures_median" ? fruitConfig.firmness_class : cat.categories} layer={layer} fruits={check.avocados} style={({ width: "300px", height: "140px" })} />
        </div>)}
    </div>
    {isFilledArray(location.defect_groups) && <div className="d-flex flex-lg-row flex-column align-items-center pb-5 pt-5 border-top"><DefectGroupSummary /></div>}
    </>);
}


function MinMaxBreakDown({ summary_min_max_breakdown }) {
    const checksLoading = useSelector((state) => state.layers.checksLoading);
    const checks = useSelector((state) => state.layers.checks);
    const check = useSelector((state) => state.checks.current);

    const getRipeningSpeed = useCallback(() => {
        if (checksLoading || !checks || checks.length === 0) {
            return "-";
        }
        const ordered = checks.filter((i) => i.fruit).sort((a, b) => a.test_id - b.test_id);
        const index = ordered.findIndex((i) => i.test_id === check.test_id);
        if (index <= 0) {
            return "0%";
        }
        const old = ordered[index - 1].atron_pressures_median_avg;
        const current = ordered[index].atron_pressures_median_avg;

        if (current > old || old === 0) {
            return "0%";
        }

        const speed = ((100 * (old - current)) / old).toFixed(0);
        return `${speed}%`;
    }, [checks, checks?.length, checksLoading]);

    const aggregated = calculateMinMaxAvg(check.avocados.map((i) => i[summary_min_max_breakdown.field]), summary_min_max_breakdown?.unity || "");

    if (summary_min_max_breakdown.categories) {
        aggregated.label = value_to_category(aggregated.avg_raw, summary_min_max_breakdown.categories).label;
    }

    return (
        <div className="pe-5 me-auto align-self-start">
            <table>
                <tbody>
                    <tr>
                        <th>Maximum{summary_min_max_breakdown.label ? ` ${summary_min_max_breakdown.label}` : ""}:</th>
                        <td className="ps-3">{aggregated.max}</td>
                    </tr>
                    <tr>
                        <th>Minimum{summary_min_max_breakdown.label ? ` ${summary_min_max_breakdown.label}` : ""}:</th>
                        <td className="ps-3">{aggregated.min}</td>
                    </tr>
                    <tr>
                        <th>Average{summary_min_max_breakdown.label ? ` ${summary_min_max_breakdown.label}` : ""}:</th>
                        <td className="ps-3">{aggregated.avg}</td>
                    </tr>
                    {summary_min_max_breakdown.categories
                        && <tr>
                            <th>{summary_min_max_breakdown.total_label}</th>
                            <td className="ps-3">{aggregated.label} </td>
                        </tr>
                    }
                    {summary_min_max_breakdown.speed
                        && <tr>
                            <th>Ripening speed:</th>
                            <td className="ps-3">{getRipeningSpeed()}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>);
}

MinMaxBreakDown.propTypes = {
    summary_min_max_breakdown: PropTypes.object.isRequired
};
