/** @jsxImportSource @emotion/react */
import { useSelector } from "react-redux";
import { useMemo } from "react";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { headerLinks } from "../../../../actions/Tenants/config/constants";
import { isFilledArray } from "../../../../utils";
import { generalLinks } from "./GeneralLink";

export const NavMainLinks = () => {
    // @todo: proper ts
    const { user } = useSelector((state: any) => state.auth);

    const config = useConfig();
    const primaryHeaderLinks = config.root_config?.primary_header_links;

    const hasPrimaryHeaderLinks = useMemo(() => isFilledArray(primaryHeaderLinks), [primaryHeaderLinks]);

    const navMainLinks = useMemo(() => {
        if (hasPrimaryHeaderLinks) {
            return primaryHeaderLinks.filter((item) => generalLinks[item.path]).map((item) => generalLinks[item.path](item.label));
        }

        const dynamicLinks = user.tenant?.show_powerbi_link ? [headerLinks.insights] : [];
        const linksToShow = [...config.headerMainLinks, ...dynamicLinks];

        return linksToShow.map((path) => generalLinks[path](null));
    }, [user, hasPrimaryHeaderLinks]);

    return navMainLinks;
};
