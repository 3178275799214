/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import { Container } from "reactstrap";
import { HeaderWithCloseAndNoNav } from "./HeaderWithCloseAndNoNav";
import StyledBox from "./StyledBox";

interface FullPageLayoutProps {
    children: React.ReactNode;
    header?: React.ReactNode;
    title?: string;
    breadcrumbs?: React.ReactNode;
    // withLogo?: boolean; // never used ?
    withStyledBox?: boolean;
    fluidContainer?: boolean;
}

/**
 * FullPageLayout component
 *
 * This component provides a flexible layout for full-page views, optionally including:
 * - A header (either custom or generated from `title` and `breadcrumbs`).
 * - A styled content box (`StyledBox`) if `withStyledBox` is enabled.
 * - A fluid or fixed-width container (`fluidContainer`).
 *
 * It is designed to wrap children components in a structured layout.
 * Mostly used as children in <DefaultLayout fullscreen=true/>
 */

const FullPageLayout = ({ withStyledBox, children, header, title, breadcrumbs, fluidContainer = false }: FullPageLayoutProps) => {
    const pageHeader = useMemo(() => {
        if (header) {
            return header;
        }
        if (title) {
            return <Container fluid={fluidContainer}>
                <HeaderWithCloseAndNoNav title={title} breadcrumbs={breadcrumbs} />
            </Container>;
        }
        return null;
    }, [header, title, breadcrumbs, fluidContainer]);

    return (
        <div>
            {pageHeader}

            <Container fluid={fluidContainer} className={header || title ? "pb-5" : "py-5" }>
                <div >
                    {withStyledBox
                        ? <StyledBox>{children}</StyledBox>
                        : children
                    }
                </div>
            </Container>
        </div>
    );
};

export default FullPageLayout;
