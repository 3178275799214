import { Input, FormGroup, Label, Button } from "reactstrap";
import { faSync } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormFtaDeviceField } from "../../actions/Tenants/config/constantsTyped";
import { RootState } from "../../reducers";
import { createDeviceEvent, pollCheckDevice } from "../../actions/Devices/actions";
import { DeviceEventType } from "../../actions/Devices/constants";
// import { listenForTriggerDeviceEvents } from "../Devices/TriggerDeviceButton";
import { getCheck } from "../../actions/Checks/actions";

export interface FtaDeviceFieldProps {
    item: FormFtaDeviceField;
    object: any;
    setValue: (name:string, value: any) => void;
    disabled: boolean;
}

interface FieldProps {
    type: "text" | "number" | "checkbox";
    value: any;
    onChange: (value: any) => void;
    disabled: boolean;
}

const InputField = ({ type, value, onChange, disabled }: FieldProps) => {
    return (
        <Input
            type={type}
            value={value || ""}
            onChange={(e) => onChange(type === "number" ? Number(e.target.value) : e.target.value)}
            disabled={disabled}
        />
    );
};

const CheckboxField = ({ type, value, onChange, disabled }: FieldProps) => {
    return (
        <Input
            type={type}
            checked={value || false}
            onChange={(e) => onChange(e.target.checked)}
            disabled={disabled}
        />
    );
};

const getFieldComponent = (type: "text" | "number" | "checkbox") => {
    const fieldMap = {
        text: InputField,
        number: InputField,
        checkbox: CheckboxField,
    };
    return fieldMap[type] || InputField;
};

const checkTypeMismatch = (value: any, type: FormFtaDeviceField["data_value_type"]) => {
    if (value === undefined) return false;
    if (type === "text") return typeof value !== "string";
    if (type === "number") return typeof value !== "number";
    if (type === "boolean") return typeof value !== "boolean";
    return false;
};

const getInputTypeFromDataType = (type: any): "number" | "checkbox" | "text" => {
    if (type === "string") return "text";
    if (type === "number") return "number";
    if (type === "boolean") return "checkbox";
    return "text";
};

export const listenForEventsProcessed = (device_uid: string | undefined, check: any | undefined, setTriggerInProgress: (value: boolean) => void) => {
    const dispatch = useDispatch();
    const processed = useSelector<RootState, any>((state) => state.devices.processed);
    const use_fta_device = check?.use_fta_device && device_uid && check?.test_id;
    useEffect(() => {
        let interval;
        if (use_fta_device) {
            dispatch(pollCheckDevice(device_uid, check.test_id));
            interval = setInterval(() => {
                dispatch(pollCheckDevice(device_uid, check.test_id));
            }, 750);
        }
        return interval !== undefined ? () => clearInterval(interval) : undefined;
    }, [use_fta_device]);

    useEffect(() => {
        if (use_fta_device) {
            dispatch(getCheck(check.test_id, false));
            setTriggerInProgress(false);
        }
    }, [processed.length, use_fta_device]);
};


export function FtaDeviceField({ item, object, setValue, disabled }: FtaDeviceFieldProps) {
    const value = object[item.name];
    const dispatch = useDispatch();
    const check = useSelector<RootState, any>((state) => state.checks.current);
    const selected_fta = useSelector<RootState, any>((state) => state.devices.selected_fta);
    const device_id : string | undefined = selected_fta?.UID;
    // Maintain trigger states for each avocado_id
    const [ftaTriggerStates, setFtaTriggerStates] = useState<{ [key: string]: boolean }>({});
    const [triggerInProgress, setTriggerInProgress] = useState(false);
    // const use_fta_device = check?.use_fta_device && device_id && check?.test_id !== undefined;
    listenForEventsProcessed(device_id, check, setTriggerInProgress);

    let type = getInputTypeFromDataType(item.data_value_type);
    const isThereDataTypeMismatch = checkTypeMismatch(value, item.data_value_type);

    if (isThereDataTypeMismatch) {
        disabled = true;
        type = getInputTypeFromDataType(typeof value);
    }

    useEffect(() => {
        const avocadoId = object.avocado_id;
        if (check?.use_fta_device && device_id && !ftaTriggerStates[avocadoId] && value === undefined) {
            dispatch(createDeviceEvent({
                check_test_id: check.test_id,
                check_fruit_id: avocadoId,
                device_id,
                command: DeviceEventType.TRIGGER,
            }));
            setFtaTriggerStates((prevState) => ({
                ...prevState,
                [avocadoId]: true, // Mark this fruit as triggered
            }));
            setTriggerInProgress(true);
        }
    }, [ftaTriggerStates, value, object.avocado_id, check?.use_fta_device, device_id, check?.test_id]);

    const FieldComponent = getFieldComponent(type);
    const onTriggerClick = () => {
        setValue(item.name, undefined);
        setFtaTriggerStates((prevState) => ({
            ...prevState,
            [object.avocado_id]: false, // Mark this fruit as not triggered
        }));
    };
    return (
        <FormGroup>
            <Label for={item.name}>{item.label}</Label>
            <div className="d-flex align-items-center">
                <FieldComponent
                    type={type}
                    value={value}
                    onChange={() => setValue(item.name, value)}
                    disabled={disabled}
                />
                <Button
                    color={triggerInProgress ? "secondary" : "primary"}
                    onClick={onTriggerClick}
                    className="ms-2"
                    disabled={triggerInProgress}
                >
                    <FontAwesomeIcon icon={faSync} />
                </Button>
            </div>
            {isThereDataTypeMismatch && (
                <small className="text-danger">
                    {`Type mismatch. Expected ${item.data_value_type} but got ${typeof value}`}
                </small>
            )}
        </FormGroup>
    );
}

