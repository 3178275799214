/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Badge, Button,
    Table
} from "reactstrap";
import { listDevices, refreshDevices } from "../../../actions/Devices/actions";
import { listTenants } from "../../../actions/Tenants/actions";
import useConfig from "../../../actions/Tenants/config/configHook";
import { Loading } from "../../Helper/Loading";

import { isMoreThanTwoWeeks, isOffline } from "../../../actions/Devices/util";
import { tenantIds } from "../../../actions/Tenants/config/constants";
import DeviceLastSeen from "../../Devices/DeviceLastSeen";
import FullPageLayout from "../Layout/FullPageLayout";

export default function DeviceManagment() {
    const devices = useSelector((state) => state.devices.devices);
    const isLoading = useSelector((state) => state.devices.isLoading);
    const dispatch = useDispatch();
    const config = useConfig();

    const dispatchListDevices = () => {
        dispatch(listDevices());
    };
    const dispatchRefreshDevices = () => {
        dispatch(refreshDevices());
    };
    // do init load of minis and fetch hearbeat and repeat
    useEffect(() => {
        dispatchListDevices();
        const process = setInterval(dispatchRefreshDevices, 25000);
        return () => clearInterval(process);
    }, []);

    // Fetch list of tenants on component mount
    useEffect(() => {
        if (config.is_admin) {
            dispatch(listTenants());
        }
    }, []);


    if (isLoading || !devices) {
        return <div css={css`min-height: 100vh;`}>
            <Loading/>
        </div >;
    }

    const headers = <thead>
        <tr>
            <th css={css`width: 160px;`}>Device Name</th>
            {/* <th css={css`width: 30px;`}>Type</th> */}
            <th css={css`width: 80px;`}>Joined</th>
            <th css={css`width: 80px;`}>Status</th>
            <th css={css`width: 160px;`}>Last activity</th>
            <th css={css`width: 240px;`}>Tenant</th>
            <th className="text-end" css={css`width: 90px;`} ></th>
        </tr>
    </thead>;
    const internal_tenants = [tenantIds.ExperienceData, tenantIds.master_tenant, tenantIds.repair_shop];
    const EDdevices = devices.filter((i) => internal_tenants.includes(i.tenant_id) && i.type === "mini");
    const otherDevices = devices.filter((i) => !internal_tenants.includes(i.tenant_id) && i.type === "mini");
    return (
        <FullPageLayout>
            {otherDevices.length > 0
                && <div className="mt-5">
                    <h2 className="pb-3">Client Devices</h2>
                    <Table size="sm" responsive>
                        {headers}
                        {otherDevices.map((i) => <DeviceItem key={`${i.UID}`} i={i} />)}
                    </Table>
                </div>
            }
            {EDdevices.length > 0
                && <div className="pt-5">
                    <h2 className="pb-3">Other Devices</h2>
                    <Table size="sm" responsive>
                        {headers}
                        {EDdevices.map((i) => <DeviceItem key={`${i.UID}`} i={i} />)}
                    </Table>

                </div>
            }
            <div>
                <h6 className="text-muted py-3">Last update time: <b>{dayjs().format("HH:mm:ss D MMM YYYY")}</b></h6>
            </div>
        </FullPageLayout>
    );
}

const display_status_of_device = (item) => (isOffline(item) ? "Offline" : "Online");
function DeviceItem({ i }) {
    const navigate = useNavigate();


    const getCellClass = (item) => {
        const isItemOnline = !isOffline(item);
        if (isItemOnline) {
            return "success";
        }

        const delayOverTwoWeeks = isMoreThanTwoWeeks(item);
        if (!delayOverTwoWeeks) {
            return "danger";
        }
        return "light";
    };

    return (
        <tbody>
            <tr>
                <td className="align-middle"><strong>{i.name}</strong></td>
                {/* <td className="align-middle">{i.type}</td> */}
                <td className="align-middle">{i.joinstatus}</td>
                <td className="align-middle"><Badge color={getCellClass(i)}>{display_status_of_device(i)}</Badge></td>
                <td className="align-middle">
                    <DeviceLastSeen device={i} prefix={false} />
                </td>
                <td className="align-middle">{i.tenant?.tenant_name || "-"} </td>
                <td className="text-end">
                    <Button className="ms-2 my-2" size="sm" color="secondary" outline onClick={() => navigate(`/device-managment/${i.UID}`)}>Configuration</Button>
                </td>
            </tr>
        </tbody>
    );
}
DeviceItem.propTypes = {
    i: PropTypes.object
};
