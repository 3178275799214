import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row, Table } from "reactstrap";
import { getRelabelingSesions, resetLabeling } from "../../../actions/Labelling/actions";
import logo from "../../../img/avos-eye.jpg";
import { Loading } from "../../Helper/Loading";
import FullPageLayout from "../Layout/FullPageLayout";

function Settings() {
    const { recentSessions, isLoading } = useSelector((state) => state.labeling);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetLabeling());
        dispatch(getRelabelingSesions());
    }, [dispatch]);

    return (
        <FullPageLayout>
            <Row className="justify-content-center pb-3 mt-5">
                <Col lg="9" >
                    <Row>
                        <Col lg="9" >

                            <h2 className="">Relabelling Section</h2>
                            <p className="text-muted py-10">You can manually check the defects to help your assistant learning more from you</p>
                            <Button color="success" size="lg" outline onClick={() => navigate("/labelling/new")}>New relabelling Session</Button>

                        </Col>
                        <Col lg="3">
                            <div className="pb-2 text-center">
                                <img src={logo} alt="EYE Logo" className="w-100" />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {isLoading && <Loading></Loading>}

            {!isLoading && recentSessions && recentSessions.length > 0
               && <div>
                   <Row className="justify-content-center py-3">
                       <Col lg="9" >
                           <h2 className="">Recent labelling sessions</h2>
                           <Table hover>
                               <thead>
                                   <tr>
                                       <th>From Date</th>
                                       <th>To Date</th>
                                       <th>Defect Type</th>
                                   </tr>
                               </thead>
                               <tbody>
                                   {recentSessions.map((i, index) => (
                                       <tr key={index}>
                                           <td>{ i.from_date }</td>
                                           <td>{ i.to_date }</td>
                                           <td>{ i.defect_type }</td>
                                       </tr>
                                   ))}
                               </tbody>
                           </Table>
                       </Col>
                   </Row>
               </div>
            }
        </FullPageLayout>
    );
}

export default Settings;
