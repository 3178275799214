/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import logo from "../../../img/logo-experience-fruit-quality.png";
import { HeaderWithCloseAndNoNav, HeaderWithCloseAndNoNavProps } from "./HeaderWithCloseAndNoNav";

interface SidebarPageLayoutProps extends HeaderWithCloseAndNoNavProps {
    header?: React.ReactNode;
    sidebar?: React.ReactNode;
    bottomBar?: React.ReactNode;
    withLogo?: boolean;
}

const SidebarPageLayout = ({ title, withLogo, children, header, breadcrumbs, sidebar, bottomBar }: SidebarPageLayoutProps) => {
    return (
        <div css={css`min-width: 80%;`}>
            <Row className="flex-row-reverse g-0">
                <Col xxl="9" xl="8" lg="6" md="12">
                    <div className="bg-white shadow-sm px-2 px-md-5 pb-5" css={css`min-height: 100dvh;`}>
                        <HeaderWithCloseAndNoNav title={title} breadcrumbs={breadcrumbs}>
                            {header}
                        </HeaderWithCloseAndNoNav>

                        {children}

                        {!!bottomBar && (<div css={css`position: fixed;bottom: 0;left:0;right:0; z-index: 1000;`}>
                            <Container className="py-4">
                                <div className=" rounded shadow-lg py-4 px-4 bg-white" >
                                    {bottomBar}
                                </div>
                            </Container>
                        </div>)}
                    </div>
                </Col>

                <Col xxl="3" xl="4" lg="6" md="12">
                    {withLogo && (
                        <div className="p-5 pb-0">
                            <Link to="/" >
                                <img src={logo} alt="" css={css`height: 48px; padding-bottom: 8px;`} />
                            </Link>
                        </div>
                    )}

                    <div className="pt-5 mt-5 px-2 px-md-5 flex flex-column gap-5">
                        {sidebar}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default SidebarPageLayout;
