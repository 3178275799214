/** @jsxImportSource @emotion/react */
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import { DeviceList } from "../../Devices/DeviceList";
import FullPageLayout from "../Layout/FullPageLayout";


function Settings() {
    const user = useSelector((state) => state.auth.user);

    return (
        <FullPageLayout>
            <Row className="justify-content-center pb-3">
                <Col >
                    <h2 className="">Hi {user.first_name}!</h2>
                    <span className="text-muted">You are logged in as <b>{user.username}</b></span>
                </Col>
            </Row>

            <Row className="justify-content-center py-3">
                <Col >
                    <h2 className="">Select Device</h2>
                    <DeviceList />
                </Col>
            </Row>
        </FullPageLayout>
    );
}

export default Settings;
