import {
    baseConfig
} from "../constants";

export const DefaultConfig = {
    ...baseConfig,
    check_summary: false,
    strict_check_size: false,
    indicate_soft_avocado_at_internal: false,
    suggest_color_stage: true,
};
