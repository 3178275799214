/** @jsxImportSource @emotion/react */
import { GROUP_STRATEGY, LAYER_STAGE, UNIT_OF_ACCOUNT } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { DeviceResultActions } from "../../../../actions/Tenants/config/constants";
import MetaForm from "../../../Forms/MetaForm";
import { CTA_FORM_LOCATION } from "../../AddItem/components/Summary/LayerCtaCheckSummary";
import CheckSummaryCategoryChartsConfigForm from "./CheckSummaryCategoryChartsConfigForm";
import CheckSummaryFruitTableConfigForm from "./CheckSummaryFruitTableConfigForm";
import CheckSummaryMinMaxBreakdownConfigForm from "./CheckSummaryMinMaxBreakdownConfigForm";
import DefectGroupConfigForm from "./DefectGroupConfigForm";
import { DisplayFilterForm } from "./DisplayFilterForm";
import FieldConfigForm from "./FieldConfigForm";
import FlowConfigForm from "./FlowConfigForm";
import FormConfigForm from "./FormConfigForm";
import LegacyInlineFormConfigForm from "./LegacyInlineFormConfigForm";
import PressureCalibrationSettingsConfigForm from "./PressureCalibrationSettingsConfigForm";
import RequiredImageConfigForm from "./RequiredImageConfigForm";
import SanitizeUnexpectedFields from "./SanitizeUnexpectedFields";
import { yesOrNoRadio } from "./util";

export const defaultCheckLocation = {
    text: "Your check location",
    value: "your_check_location",
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
        // exclude_layer_types: [],
    },
};


export default function CheckLocationConfigForm({ config_data, setConfigData }) {
    const config = useConfig();

    const form = [
        {
            label: "Label",
            description: "What is the label of this check location. Used in the button.",
            name: "text",
            type: "text"
        },
        {
            label: "Unit of account",
            description: "You can use this if the check is done on a box or fruit. Leaving this empty the system will use Fruit as default.",
            name: "unit_of_account",
            type: "single-select",
            options: Object.keys(UNIT_OF_ACCOUNT).map((i) => ({ value: UNIT_OF_ACCOUNT[i], label: i }))
        },
        {
            label: "Value",
            description: "What is the slug or identifier of this check location. Used in the database and in urls.",
            name: "value",
            type: "text",
            onchange_callback: ({ field, value }) => setConfigData({ ...config_data, [field]: value.trim().replaceAll(" ", "_").toLowerCase() })
        },
        {
            label: "Allocate stage",
            description: "Doing a check on this location will allocate the layer to this stage. Use this if the value of this check location is not a stage. For example calibration check will allocate to ripening.",
            name: "allocate_stage",
            type: "single-select",
            options: Object.keys(LAYER_STAGE).map((i) => ({ value: LAYER_STAGE[i], label: i }))
        },
        {
            label: "Display filter",
            type: "element",
            name: "display_filter",
            el: <DisplayFilterForm
                object={config_data.display_filter}
                setValue={(field, value) => setConfigData({ ...config_data, display_filter: { ...config_data.display_filter, [field]: value } })}
                attributes={["fruit_types", "exclude_fruit_types", "layer_types"]} />
        },
        {
            type: "header",
            label: "RipeWise",
        },
        {
            label: "Group strategy",
            description: "Use this to set the group strategy.",
            name: "group_strategy",
            type: "single-select",
            options: Object.keys(GROUP_STRATEGY).map((i) => ({ value: GROUP_STRATEGY[i], label: i }))
        },
        {
            type: "header",
            label: "Flows and Forms",
        },
        {
            label: "Flow",
            name: "flow",
            type: "element",
            el: <FlowConfigForm
                fields={config_data.flow || []}
                description="Which steps to follow to complete this check"
                name="flow"
                title="Flow"
                setArray={(flow) => setConfigData({ ...config_data, flow })} />
        },
        {
            label: "Inline form",
            name: "inline_form",
            type: "element",
            el: <LegacyInlineFormConfigForm
                fields={config_data.inline_form || []}
                description="This is the legacy way of definng which fields to show in the form. Use `Fruit Form` if you want to use mandatory fields."
                name="inline_form"
                title="Legacy Form"
                setArray={(inline_form) => setConfigData({ ...config_data, inline_form })} />
        },
        {
            label: "Fruit form",
            name: "fruit_form",
            type: "element",
            el: <FormConfigForm
                fields={config_data.fruit_form || []}
                description="Define the form for fruit. This data is stored on a Fruit level."
                name="fruit_form"
                title="Fruit"
                setArray={(fruit_form) => setConfigData({ ...config_data, fruit_form })} />
        },
        {
            label: "Meta form summary",
            name: "meta_form_summary",
            type: "element",
            el: <FormConfigForm
                fields={config_data.meta_form_summary || []}
                description="A form to show in the summary of the check. Mostly used for next_action."
                name="meta_form_summary"
                title="Meta form summary"
                setArray={(meta_form_summary) => setConfigData({ ...config_data, meta_form_summary })} />
        },
        {
            label: "Meta form layer",
            name: "meta_form_layer",
            type: "element",
            el: <FormConfigForm
                fields={config_data.meta_form_layer || []}
                description="A form to show before you start this check. This data is stored on a layer level."
                name="meta_form_layer"
                title="Meta form layer"
                setArray={(meta_form_layer) => setConfigData({ ...config_data, meta_form_layer })} />
        },
        {
            label: "Pre check form",
            name: "pre_check_form",
            type: "element",
            el: <FormConfigForm
                fields={config_data.pre_check_form || []}
                description="A form to show before you start this check. This data is stored on a check level."
                name="pre_check_form"
                title="Pre check form"
                setArray={(pre_check_form) => setConfigData({ ...config_data, pre_check_form })} />
        },
        {
            label: "Required Check Images",
            type: "element",
            name: "required_images",
            el: <RequiredImageConfigForm
                fields={(config_data.required_images || []).map((i) => ({ ...i, label: typeof i.label === "object" ? i.type : i.label })) || []} // Whoops...used <br</br> as label in hardcoded config and forget to sanitze it to a string
                description="If you want images to be taken on all the fruit or the whole pallet. Configure the type of images here. Used on check summary, advance ripening and visual check."
                name="required_images"
                title="Required images"
                setArray={(required_images) => setConfigData({ ...config_data, required_images })} />
        },
        {
            label: "Required Fruit Images",
            type: "element",
            name: "required_fruit_images",
            el: <RequiredImageConfigForm
                fields={(config_data.required_fruit_images || []).map((i) => ({ ...i, label: typeof i.label === "object" ? i.type : i.label })) || []} // Whoops...used <br</br> as label in hardcoded config and forget to sanitze it to a string
                description="If you want images to be taken on a fruit level. Configure the type of images here. Used in the fruit forms."
                name="required_fruit_images"
                title="Required images"
                setArray={(required_fruit_images) => setConfigData({ ...config_data, required_fruit_images })} />
        },
        {
            type: "header",
            label: "Visual Check Settings"
        },
        {
            label: "Visual check form",
            name: "visual_check_form",
            type: "element",
            el: <FormConfigForm
                fields={config_data.visual_check_form || []}
                description="A form to show at the visual check. e.g. sample_size"
                name="visual_check_form"
                title="Visual check form"
                setArray={(visual_check_form) => setConfigData({ ...config_data, visual_check_form })} />
        },
        {
            label: "Visual check defect form",
            name: "visual_check_defect_form",
            type: "element",
            el: <DefectGroupConfigForm
                fields={config_data.visual_check_defect_form || []}
                description="A form to enter defect frequencies at the visual check."
                name="visual_check_defect_form"
                title="Visual check defect form"
                setArray={(visual_check_defect_form) => setConfigData({ ...config_data, visual_check_defect_form })} />
        },
        {
            label: "Visual check option form",
            name: "visual_check_option_form",
            type: "element",
            el: <FormConfigForm
                fields={config_data.visual_check_option_form || []}
                description="A form to add options at the visual check. e.g maturity"
                name="visual_check_option_form"
                title="Visual check option form"
                setArray={(visual_check_option_form) => setConfigData({ ...config_data, visual_check_option_form })} />
        },
        {
            type: "header",
            label: "Advance Ripening Settings"
        },
        {
            label: "Advance ripening start form",
            name: "advance_ripening_start_form",
            type: "element",
            el: <FormConfigForm
                fields={config_data.advance_ripening_start_form || []}
                description="A form to show before starting advance ripening. You will probably want to set the sample size here."
                name="advance_ripening_start_form"
                title="Advance ripening form"
                setArray={(advance_ripening_start_form) => setConfigData({ ...config_data, advance_ripening_start_form })} />
        },
        {
            label: "Advance ripening (firmness) classes",
            name: "advance_ripening_classes_form",
            type: "element",
            el: <FormConfigForm
                fields={config_data.advance_ripening_classes_form || []}
                description="Which classes to collect at advance ripening. (other then defects). Sum of these define sample_size."
                name="advance_ripening_classes_form"
                title="Advance ripening form"
                setArray={(advance_ripening_classes_form) => setConfigData({ ...config_data, advance_ripening_classes_form })} />
        },
        {
            label: "Advance ripening form",
            name: "meta_form_advance_ripening",
            type: "element",
            el: <FormConfigForm
                fields={config_data.meta_form_advance_ripening || []}
                description="A form to show at the end of advance ripening. For example a next action."
                name="meta_form_advance_ripening"
                title="Advance ripening form"
                setArray={(meta_form_advance_ripening) => setConfigData({ ...config_data, meta_form_advance_ripening })} />
        },
        {
            label: "Show visual check in advance ripening",
            name: "advance_ripening_show_external_defects",
            description: "Show visual check in the advance ripening form and check card. Configure visual_check_defect_form to define which defects to show.",
            ...yesOrNoRadio
        },
        {

        },
        {
            type: "header",
            label: "Report & summary options",
        },

        {
            label: "Summary layer fields PDF",
            type: "element",
            name: "summary_layer_fields_pdf",
            el: (
                <FieldConfigForm
                    fields={config_data.summary_layer_fields_pdf || []}
                    description="What layer meta to show in pdf above the fruit table in the pdf check summary. (Check Summary Pdf)"
                    name="summary_layer_fields_pdf"
                    title="Layer fields PDF"
                    setArray={(summary_layer_fields_pdf) => setConfigData({ ...config_data, summary_layer_fields_pdf })}
                />
            )
        },
        {
            label: "Summary layer fields col 1",
            type: "element",
            name: "summary_layer_fields_1",
            el: (
                <FieldConfigForm
                    fields={config_data.summary_layer_fields_1 || []}
                    description="What layer meta to show in column 1 above the fruit table in the check summary."
                    name="summary_layer_fields_1"
                    title="Summary layer fields col 1"
                    setArray={(summary_layer_fields_1) => setConfigData({ ...config_data, summary_layer_fields_1 })}
                />
            )
        },
        {
            label: "Summary layer fields col 2",
            type: "element",
            name: "summary_layer_fields_2",
            el: (
                <FieldConfigForm
                    fields={config_data.summary_layer_fields_2 || []}
                    description="What layer meta to show in column 2 above the fruit table in the check summary."
                    name="summary_layer_fields_2"
                    title="Summary layer fields col 2"
                    setArray={(summary_layer_fields_2) => setConfigData({ ...config_data, summary_layer_fields_2 })}
                />
            )
        },
        {
            label: "Summary layer fields col 3",
            type: "element",
            name: "summary_layer_fields_3",
            el: (
                <FieldConfigForm
                    fields={config_data.summary_layer_fields_3 || []}
                    description="What layer meta to show in column 3 above the fruit table in the check summary."
                    name="summary_layer_fields_3"
                    title="Summary layer fields col 3"
                    setArray={(summary_layer_fields_3) => setConfigData({ ...config_data, summary_layer_fields_3 })}
                />
            )
        },
        {
            label: "Summary layer fields col 4",
            type: "element",
            name: "summary_layer_fields_4",
            el: (
                <FieldConfigForm
                    fields={config_data.summary_layer_fields_4 || []}
                    description="What layer meta to show in column 4 above the fruit table in the check summary."
                    name="summary_layer_fields_4"
                    title="Summary layer fields col 4"
                    setArray={(summary_layer_fields_4) => setConfigData({ ...config_data, summary_layer_fields_4 })}
                />
            )
        },
        {
            label: "Min Max value breakdown",
            type: "element",
            name: "summary_min_max_breakdown",
            el: <CheckSummaryMinMaxBreakdownConfigForm
                fields={config_data.summary_min_max_breakdown || []}
                description="For which fields you want to show the min, max and average class in the summary. "
                name="summary_min_max_breakdown"
                title="Min Max value breakdown"
                setArray={(summary_min_max_breakdown) => setConfigData({ ...config_data, summary_min_max_breakdown })} />
        },
        {
            label: "Category chart",
            type: "element",
            name: "summary_category_charts",
            el: <CheckSummaryCategoryChartsConfigForm
                fields={config_data.summary_category_charts || []}
                description="For which fields do you want to show a pie/bar chart in the summary. "
                name="summary_category_charts"
                title="Category chart"
                setArray={(summary_category_charts) => setConfigData({ ...config_data, summary_category_charts })} />
        },
        {
            label: "Defect groups",
            name: "defect_groups",
            type: "element",
            el: <DefectGroupConfigForm
                fields={config_data.defect_groups || []}
                description="What group of defects do you want to show at the header of summary and pdf page?"
                name="defect_groups"
                title="Defect groups"
                setArray={(defect_groups) => setConfigData({ ...config_data, defect_groups })} />
        },
        {
            label: "Summary fruit fields",
            type: "element",
            name: "summary_fruit_fields",
            el: <CheckSummaryFruitTableConfigForm
                fields={config_data.summary_fruit_fields || []}
                description="Fields to show in the fruit table in the check summary"
                name="summary_fruit_fields"
                title="Summary fruit fields" setArray={(summary_fruit_fields) => setConfigData({ ...config_data, summary_fruit_fields })} />
        },
        {
            type: "header",
            label: "Pressure calibration",
        },
        {
            label: "Pressure Calibration Settings",
            type: "element",
            name: "pressure_calibration_settings",
            el: <PressureCalibrationSettingsConfigForm
                fields={config_data.pressure_calibration_settings || []}
                description="Settings for pressure calibration"
                name="pressure_calibration_settings"
                title="Pressure Calibration Settings"
                setArray={(pressure_calibration_settings) => setConfigData({ ...config_data, pressure_calibration_settings })} />
        },
        {
            label: "Device result action",
            description: "If we are using a device, what should happen right after we received fruit results from the device?",
            name: "device_result_action",
            type: "single-select",
            options: Object.keys(DeviceResultActions).map((i) => ({ value: i, label: DeviceResultActions[i] }))
        },
        {
            type: "header",
            label: "QC Planning or other CTA form for layer",
        },
        {
            label: "Call to Action title",
            name: "cta_form_title",
            description: "Title of the call to action form.",
            type: "text"
        },
        {
            label: "Where to show the CTA form",
            name: "cta_form_location",
            type: "single-select",
            options: [
                { value: CTA_FORM_LOCATION.MODAL_AFTER_SUBMIT, label: "Modal after submit" },
                { value: CTA_FORM_LOCATION.BELOW_CHECK_SUMMARY, label: "Below Check summary (inline)" },
            ]
        },
        {
            type: "element",
            el: <FormConfigForm
                fields={config_data.cta_form || []}
                description="Call to action form for the layer at check summary."
                name="cta_form"
                title="Filter"
                setArray={(cta_form) => setConfigData({ ...config_data, cta_form })} />
        },
        {
            type: "header",
            label: "Other options",
        },
        {
            label: "Bulk input modal",
            description: "If you want to use the bulk input modal for this check location, set this to the field you want to paste values for. Use in conjunction with `use_bulk_edit` in pre check form.",
            name: "bulk_input_modal",
            type: "single-select",
            options: ["pressure", "2_pressures", "weight"].map((i) => ({ value: i, label: i }))
        },
        {
            label: "Enable duplicate fruit button",
            description: "Enable button to duplicate latest fruit",
            name: "enable_duplicate_fruit",
            ...yesOrNoRadio
        },
        {
            label: "Require another check",
            description: "Once used to differentiate between first-ripening check and regular ripening checks. Not in use anymore.",
            name: "require_another_check",
            type: "single-select",
            options: config.check_locations.map(({ value, text }) => ({ value, label: `${text} (${value})` }))
        },
        {
            label: "Is hidden",
            description: "Hide the button to start this check. For example if you want to start an intake check from the PO, you can hide it from the pallet here.",
            name: "is_hidden",
            ...yesOrNoRadio
        },
        {
            label: "Show fruit per box",
            description: "Divide the fruit per box by box_size. If you have box size 10 and 13 fruit, you will get 2 boxes. Setting this to false will always show one box.",
            name: "show_fruit_per_box",
            ...yesOrNoRadio
        },
        {
            label: "Only show latest check on child",
            description: "Set this to true for ART like checks to only show one calender item per ART.",
            name: "only_show_latest_check_on_child",
            ...yesOrNoRadio
        },
        {
            label: "Show pressure prediction",
            description: "Lorem ipsum bla bla",
            name: "show_pressure_prediction",
            ...yesOrNoRadio
        },
        {
            label: "Show device weight",
            description: "Show device weight in device results",
            name: "show_atron_weight",
            ...yesOrNoRadio
        },
        {
            label: "Show device color",
            description: "Show device predicted color class",
            name: "show_atron_color",
            ...yesOrNoRadio
        },
        {
            label: "Show firmness results bottom summary",
            description: "Show medium and average firmness below device results",
            name: "show_atron_results_bottom_summary",
            ...yesOrNoRadio
        },
        {
            label: "Use Submit instead of Start",
            description: "You can use Submit if there is no check to create. Currently, only used in the Arrival check.",
            name: "submit_instead_of_start_check",
            ...yesOrNoRadio
        },

    ];
    return <div>
        <SanitizeUnexpectedFields form={form} object={config_data} setObject={setConfigData} />
        <MetaForm meta={form} setValue={(field, value) => setConfigData({ ...config_data, [field]: value })} object={config_data} config={config} />
    </div>;
}
