/** @jsxImportSource @emotion/react */
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../Layout/Breadcrumbs";


const RipeningRoomBreadcrumbs = ({ currentLocation }: { currentLocation: any }) => {
    const { location_id } = useParams();


    return (
        <Breadcrumbs
            items={[
                { isHome: true, href: "/layer" },
                {
                    href: currentLocation ? "/ripening-room" : undefined,
                    label: "Rooms",
                },
                ...((currentLocation && currentLocation?.location_id === location_id) ? [
                    {
                        label: currentLocation?.label,
                    },
                ] : []),
            ]}
        />
    );

};

export default RipeningRoomBreadcrumbs;
