/** @jsxImportSource @emotion/react */
import { Button, FormGroup, FormText, Label } from "reactstrap";
import { FormRadioField, OptionFlagged } from "../../actions/Tenants/config/constantsTyped";
import { getFilledArrayOrDefault, isFilledArray } from "../../utils";
import { useManagedOptionsHook } from "./useManagedOptionsHook";

export interface CheckboxButtonProps {
    selected: boolean;
    color: string;
    className: string;
    onClick: () => void;
    disabled?: boolean;
    text: JSX.Element | string;
}

export function CheckboxButton({ text, selected, color, className, onClick, disabled }: CheckboxButtonProps) {
    return (
        <Button color={color} outline={!selected} disabled={disabled} className={["btn-radio", className || ""].join(" ")} onClick={onClick}>
            {text}
        </Button>
    );
}

export interface CheckboxButtonsProps {
    options: FormRadioField["options"];
    selectedOptions: string[] | object[] | string;
    color: string;
    onChange: <T extends Array<any>>(newSelectedOptions: T) => void;
    className?: string;
    return_objects?: true;
    return_array?: true;
}

/**
 * TODO:
 * - Add support for single select
 * https://experiencedata.atlassian.net/browse/EFQ-2093
 */
// * use return_objects if you want to return the array with objects
// * use return array if you want to return the array with values
// * by default will return a comma seperated string, to be stored as meta value as string
export function CheckboxButtons({ options, selectedOptions, color, onChange, className, return_array, return_objects }: CheckboxButtonsProps): any {

    // console.log("render options", options);
    // * what data struct should be dispatched
    const filterBeforeChange = (newSelected) => {
        // * return array of objects
        if (return_objects) {
            return (options as any).filter((i) => newSelected.includes(i.value));
        }
        // * return array of values
        if (return_array) {
            return newSelected;
        }
        // * return string
        return newSelected.join(",");
    };

    // selectedOptions should be represented as an array of values
    let selected: string[] = [];
    if (isFilledArray(selectedOptions)) {
        if (return_objects) {
            selected = getFilledArrayOrDefault(selectedOptions).map((i: any) => i.value);
        } else {
            selected = selectedOptions as string[];
        }
    } else if (typeof (selectedOptions) === "string") {
        if (selectedOptions === "[]") {
            selected = [];
        } else {
            selected = selectedOptions.split(",");
        }
    } else {
        selected = [];
    }


    return <>
        {
            options.filter((i) => !i.hidden).map((i, index) => <CheckboxButton
                key={index}
                text={(i.text || i.label) as string | JSX.Element}
                color={i.color ? i.color : color}
                disabled={i.disabled}
                selected={selected.some((x) => x === i.value)}
                className={`${i.className} ${className}`}
                onClick={() => {
                    let newSelectedOptions = [...selected, i.value];
                    if (selected.some((x) => x === i.value)) {
                        newSelectedOptions = selected.filter((x) => x !== i.value);
                        (document.activeElement as any).blur();
                    }
                    onChange(filterBeforeChange(newSelectedOptions) as any);
                }} />)
        }
    </>;
}

export interface CheckboxButtonManagedProps {
    onChange: (fieldName: string, selectedOptions: Array<OptionFlagged["value"]>) => void;
    object: any;
    selectedOptions: string[] | object[] | string;
    name: string;
    label: string;
    options_name?: string;
    description?: string;
    extra_context?: { // * pass extra context properties without setting them on the main object (e.g. to only show avocado options on check field, while fruit type is not stored on check but on layer)
        fruit_type?: string,
        fruit_variety?: string,
    }
    return_objects?: true;
    return_array?: true;
}

export function CheckboxButtonsManaged({
    onChange, object, selectedOptions, name, label, options_name, description, extra_context, return_objects, return_array
}: CheckboxButtonManagedProps) {
    const _options = useManagedOptionsHook({ object: { ...object, ...extra_context }, name: options_name || name });
    const options = _options.sort();
    return (
        <FormGroup>
            <Label>{label}</Label>
            {description && <FormText className="d-block">{description}</FormText>}
            <div>
                {options.length === 0 && <div className="text-warning">No options configured for {name}</div>}
                <CheckboxButtons
                    color="secondary"
                    options={options.map((i) => ({ ...i, value: i.value, text: i.label }))}
                    selectedOptions={selectedOptions}
                    onChange={(newSelectedOptions) => onChange(name, newSelectedOptions)}
                    className="me-1 mb-1"
                    return_objects={return_objects}
                    return_array={return_array}
                />
            </div>
        </FormGroup>
    );
}
