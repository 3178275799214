/** @jsxImportSource @emotion/react */

import Field, { DataSource } from "./Field";


class RipeningRoomMetaField extends Field {
    field_type = "ripening_room_meta_field";

    backend_table = "ripening_room_meta";

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        this.value = null;
        if (datasource.ripeningRoom?.[this.fieldname_getter]) {
            this.value = datasource.ripeningRoom[this.fieldname_getter];
        }

        this.value = this.get_form_option_label(datasource.form_options, this.value_filter(this.value, datasource));
        this.set_color_code(datasource.ripeningRoom);

        return this;

    }
}

export default RipeningRoomMetaField;


