import { Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { chunk } from "../../../../../utils/chunk";
import { PDFTable, tableStyles } from "./PDFTable";

export function PDFPalletsSummary({ fields, printChildren, config }) {

    // stack the columns, starting with the headers, followed by content and finally the footer
    const headers = fields.map((i, index) => <View key={index} style={[tableStyles.tableCell, tableStyles.tableHeader]} >
        <Text style={tableStyles.tableHeaderText}>{i.label || "\u00A0" }</Text>
    </View>);
    const allColumns = fields.map((i, index) => [
        ...(printChildren.map((p) => <View style={[{ height: "19px", }, tableStyles.tableCell]} key={index + 1}>{i.set_value({
            config,
            layer: p,
            check: p.latest_check, // * To be used in check tables and layer overview
            fruit: null, // * Fruit list is not available on overview screen
            children: null, // * children is only available for layer index your are viewing
            form_options: config.form_options // * used to translate form values to labels

        }).display_vector("7px")}</View>))
    ]);

    // make chunks of all columns with 25 rows per column
    const chunked = fields.map((_, index) => chunk(allColumns[index], 25));

    return chunked[0].map((_, chunkIndex) => <PDFTable key={chunkIndex} columns={chunked.map((i, colIndex) => [headers[colIndex], ...i[chunkIndex]])} />);

}

PDFPalletsSummary.propTypes = {
    fields: PropTypes.array,
    printChildren: PropTypes.array,
    config: PropTypes.any,
    form_options: PropTypes.object
};


