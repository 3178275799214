/** @jsxImportSource @emotion/react */
import { faUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { DEFECT_POSITION, DEFECT_SEVERITY } from "../../../../actions/Checks/constants";
import FilterableConfigList from "./FilterableConfigList";
import FlagConfigForm from "./FlagConfigForm";
import { yesOrNoRadio } from "./util";

const defaultField = {
    field: {
        title: "Minor defects",
        lg: "4",
        defect_position: [DEFECT_POSITION.EXTERNAL],
        defect_severity: [DEFECT_SEVERITY.MINOR],
        show_total: 1,
        denominator: "sample_size",
        flags: []

    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    // exclude_layer_types: [],
    }
};
export default function DefectGroupConfigForm({ name, title, fields, description, setArray }) {
    const { tenant_id } = useParams();
    const getFieldTitle = (object) => <span>{object.title}</span>;
    const getFieldForm = (object, setObject) => [
        {
            type: "element",
            el: <div className="py-1">You can manage the defects here <Link to={`/tenants/${tenant_id}/form-field-options/defects`} target="_blank" rel="noopener noreferrer" >Defects <FontAwesomeIcon icon={faUpRightFromSquare} /> </Link></div>
        },
        {
            label: "Title",
            name: "title",
            type: "text",
        },
        {
            label: "lg",
            type: "number",
            description: "How many columns should this input take on desktop screen? Fil something between 1 - 12.",
            name: "lg",
            max: 12,
            min: 1

        },
        {
            label: "Defect position",
            name: "defect_position",
            type: "single-select",
            return_array: true,
            multi: true,
            options: Object.values(DEFECT_POSITION).map((i) => ({ value: i, label: i })),
        },
        {
            label: "Defect Severity",
            name: "defect_severity",
            type: "single-select",
            return_array: true,
            multi: true,
            options: Object.values(DEFECT_SEVERITY).map((i) => ({ value: i, label: i })),
        },
        {
            label: "Show group total?",
            name: "show_group_total",
            type: "radio",
            options: [{ value: 1, label: "Yes" }, { value: 0, label: "No" }]
        },
        {
            label: "Divide defects by?",
            name: "denominator",
            description: "What to use to get the percentage of each defect.",
            type: "radio",
            options: [{
                label: "Fruits",
                value: "avos_fruit",
            },
            {
                label: "Sample size",
                value: "sample_size",
            }],
        },
        {
            label: "[DEPRECATED] Total defects title",
            name: "total_defects_title",
            description: "[DEPRECATED] -> just set title and/or pdf_show_on_po_level. ",
            type: "text",
        },
        {
            type: "element",
            el: <FlagConfigForm
                fields={object.flags || []}
                description="Flag individual defects? use a value between 0 and 1 representing the percentage of defects belonging to this defect group. This will not be used to flag the whole layer. Use the color coding wizard for that."
                name="flags"
                title="Flags"
                setArray={(flags) => setObject({ ...object, flags })} />
        },
        {
            label: "Number of decimals",
            name: "decimals",
            description: "Number of decimals to use when displaying the percentage of defects. Defaults to 1.",
            type: "number"
        },
        {
            label: "Exclude from overall total",
            name: "exclude_from_total",
            description: "Exclude this defect group from the overall total. By default will be included.",
            ...yesOrNoRadio
        },
        {
            label: "PDF Show on PO level",
            name: "pdf_show_on_po_level",
            description: "Show this defect group on the PO level of the PDF report (first page). By default will NOT be included.",
            ...yesOrNoRadio
        },
        {
            label: "PDF Show on pallet level",
            name: "pdf_show_on_pallet_level",
            description: "Show this defect group on the pallet level of the PDF report (second page). By default this group will be included.",
            ...yesOrNoRadio
        }
    ];

    return <FilterableConfigList
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle} />;

}


