/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAsCalibrationAvocados } from "../../../../actions/Checks/actions";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { DeviceResultActions } from "../../../../actions/Tenants/config/constants";
import { value_to_category } from "../../../../actions/Tenants/config/utils";
import useAvosApi from "../../../../utils/useAvosApiHook";
import { QuickPressureInput } from "./BulkFTAInput";

function hasPressureCalibrationHook() {
    const check = useSelector((state) => state.checks.current);
    const [getCheckCalibration, check_has_pressure_calibration] = useAvosApi(`/tests/${check?.test_id}/get_calibration/`);
    const [has_pressure_calibration, setHasPressureCalibration] = useState(false);

    // * set initual state for has_pressure_calibration
    useEffect(() => {
        if (check?.test_id) {
            getCheckCalibration({ test_id: check.test_id });
        }
    }, [check.test_id]);

    useEffect(() => {
        if (check_has_pressure_calibration?.has_pressure_calibration) {
            setHasPressureCalibration(true);
        }
    }, [check_has_pressure_calibration?.has_pressure_calibration]);

    return [has_pressure_calibration, setHasPressureCalibration, getCheckCalibration];

}
function setupPressureCalibrationHook(avocado) {
    const config = useConfig();
    const check = useSelector((state) => state.checks.current);
    const layer = useSelector((state) => state.layers.current);
    const [isBadCandidate, setIsBadCandidate] = useState(false);
    const dispatch = useDispatch();
    const location = config.get_location(layer, check);
    const { pressure_calibration_settings } = location;

    const required_sample_size = pressure_calibration_settings?.required_sample_size === "0" ? 0 : pressure_calibration_settings?.required_sample_size || 2;
    const calibration_stage = pressure_calibration_settings?.calibration_stage || check.location;
    const timer = useRef(0);

    const [has_pressure_calibration, setHasPressureCalibration, getCheckCalibration] = hasPressureCalibrationHook();


    // !warning:  disable the pressue !== 13 rule for now
    // const calibration_sample = check.avocados.filter((i) => i.pressure > 0 && i.pressure !== 13);
    const calibration_sample = check.avocados.filter((i) => i.pressure > 0 && i.atron_pressures_median > 0);
    const could_fill_pressure = required_sample_size > 0 && (check.avocados.length <= required_sample_size || (check.avocados.length > 0 && avocado.avocado_id <= check.avocados[required_sample_size - 1].avocado_id));

    const array_of_pressures = Array.from({ length: required_sample_size }, (_, key) => calibration_sample?.[key]?.pressure);

    useEffect(() => {

        // * make it possible to recalibrate
        // if (has_pressure_calibration) {
        //     return;
        // }
        clearTimeout(timer.current);

        // * only calibrate when there is a required sample size
        if (required_sample_size === 0) {
            return;
        }

        //  only calibrate if we have enough fruit
        if (calibration_sample.length < required_sample_size) {
            return;
        }

        // Use the same amount of fruit as setup for the required_sample_size
        // only use the required sample size of fruit for calibration
        timer.current = setTimeout(() => {
            const avocado_ids = calibration_sample.map((i) => i.avocado_id).slice(0, required_sample_size);
            dispatch(setAsCalibrationAvocados(check.test_id, calibration_stage, avocado_ids)).then((response) => {
                if (response.error) {
                    setIsBadCandidate(true);
                    setHasPressureCalibration(false);
                } else {
                    setHasPressureCalibration(true);
                    // * also check against the backend
                    getCheckCalibration(check.test_id);
                }
            });
        }, 300);

    }, [calibration_sample.length, ...array_of_pressures]);

    // * 4 SCENARIOS:
    // * 1: There is no calibration on the layer => ALWAYS ask for pressure
    // * 2: There is calibration on this layer from another check => NEVER ask for pressure => use prediction instead
    // * 3: There is calibration on this check => pressure was asked on first n fruits, but after that a prediction can be used
    // * 4: There are fruits with pressure, but no calibration => This means the batch is not egliable to be predicted becase R^2 is to big
    return [has_pressure_calibration, isBadCandidate, location, could_fill_pressure];

}

// * Setup Pressure calibration on first 2 fruit, and do not show QuickPressureInput as that one is already in the form
export function SetupPressureCalibrationWithForm({ avocado }) {
    const [has_pressure_calibration, isBadCandidate, location, could_fill_pressure] = setupPressureCalibrationHook(avocado);


    if (!avocado.avocado_id) {
        return null;
    }

    if (could_fill_pressure) {
        return null;
    }

    if (has_pressure_calibration) {
        // * Bakker wants predictions on the fly
        if (location.device_result_action === DeviceResultActions.pressure_advice) {
            return <PressureAdvice avocado={avocado} check_finished={false} />;
        }

        // * other tenants can just continue scanning without inputting the pressure
        return <div className="text-success py-4">Please continue</div>;
    }

    if (isBadCandidate) {
        return <div className="text-warning py-4">Pressure can not be predicted on this batch, please cut the avocado.</div>;
    }
    return null;

}

SetupPressureCalibrationWithForm.propTypes = {
    avocado: PropTypes.object,
};

// * Setup Pressure calibration on first 2 fruit
export function SetupPressureCalibration({ avocado }) {
    const [has_pressure_calibration, isBadCandidate, location, could_fill_pressure] = setupPressureCalibrationHook(avocado);

    if (!avocado.avocado_id) {
        return null;
    }

    if (has_pressure_calibration && !could_fill_pressure) {
        // * Bakker wants predictions on the fly
        // TODO: we could opt to make this configurable per fruit_type
        if (location.device_result_action === DeviceResultActions.pressure_advice) {
            return <PressureAdvice avocado={avocado} check_finished={false} />;
        }

        // * other tenants can just continue scanning without inputting the pressure
        return <div className="text-success text-center py-4">Please continue</div>;
    }


    return <div>
        <div>
            <h4 className="py-3 mb-0 text-center">Please input the pressure</h4>
            <div className="pb-4 ">
                <div className="mx-auto d-flex justify-content-center">
                    <QuickPressureInput avocado={avocado} />
                </div>
            </div>
        </div>
        {isBadCandidate && <div className="text-warning text-center">Pressure can not be predicted on this batch, please cut the avocado.</div>}
    </div>;
}

SetupPressureCalibration.propTypes = {
    avocado: PropTypes.object,
};


function findIndexWhereFirmnessBelowThreshold(avocados, min_fruit, threshold = 70) {
    let totalFirmness = 0;
    for (let i = 0; i < avocados.length; i++) {
        totalFirmness += avocados[i].atron_pressures_median;
        const avgFirmness = totalFirmness / (i + 1);
        if ((i + 1) >= min_fruit) {
            if (avgFirmness < threshold) {
                return i;
            }
        }
    }
    return false;
}

// * Setup Pressure calibration after scanning N fruit
export function SetupPressureCalibrationAfterNFruit({ avocado }) {
    const config = useConfig();
    const check = useSelector((state) => state.checks.current);
    const layer = useSelector((state) => state.layers.current);
    const [isBadCandidate, setIsBadCandidate] = useState(false);
    const dispatch = useDispatch();
    const timer = useRef(0);
    const location = config.get_location(layer, check);
    const { pressure_calibration_settings } = location;
    const [has_pressure_calibration, setHasPressureCalibration, getCheckCalibration] = hasPressureCalibrationHook();
    const min_fruit = pressure_calibration_settings?.min_fruit || 10;
    const required_sample_size = pressure_calibration_settings?.required_sample_size || 2;


    // ignore the first 10 avocados
    // !warning:  disable the pressue !== 13 rule for now
    // const calibration_sample = check.avocados.filter((_, index) => index + 1 > min_fruit).filter((i) => i.pressure > 0 && i.pressure !== 13);
    const calibration_sample = check.avocados.filter((i) => i.atron_pressures_median > 0).filter((_, index) => index + 1 > min_fruit).filter((i) => i.pressure > 0);

    const firmness_to_low_fruit_index = findIndexWhereFirmnessBelowThreshold(check.avocados, min_fruit, 180);
    const check_finished = (check.avocados.length >= min_fruit && (has_pressure_calibration || firmness_to_low_fruit_index === false)) || calibration_sample.length >= required_sample_size;

    const is_latest_avocado = check.avocados.length > 0 && check.avocados[check.avocados.length - 1].avocado_id === avocado.avocado_id;

    // * can fill the pressure if there are at least x avocados and the currently viewed avocado is not one of the first x
    const could_fill_pressure = check.avocados.length >= min_fruit && avocado.avocado_id > check.avocados[min_fruit - 1].avocado_id;

    // * check needs calibration, current fruit has no pressure, and is 11th, 12th or 13th fruit
    const should_fill_pressure = could_fill_pressure && !(avocado.pressure > 0) && firmness_to_low_fruit_index > 0 && calibration_sample.length < required_sample_size && !has_pressure_calibration;

    // * another scan needed? current pressure is filled in, but no calibration yet
    const another_scan_needed = !should_fill_pressure && calibration_sample.length < required_sample_size && !has_pressure_calibration && firmness_to_low_fruit_index > 0 && !isBadCandidate;

    // console.log("another_scan_needed", another_scan_needed);
    // console.log("should_fill_pressure", should_fill_pressure);
    // console.log("calibration_sample", calibration_sample.length);
    // console.log("firmness_to_low_fruit_index", firmness_to_low_fruit_index);
    // console.log("isBadCandidate", isBadCandidate);
    // console.log("sample size ", calibration_sample < required_sample_size);

    const array_of_pressures = Array.from({ length: required_sample_size }, (_, key) => calibration_sample?.[key]?.pressure);

    useEffect(() => {

        // // already calibrated
        // * make it possible to recalibrate
        // if (has_pressure_calibration) {
        //     return;
        // }
        clearTimeout(timer.current);

        // only calibrated when firmness was to low
        if (!firmness_to_low_fruit_index) {
            return;
        }

        // Only attempt to calibratte when latest 2 fruits have pressure
        if (calibration_sample.length < required_sample_size) {
            return;
        }

        // only use the required sample size of fruit for calibration
        timer.current = setTimeout(() => {
            const first_3_avocado_ids = calibration_sample.map((i) => i.avocado_id).slice(0, required_sample_size);
            dispatch(setAsCalibrationAvocados(check.test_id, location.calibration_stage || check.location, first_3_avocado_ids)).then((response) => {
                if (response.error) {
                    setIsBadCandidate(true);
                    setHasPressureCalibration(false);
                } else {
                    setHasPressureCalibration(true);
                    // * also check against the backend
                    getCheckCalibration(check.test_id);
                }
            });
        }, 300);

    }, [calibration_sample.length, firmness_to_low_fruit_index, ...array_of_pressures]);

    // * 4 SCENARIOS:
    // * 1: The avg firmness is more than 70 no calibration is needed
    // * 2: The avg firmness is less than 70 and scan 10 fruit, calibration is needed

    if (!avocado.avocado_id) {
        return null;
    }


    if (has_pressure_calibration) {
        // * Bakker wants predictions on the fly
        if (location.device_result_action === DeviceResultActions.calibrate_after_n_fruits && check.avocados.length > (min_fruit + required_sample_size) && avocado.avocado_id > check.avocados[min_fruit + required_sample_size - 1].avocado_id) {
            return <PressureAdvice avocado={avocado} check_finished={check_finished} />;
        }

        // // * other tenants can just continue scanning without inputting the pressure
        // return <div className="text-success text-center py-4">Please continue</div>;
    }
    if (!has_pressure_calibration && check.avocados.length - min_fruit <= 0) {
        return <div className="text-success text-center py-4">Please continue scanning</div>;
    }
    if (!is_latest_avocado && !could_fill_pressure) {
        return <div className="text-success text-center py-4">Please continue at latest fruit</div>;
    }

    return <div>
        <div className="pb-4 pt-4 ">
            <div className="mx-auto d-flex justify-content-center">
                <QuickPressureInput avocado={avocado} />
            </div>
        </div>
        {isBadCandidate && <div className="text-warning text-center">Pressure can not be predicted on this batch, please cut the fruit.</div>}
        {should_fill_pressure && <div className="text-warning text-center">Please input pressure</div>}
        {another_scan_needed && <div className="text-warning text-center">Please scan another fruit</div>}
        {check_finished && <div className="text-warning text-center">Please complete scan</div>}
    </div>;
}

SetupPressureCalibrationAfterNFruit.propTypes = {
    avocado: PropTypes.object,
};


function PressureAdvice({ avocado, check_finished }) {
    const config = useConfig();
    const layer = useSelector((state) => state.layers.current);
    const pressure_classes = config.get_ripening_classes(layer, "pressure_avg");

    let fta_output_text;

    if (typeof avocado.atron_pressure_kg === "undefined" || avocado.atron_pressure_kg === null) {
        fta_output_text = <div className="text-muted">-</div>;
    } else {
        const { label, color } = value_to_category(avocado.atron_pressure_kg, pressure_classes);
        const textcolor = `text-${color}`;
        fta_output_text = <div className={textcolor}>{label}</div>;
    }

    return <div className="text-center"><h4 className="py-3 mb-0">{fta_output_text}</h4>
        {check_finished && <div className="text-success">Please complete scan.</div>}
    </div>;
}
PressureAdvice.propTypes = {
    avocado: PropTypes.object,
    check_finished: PropTypes.bool,
};
