/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faGrid, faList } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button, Table
} from "reactstrap";
import { listFacilityLocations } from "../../../actions/FacilityLocations/actions";
import { applyDisplayFilterLayerFields } from "../../../actions/Tenants/config/applyDisplayFilter";
import useConfig, { useTenantFormOptions } from "../../../actions/Tenants/config/configHook";
import useNavigateFrom from "../../../utils/useNavigateFrom";
import { TableCell, TableHeaderCell } from "../../Helper/Table";
import FullPageLayout from "../Layout/FullPageLayout";
import { LOCATION_TYPES } from "../Tenants/TenantEditFacilityLocationsForm";
import { LIST_OR_GRID } from "./RipeningRoom/hooks/useRipeningRoom";
import RipeWiseBreadcrumbs from "./RipeningRoomBreadcrumbs";

const RipeningRoomTableWrapped = () => {
    return (

        <FullPageLayout
            fluidContainer={true}
            title={"Ripening Rooms"}
            breadcrumbs={<RipeWiseBreadcrumbs currentLocation={null} />}
        >
            <RipeningRoomTable />
        </FullPageLayout>
    );
};

export default RipeningRoomTableWrapped;


// DataTable component for displaying cells
export function RipeningRoomTable() {
    const dispatch = useDispatch();
    const filter = useSelector((state: any) => state.facilityLocations.filter);
    const ripening_cells = useSelector((state: any) => state.facilityLocations.locations);
    const form_options = useTenantFormOptions();
    const [listOrGrid, setListOrGrid] = useState(LIST_OR_GRID.LIST);

    useEffect(() => {
        dispatch(listFacilityLocations({ type: LOCATION_TYPES.RIPENING_ROOM, ...filter }));
    }, []);

    const config = useConfig();
    const { navigateTo } = useNavigateFrom();

    const fields = applyDisplayFilterLayerFields(config?.root_config?.ripening_room_overview_fields, {
        // fruit_type: "avocado"  # TODO: discuss fruit_type fillters
    });

    if (fields.length === 0) {
        return null;
    }
    const values = (c) => fields.map((i, index) => <TableCell key={index}>{i.set_value({
        config,
        ripeningRoom: c,
        form_options
    }).display_component()}</TableCell>);

    const renderHeader = () => {
        return fields.map((field, index) => (
            <TableHeaderCell key={index} >{field.label}</TableHeaderCell>
        ));
    };

    // * commented out some divs to place future filters
    return (

        <div>
            <div className="d-flex justify-content-between">
                <div>
                    <div className="d-flex">
                        {/* <GroupStrategyModal /> */}
                        <div className="me-1">
                            <Button color="light" onClick={() => setListOrGrid((prev:string) => (prev === LIST_OR_GRID.LIST ? LIST_OR_GRID.GRID : LIST_OR_GRID.LIST))}>
                                {listOrGrid === LIST_OR_GRID.LIST && <span><FontAwesomeIcon icon={faGrid}></FontAwesomeIcon> Grid</span>}
                                {listOrGrid === LIST_OR_GRID.GRID && <span><FontAwesomeIcon icon={faList}></FontAwesomeIcon> List</span>}
                            </Button>
                        </div>
                    </div>
                </div>
                <div>
                    {/* <ScanToMoveLayerModal /> */}
                    {/* <ScanToCheckModal/> */}
                </div>
            </div>
            <div className="pt-4 d-flex flex-column" css={css`min-height: 50vh;`} >
                <div >
                    { listOrGrid === LIST_OR_GRID.LIST && <div css={css`border-radius: 8px; overflow: hidden;`} className="bg-body border">
                        <Table size="sm" responsive={true} borderless={true}>
                            <thead>
                                <tr>{renderHeader()}</tr>
                            </thead>
                            <tbody>
                                {ripening_cells.map((i, index) => <tr key={index} className="clickable" title={i.location_id} onClick={() => navigateTo(`/ripening-room/${i.location_id}`)}>{values(i)}</tr>)}
                            </tbody>
                        </Table >
                    </div>
                    }

                    {listOrGrid === LIST_OR_GRID.GRID && (

                        <div className=""
                            css={{
                                display: "grid",
                                gridTemplateColumns: `repeat( ${(config?.root_config.ripening_room_number_of_rooms_per_row || 9)}, 1fr)`, // Set grid columns based on config.columns or default to 9
                                gap: "1rem", // Spacing between items
                                overflowX: "scroll",
                                // position: "absolute",
                                // left: "2%",
                                // right: "2%",
                                // zIndex: 999

                            }}>
                            {ripening_cells.map((c, indexx) => (
                                <div
                                    key={indexx}
                                    className="mb-3 border bg-body p-3 rounded clickable"
                                    css={css`min-width: 20rem;`}
                                    onClick={() => navigateTo(`/ripening-room/${c.location_id}`)}>
                                    <table className="w-100">
                                        {fields.map((i, index) => (
                                            <tr key={index}>
                                                <td>{i.label}</td>
                                                <td className="fw-bold ps-2">
                                                    {i.set_value({
                                                        config,
                                                        ripeningRoom: c,
                                                        form_options
                                                    }).display_component()}
                                                </td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>);
}
